import React, { useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { withRouter } from "react-router";
import {Link} from "react-router-dom";

import "../../css/pages/customers/form.scss";
import {getNewNum, getResource, updateResource} from "../../redux/action";
import {addContact, changeProperty, customerToForm, submit, reset} from "../../redux/actions/forms/customerFormAction";
import {getParams, renderIfDefinedParams} from "../../utils/Data";
import ContactForm from "./ContactForm";
import Page from "../../components/Page/Page";
import Note from "./customer/Note";
import {onSaved} from "../../utils/Alert";

const CustomerForm = ({history}) => {
    const {rawToken, item, newNum} = useSelector(({apiReducer}) => apiReducer);
    const {data, contactsForms, contactFormsId} = useSelector(({customerFormReducer}) => customerFormReducer);

    const dispatch  = useDispatch();

    const {id}      = getParams()
    const customer  = item

    useEffect(() => {
        dispatch(reset())
        return () => {
            dispatch(reset())
        }
    }, [])

    useEffect(() => {
        dispatch(reset())
        if(id !== 'creer')
            dispatch(getResource('customers', id, rawToken))
        else
            dispatch(getNewNum('customers', rawToken))
    }, [id])

    useEffect( () => {
        if(customer !== null) {
            dispatch(customerToForm(customer))
            addContacts(customer.contacts)
        }
    }, [customer])

    useEffect(() => {
        if(id === 'creer')
            dispatch(changeProperty('code', newNum))
    }, [newNum])


    const onChange = e => dispatch(changeProperty(e.target.name, e.target.value))

    const onChangeNote = value => {
        dispatch(changeProperty("note", value))
    }

    const handleSubmit = e => {
        e.preventDefault();
        dispatch(submit(id, data, rawToken, () => {
            onSaved("/clients/" + id + "/modifier", "Client sauvegardé", "Le client a bien été sauvegardé !")
        }))
    };

    const addContacts = contacts => contacts.forEach((contact, i) => addContactForm(null, i, contact))

    const addContactForm = (e = null, i = null, contact = null) => {
        const id = i !== null ? i : contactFormsId
        dispatch(addContact(<ContactForm key={id} id={id} contact={contact}/>, contact))
    }

    const buttonsRight = () =>
        id !== 'creer' ?
            <Link to={"/clients/" + id}>
                <button className={"btn-circle btn-primary"} title={"Voir la fiche"}>
                    <i className={"fas fa-sticky-note"} />
                </button>
            </Link>
            : ''


    return (
        <Page title={id !== 'creer' ? 'Modification d\'un client' : 'Création d\'un client'} headerButtons={buttonsRight()}>
            <form onSubmit={handleSubmit} className={"form-block form-customers"}>
                <div className={"form-group row"}>
                    <div className={"col-12 col-lg-6"}>
                        <h3 className={"form-block-title"}>Informations principales</h3>
                    </div>
                    <div className={"col-12 col-lg-6"}>
                        <h3 className={"form-block-title"}>Adresse</h3>
                    </div>
                </div>
                <div className={"form-group row"}>
                    <div className={"col-12 col-lg-1"}>
                        <label>Code</label>
                        <input type={"text"} name={"code"} placeholder={"Code"} value={data.code} onChange={onChange}/>
                    </div>
                    <div className={"col-12 col-lg-5"}>
                        <label>Nom de l'entreprise</label>
                        <input type={"text"} name={"name"} placeholder={"Nom du client"} value={data.name} onChange={onChange}/>
                    </div>
                    <div className={"col-12 col-lg-1"}>
                        <label>N°</label>
                        <input type={"text"} name={"address.number"} placeholder={"N°"} value={data.address.number} onChange={onChange}/>
                    </div>
                    <div className={"col-12 col-lg-5"}>
                        <label>Rue</label>
                        <textarea rows={1} name={"address.street"} placeholder={"Adresse"} value={data.address.street} onChange={onChange}/>
                    </div>
                </div>

                <div className={"form-group row"}>
                    <div className={"col-12 col-lg-3"}>
                        <label>Téléphone fixe</label>
                        <input type={"text"} name={"homePhone"} placeholder={"Fixe"} value={data.homePhone} onChange={onChange}/>
                    </div>
                    <div className={"col-12 col-lg-3"}>
                        <label>Téléphone portable</label>
                        <input type={"text"} name={"phone"} placeholder={"Portable"} value={data.phone} onChange={onChange}/>
                    </div>
                    <div className={"col-12 col-lg-1"}>
                        <label>Code Postal</label>
                        <input type={"text"} name={"address.postalCode"} placeholder={"CP"} value={data.address.postalCode} onChange={onChange}/>
                    </div>
                    <div className={"col-12 col-lg-5"}>
                        <label>Ville</label>
                        <input type={"text"} name={"address.city"} placeholder={"Ville"} value={data.address.city} onChange={onChange}/>
                    </div>
                </div>

                <div className={"form-group row"}>
                    <div className={"col-12 col-lg-3"}>
                        <label>Fax</label>
                        <input type={"text"} name={"fax"} placeholder={"Fax"} value={data.fax} onChange={onChange}/>
                    </div>
                    <div className={"col-12 col-lg-3"}>
                        <label>Adresse mail</label>
                        <input type={"email"} name={"email"} placeholder={"Adresse mail"} value={data.email} onChange={onChange}/>
                    </div>
                </div>

                <Note onChangeNote={onChangeNote} note={renderIfDefinedParams(customer, "note")}/>

                <div className={"addContact"} onClick={addContactForm}>
                    Ajouter un contact
                </div>

                {contactsForms.map(form => form)}

                {id !== 'creer' ? <button className={"submitButton"} title={"Modifier"}>Modifier</button> : <button  className={"submitButton"} title={"Créer"}>Créer</button>}
            </form>
        </Page>
    )
}

export default withRouter(CustomerForm)
