import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {isDefined} from "../../../utils/Data"
import Row from "./Row"
import {getResource, getResourceByProperty} from "../../../redux/action";

const RowContainer = ({product, index}) => {
    const dispatch = useDispatch()
    const {rawToken} = useSelector(({apiReducer}) => apiReducer)

    const [stock, setStock]     = useState("")
    const [project, setProject] = useState(null)
    const [totalSurface, setTotalSurface] = useState("")
    const [total, setTotal]     = useState(0)

    useEffect(() => {
        setTotalSurface(((parseFloat(product.surface) || 0) * (parseFloat(product.quantity) || 0)).toFixed(2))
    }, [product.surface, product.quantity])

    useEffect(() => {
        setTotal(((parseFloat(totalSurface) || 0) * (parseFloat(product.unitPrice) || 0)).toFixed(2))
    }, [totalSurface, product.unitPrice])

    useEffect(() => {
        onChangeStock(product.project)
    }, [product.project])

    const onChangeStock = (id) => {
        dispatch(getResource('projects', id, rawToken))
            .then(res => {
                if (isDefined(res)) {
                    setStock(res.number)
                } else {
                    setStock("")
                }
                onChangeProject(res)
            })
    }

    const onChangeProject = project => {
        isDefined(project) ? setProject(project) : setProject(null)
    }

    return <Row product={product} index={index} project={project} stock={stock} total={total} totalSurface={totalSurface}/>
}

export default RowContainer
