import React, {Component} from 'react';
import {connect} from "react-redux"
import ReactLoading from 'react-loading'

import {changeStep, getWeekDays} from "../../redux/calendarAction";
import {formatDate, getDateFormated, getDayOfDate} from "../../utils/Date";
import CalendarStep from "./CalendarStep";
import {getHigherLength, isDefined, transpose} from "../../utils/Data";
import CalendarHours from "./CalendarHours";


class CalendarTable extends Component {
    componentDidMount() {
        this.props.dispatch(getWeekDays());
    }

    getSteps = () => {
        let calendar = [];

        let steps = this.props.steps;
        this.props.week.forEach((day, i) => {
            calendar[i] = [];
            steps.forEach((step, j) => {
                const dayDate   = getDateFormated(day);
                const stepDate  = formatDate(step.planned);

                if(stepDate === dayDate)
                    calendar[i].push(<CalendarStep key={j} index={j} step={step} changePosition={this.changePosition}/>)
            })
        })

        calendar.map(day => day.sort((row, row2) => {
            if(row.props.step.priority > row2.props.step.priority)
                return -1
            if(row.props.step.priority < row2.props.step.priority)
                return 1

            if(row.props.step.position > row2.props.step.position)
                return 1
            if(row.props.step.position < row2.props.step.position)
                return -1
        }))

        return calendar;
    };

    calculHoursPerUser = calendar => {
        const length = getHigherLength(calendar)

        return calendar.map((day, d) => {
            let usersHours = []

            day.forEach((cell, i) => {
                const step = cell.props.step
                step.users.forEach(userStep => {
                    const user = usersHours.find(user => user.id === userStep.id)
                    if(isDefined(user))
                        user.hours = user.hours + (parseFloat(step.hours) || 0)
                    else
                        usersHours.push({id: userStep.id, firstname: userStep.firstname, color: userStep.color, hours: parseFloat(step.hours) || 0})
                })
            })

            if(day.length < length) {
                for(let i = day.length; i < length; i++) {
                    day.push(<td/>)
                }
            }

            day.push(<td className={"users-hours"}>{usersHours.map((user, i) => <CalendarHours key={i} user={user}/>)}</td>)

            return day
        })
    }

    reorderArray = (from, to ,originalArray) => {
        const movedItem         = originalArray.find((item, index) => index === from);
        const remainingItems    = originalArray.filter((item, index) => index !== from);

        const reorderedItems = [
            ...remainingItems.slice(0, to),
            movedItem,
            ...remainingItems.slice(to)
        ];

        return reorderedItems;
    }

    changePosition = (id, up = true) => {
        let indexDay = null;
        const calendar      = this.getSteps()
        calendar.forEach(day => {
            if(day.find(step => step.props.step.id === id) !== undefined)
                indexDay = day
        })

        const from  = indexDay.findIndex(el => el.props.step.id === id) //step.position;
        const to    = up ? from - 1 : from + 1;

        const oldCalendar   = [...indexDay]
        const newCalendar   = this.reorderArray(from, to, oldCalendar)

        newCalendar.forEach((row, i) => {
            if(isDefined(row))
                this.props.dispatch(changeStep(row.props.step.id, 'steps', {position: i}, this.props.rawToken));
        })
    }

    onDragEnd = result => {

    }

    render() {
        const week = this.props.week;
        const calendar = this.calculHoursPerUser(this.getSteps());

        return (
            <>
            {this.props.loading === true ? <ReactLoading type={"spin"} color={"#0082f8"} height={70} width={70} className={"sr-loading"} /> : ""}
            <table className={"table"}>
                <thead>
                    <tr className={"nameOfDays"}>
                        {week.map((date, i) => <th key={i} scope="col" className={getDateFormated(date) === getDateFormated(new Date()) ? "today" : ""}>{getDayOfDate(date)}</th>)}
                    </tr>
                    <tr>
                        {week.map((date, i) => <th key={i} scope="col" className={getDateFormated(date) === getDateFormated(new Date()) ? "today" : ""}>{getDateFormated(date)}</th>)}
                    </tr>
                </thead>
                    <tbody>
                        {transpose(calendar).map((day, i) => {
                            const row = day.map((step, j) => step !== null && step !== undefined ? step : <td className={"step"}/>)
                            return <tr key={i}>{row}</tr>
                        })}
                    </tbody>
            </table>
            </>
        )
    }
}

const mapStateToProps = ({apiReducer, calendarReducer}) => {
    return {
        rawToken: apiReducer.rawToken,
        date: calendarReducer.date,
        week: calendarReducer.week,
        users: apiReducer.users,
    }
};

export default connect(mapStateToProps)(CalendarTable)
