import React, {Component} from 'react'
import {connect} from "react-redux"
import { withRouter } from "react-router"

import "../../css/pages/messages/form.scss"
import {
    createResource,
    getResource, getResourceByProperty,
    getResources, getResourcesByProperty,
    updateResource
} from "../../redux/action"
import MyAutosuggest from "../../components/MyAutosuggest"
import {getParams, isDefined} from "../../utils/Data"
import Page from "../../components/Page/Page"
import {onSaved} from "../../utils/Alert";

class MessageForm extends Component {
    constructor(props) {
        super(props);

        const {id, action} = getParams()
        this.action     = action;

        this.state = {
            id: id,
            message: null,
            form: {
                title: '',
                project: {
                    id: null,
                    number: '',
                },
                message: '',
                receivers: [],
            },
            customer: null,
        }
    }

    componentDidMount() {
        if(this.state.id !== 'creer' && this.action !== 'message') {
            this.props.dispatch(getResource('messages', this.state.id, this.props.rawToken))
                .then((message) => {
                    this.setState({
                        message: message,
                    });
                    Object.keys(this.state.form).map((input) => this.resourceToInput(input));
                });
        }
        else if(this.action === 'message') {
            this.getProjectById(this.state.id);
        }

        this.props.dispatch(getResourcesByProperty('projects', 'number', this.props.rawToken));
        this.props.dispatch(getResources('users', this.props.rawToken));
    }

    getProjectById = id => {
        this.props.dispatch(getResource('projects', id, this.props.rawToken))
            .then(project =>
                this.setState({
                    form: {
                        ...this.state.form,
                        project: {
                            ...this.state.form.project,
                            id: project.id,
                            number: project.number
                        }
                    },
                    customer: project.customer.name,
                })
            );
    };

    resourceToInput = (input) => {
        if(input === 'project') {
            if(this.state.id !== 'creer' && this.state.message.project !== null)
                this.onChangeProject(null, null, this.state.message.project.number);
            else
                this.setState({
                    form: {
                        ...this.state.form,
                        project: this.state.message.project !== null ? this.state.message.project.number : ''
                    }
                });
        }
        else if(input === 'receivers') {
            this.setState({
                form: {
                    ...this.state.form,
                    receivers: isDefined(this.state.message.messagings.receivers) ? this.state.message.messagings.receivers.map(receiver => receiver.id) : []
                }
            });
        }
        else if (input === 'title' && this.action === 'repondre') {
            this.setState({
                form: {
                    ...this.state.form,
                    title: this.state.message.title !== null ? 'RE : ' + this.state.message.title : ''
                }
            });
        }
        else if(input === 'message' && this.action === 'repondre') {}
        else {
            this.setState({
                form: {
                    ...this.state.form,
                    [input]: this.state.message[input] !== null ? this.state.message[input] : ''
                }
            });
        }
    };

    changeHandler = (e, newName, newValue) => {
        if(e !== null)
            e.preventDefault();

        const name  = newName !== undefined ? newName : e.target.name;
        const value = newValue !== undefined ? newValue : e.target.value;

        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        });
    };

    onChangeProject = (e, name, value) => {
        this.setState({
            form: {
                ...this.state.form,
                project: {
                    ...this.state.form.project,
                    number: value
                }
            }
        });
        this.props.dispatch(getResourceByProperty('projects', 'number', value, this.props.rawToken))
            .then(id => {
                if (isDefined(id)) {
                    this.setState({
                        form: {
                            ...this.state.form,
                            project: {
                                ...this.state.form.project,
                                id: id
                            }
                        }
                    });
                }
                else {
                    this.setState({
                        form: {
                            ...this.state.form,
                            project: {
                                ...this.state.form.project,
                                id: null,
                            }
                        },
                        customer: null,
                    });
                }

            })
    };

    onChangeReceivers = e => {
        e.persist();
        let options = e.target.selectedOptions;
        let values = [];

        Array.from(options,option => values.push(option.value));

        this.setState({
            form: {
                ...this.state.form,
                receivers: values
            }
        });
    };

    handleSubmit = e => {
        e.preventDefault();

        let body = {};
        Object.keys(this.state.form).map((input) => {
            if(input !== "receivers")
                body[input] = this.state.form[input]
        });

        body.project = this.state.form.project.id;

        const receivers = this.state.form.receivers.length === 0 ? this.props.users.map(user => user.id) : this.state.form.receivers

        if(this.action === 'repondre') {
            body.parent = this.state.id;
        }

        if(this.state.id === 'creer' || this.action === 'repondre' || this.action === 'message') {
            this.props.dispatch(createResource('messages', body, this.props.rawToken)).then(message => {
                receivers.forEach(receiver => {
                    this.props.dispatch(createResource('messagings', {receiver: receiver, message: message.id}, this.props.rawToken))
                })
            })
            onSaved("/messages", "Message envoyé", "Votre message a bien été envoyé !")
        }
        else {
            this.props.dispatch(updateResource('messages', this.state.id, body, this.props.rawToken)).then(() => onSaved("/messages/" + this.state.id + "/modifier", "Message sauvegardé", "Votre message a bien été sauvegardé !"))
        }
    };

    render() {
        const isResponse        = this.action === 'repondre';
        const {customer, form}  = this.state;
        const projectsNumber    = this.props.numbers.projects;
        const loadingProjects   = isDefined(projectsNumber) && projectsNumber.length > 0;
        const users             = this.props.users.filter(user => user.firstname !== "Dimitri" && user.firstname !== "Gestion" && user.firstname !== "Jean Claude")

        return (
            <Page title={(this.state.id !== 'creer' ? 'Modification ' : 'Création') + ' d\'un message'} className={"messages-form"}>
                <form onSubmit={this.handleSubmit} className={"form-block"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <label>Titre</label>
                            <input type={"text"} name={"title"} placeholder={"Titre du message"} value={form.title} onChange={this.changeHandler} disabled={isResponse}/>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-12 col-lg-1"}>
                            <label>Dossier</label>
                            <MyAutosuggest
                                searchWithoutName={true}
                                placeholder={loadingProjects ? "N° de projet" : "Chargement ..."}
                                name={"project"}
                                items={projectsNumber}
                                onChange={this.onChangeProject}
                                value={form.project.number}
                                disabled={!loadingProjects || isResponse}
                            />
                        </div>
                        <div className={"col-12 col-lg-4"}>
                            <label>Client</label>
                            <input type={"text"} name={"customer"} placeholder={"Nom du client"} value={isDefined(customer) ? customer : ""} disabled={true}/>
                        </div>
                        <div className={"col-12 col-lg-2"}>
                            <label>Destinataires</label>
                            <select
                                name={"receivers"}
                                value={form.receivers}
                                onChange={this.onChangeReceivers}
                                disabled={!this.props.users.length > 0}
                                size={users.length + 1}
                                multiple={true}
                            >
                                <option value={null}>Destinaire(s)</option>
                                {users.map((user, i) => <option key={i} value={user.id}>{user.firstname + ' ' + user.lastname}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <label>Message</label>
                            <textarea name={"message"} placeholder={"Message"} value={form.message} onChange={this.changeHandler}/>
                        </div>
                    </div>

                    <button title={"Envoyer"}>Envoyer</button>
                </form>
            </Page>
        )
    }
}

const mapStateToProps = ({apiReducer}) => {
    return {
        rawToken:   apiReducer.rawToken,
        numbers:    apiReducer.numbers,
        projects:   apiReducer.projects,
        users:      apiReducer.users,
        user:       apiReducer.user,
    }
};

export default withRouter(connect(mapStateToProps)(MessageForm))
