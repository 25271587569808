import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import {deleteResource} from "../../redux/action";
import {changePropertyContact} from "../../redux/actions/forms/customerFormAction";
import {deleteContact} from "../../redux/actions/forms/customerFormAction";

const ContactForm = ({id, contact}) => {
    const {rawToken}  = useSelector(({apiReducer}) => apiReducer);
    const {data}      = useSelector(({customerFormReducer}) => customerFormReducer);

    const dispatch  = useDispatch();

    const form      = data.contacts.find(contact => contact.contactFormsId === id)

    const onChange = e => dispatch(changePropertyContact(id, e.target.name, e.target.value));

    const onDelete = () => {
        dispatch(deleteContact(id))
        if(contact !== null)
            dispatch(deleteResource('contacts', contact.id, rawToken));
    }

    return (
        <div className={"form-group row row-contact"}>
            <div className={"col-12 col-lg-2"}>
                <label>Nom</label>
                <input type={"text"} name={"lastname"} placeholder={"Nom"} value={form.lastname} onChange={onChange}/>
            </div>
            <div className={"col-12 col-lg-2"}>
                <label>Prénom</label>
                <input type={"text"} name={"firstname"} placeholder={"Prénom"} value={form.firstname} onChange={onChange}/>
            </div>
            <div className={"col-12 col-lg-3"}>
                <label>Aldresse mail</label>
                <input type={"email"} name={"email"} placeholder={"Adresse mail"} value={form.email} onChange={onChange}/>
            </div>
            <div className={"col-12 col-lg-2"}>
                <label>Téléphone fixe</label>
                <input type={"text"} name={"homePhone"} placeholder={"Fixe"} value={form.homePhone} onChange={onChange}/>
            </div>
            <div className={"col-12 col-lg-2"}>
                <label>Téléphone portable</label>
                <input type={"text"} name={"phone"} placeholder={"Portable"} value={form.phone} onChange={onChange}/>
            </div>
            {/*<div className={"col-12 col-lg-2"}>
                <input type={"text"} name={"fax"} placeholder={"Fax"} value={form.fax} onChange={onChange}/>
            </div>*/}
            <div className={"col-12 col-lg-1"}>
                <div className={"deleteContact btn-circle btn-danger"} title={"Supprimer ce contact"} onClick={onDelete}>
                    <i className="fas fa-trash-alt"/>
                </div>
            </div>
        </div>
    )
}

export default ContactForm
