import {FORWARD_DAY, FORWARD_WEEK, GET_WEEK_DAYS, PREVIOUS_DAY, PREVIOUS_WEEK} from "./calendarAction";
import {
    getDays,
    getLast5Days,
    getPreviousDay,
    getNextDay,
    getNext5Days,
    getNextWeekDay, getLastWeekDay
} from "../utils/Date";

const initialState = {
    date: getDays()[0],
    week: getLast5Days(new Date()),
};

const calendarReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_WEEK_DAYS:
            return {
                ...state,
                date: getDays()[0],
                week: getDays()
            };
        case PREVIOUS_WEEK:
            return {
                ...state,
                date: getLastWeekDay(state.date),
                week: getNext5Days(state.date)
            };
        case PREVIOUS_DAY:
            return {
                ...state,
                date: getPreviousDay(state.date),
                week: getLast5Days(state.date, state.week)
            };
        case FORWARD_DAY:
            return {
                ...state,
                date: getNextDay(state.date),
                week: getNext5Days(state.date)
            };
        case FORWARD_WEEK:
            return {
                ...state,
                date: getNextWeekDay(state.date),
                week: getNext5Days(state.date)
            };
        default: return state;
    }
};



export default calendarReducer;
