import {isDefined} from "sr-dates"

export const getTotalPrice = totalPrice => isDefined(totalPrice) ? totalPrice : 0;

export const getTva = (totalPrice, discountEuros = 0) => {
    const total     = getTotalPrice(totalPrice);
    const discount  = parseFloat(discountEuros || 0)
    return total && total !== 0 ? parseFloat((total - discount) * 20 / 100) : 0;
};

export const getAmountTTC = (totalPrice, discountEuros) => {
    const total     = getTotalPrice(totalPrice);
    const tva       = getTva(totalPrice, discountEuros);
    return parseFloat(total - discountEuros + tva)
};

export const getAdvance = advance => isDefined(advance) && advance !== '0' ? parseFloat(advance) : 0;

export const getPriceToPaid = (totalPrice, advance, discountEuros, paid) => {
    const advanceFormated   = getAdvance(advance);
    const amountTTC         = getAmountTTC(totalPrice, discountEuros);
    return isDefined(paid) ? parseFloat('0.00') : amountTTC - advanceFormated;
};
