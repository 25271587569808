import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from "react-redux"

import {getNewNum, getResource, getResourceByProperty, getResources, getResourcesByProperty} from "../../redux/action"
import {
    changeProperty,
    resourceToForm,
    submit,
    reset,
    changeProvider,
    changeUser, addProduct
} from "../../redux/actions/forms/orderFormAction"
import {getParams, isDefined} from "../../utils/Data"
import {onSaved} from "../../utils/Alert"
import OrdersForm from "./OrdersForm"

const OrdersFormContainer = () => {
    const {user, rawToken, providers} = useSelector(({apiReducer}) => apiReducer)
    const reducer   = useSelector(({orderFormReducer}) => orderFormReducer)
    const {data, orderUpdated} = reducer
    const dispatch  = useDispatch()
    const {id}      = getParams()

    const [loadingProviders, setLoadingProviders]   = useState(providers.length <= 0)
    const [loadingProjects, setLoadingProjects]     = useState(false)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        dispatch(getResources("providers", rawToken))

        if(id === 'creer') {
            dispatch(getNewNum('orders', rawToken)).then(res => dispatch(changeProperty("number", res)))
        }

        return () => {
            dispatch(reset())
        }
    }, [])

    useEffect(() => {
        if(id !== 'creer')
            dispatch(getResource('orders', id, rawToken)).then(res => dispatch(resourceToForm(res)))
    }, [id])

    useEffect(() => {
        setLoadingProviders(providers.length <= 0)
    }, [providers])

    useEffect(() => {
        if(id === 'creer' && user !== null)
            dispatch(changeUser(user))
    }, [user])

    useEffect(() => {
        setTotal(() => {
            let total = 0
            data.productsOrders.forEach(product => {
                const totalSurface = (parseFloat(product.surface) || 0) * (parseFloat(product.quantity) || 0)
                total = total + ((parseFloat(totalSurface) || 0) * (parseFloat(product.unitPrice) || 0))
            })
            return total
        })
    }, [data.productsOrders])

    useEffect(() => {
        setLoadingProjects(true)
        dispatch(getResourcesByProperty('projects', 'number', rawToken)).then(() => {
            setLoadingProjects(false)
        })
        //dispatch(getResources("projects", rawToken))
    }, [])

    const onChange = e => {
        dispatch(changeProperty(e.target.name, e.target.value))
    }

    const onChangeProvider = (e, newName, newValue) => {
        dispatch(changeProperty(newName, newValue))
        dispatch(getResourceByProperty('providers', 'name', newValue, rawToken))
            .then(id => {
                    if (isDefined(id))
                       dispatch(getResource('providers', id, rawToken))
                            .then((res) => {
                                if (isDefined(res)) {
                                    dispatch(changeProvider(res.id))
                                }
                            });
                    else {
                        dispatch(changeProperty("provider", ""))
                        dispatch(changeProvider(null))
                    }
                }
            )
    };


    const handleSubmit = e => {
        e.preventDefault()
        dispatch(submit(id, reducer, orderUpdated, rawToken, () => onSaved("/commandes/" + id + "/modifier", "Commande sauvegardée", "Votre commande a bien été sauvegardée !")))
    }

    return <OrdersForm handleSubmit={handleSubmit}
                       onChange={onChange}
                       onChangeProvider={onChangeProvider}
                       loadingProviders={loadingProviders}
                       loadingProjects={loadingProjects}
                       total={total}
                       addProduct={() => dispatch(addProduct())}/>


}

export default OrdersFormContainer
