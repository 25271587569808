import React, {useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"

import {onDelete} from "../../../utils/Alert"
import {changeProduct, deleteProduct} from "../../../redux/actions/forms/orderFormAction"
import OrdersArticlesModal from "./OrdersArticlesModal"
import MyAutosuggest from "../../../components/MyAutosuggest"
import {isDefined} from "../../../utils/Data";
import {getResource, getResourceByProperty} from "../../../redux/action";


const ProductRow = ({article, index, loadingProjects}) => {
    const dispatch      = useDispatch()
    const {numbers, rawToken}   = useSelector(({apiReducer}) => apiReducer)

    const [stock, setStock]     = useState("")
    const [project, setProject] = useState(null)
    const [totalSurface, setTotalSurface] = useState("")
    const [total, setTotal] = useState(0)

    useEffect(() => {
        setTotalSurface(((parseFloat(article.surface) || 0) * (parseFloat(article.quantity) || 0)).toFixed(2))
    }, [article.surface, article.quantity])

    useEffect(() => {
        setTotal(((parseFloat(totalSurface) || 0) * (parseFloat(article.unitPrice) || 0)).toFixed(2))
    }, [totalSurface, article.unitPrice])

    useEffect(() => {
        if(stock.length <= 0) {
            if(isDefined(article.project)) {
                setStock(article.project.number)
                onChangeProject(article.project)
            }
            else {
                setStock("")
                setProject(null)
            }
        }
    }, [article.project])

    const onDeleteProduct = (product) => {
        onDelete('', 'Etes-vous sûr de vouloir supprimer cet article ?', () => {
            dispatch(deleteProduct(product))
        })
    }

    const onChange = e => {
        dispatch(changeProduct(article.id, e.target.name, e.target.value))
    }

    const onChangeStock = (e, name, value) => {
        dispatch(getResourceByProperty('projects', 'number', value, rawToken))
            .then(id => {
                if (isDefined(id))
                    dispatch(getResource('projects', id, rawToken))
                        .then(res => {
                            setStock(value)
                            onChangeProject(res)
                        })
            })
    }

    const onChangeProject = project => {
        if(isDefined(project)) {
            setProject(project)
            dispatch(changeProduct(article.id, "project", project.id))
        }
        else {
            setProject(null)
            dispatch(changeProduct(article.id, "project", null))
        }
    }

    return (
        <tr key={index} className={"article"}>
            <td className={"ref-cell cell-input"}>
                <div className={"ref-container"}>
                    <OrdersArticlesModal row={article.id}/>
                    <input type={"text"} name={"reference"} value={article.reference} onChange={onChange}/>
                </div>
            </td>
            <td className={"cell-input"}>
                <textarea name={"designation"} value={article.designation} onChange={onChange} rows={1}/>
                <div className={"customer-name"}>
                    {isDefined(project) ? ("Client : " + project.customer.name) : ""}
                </div>
            </td>
            <td className={"cell-input stock-cell"}>
                <MyAutosuggest
                    disabled={loadingProjects}
                    searchWithoutName={true}
                    items={numbers.projects.reverse()}
                    name={"stock"}
                    placeholder={loadingProjects ? "Chargement ..." : "N° Projet"}
                    value={stock}
                    onChange={onChangeStock}/>

            </td>
            <td className={"cell-input medium-cell format-cell"}>
                <input type={"text"} name={"format"} value={article.format} onChange={onChange}/>
            </td>
            <td className={"cell-input small-cell text-right"}>
                <input type={"number"} min={0} step={0.01} name={"surface"} value={article.surface} onChange={onChange}/>
            </td>
            <td className={"cell-input small-cell text-right"}>
                <input type={"number"} min={0} step={0.01} name={"quantity"} value={article.quantity} onChange={onChange}/>
            </td>
            <td className={"medium-cell text-right"}>{totalSurface}</td>
            <td className={"cell-input small-cell text-right"}>
                <input type={"number"} min={0} step={0.01} name={"unitPrice"} value={article.unitPrice} onChange={onChange}/>
            </td>
            <td className={"total-cell text-right"}>{total + " €"}</td>
            <td>
                <div className={"delete-article btn-circle btn-danger"}
                     title={"Supprimer l'article"}
                     onClick={() => onDeleteProduct(article.id)}>
                    <i className="fas fa-trash-alt"/>
                </div>
            </td>
        </tr>
    )
}

export default ProductRow
