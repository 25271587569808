import React from 'react';
import {Link} from "react-router-dom";

import Page from "../../components/Page/Page";
import MessagesList from "./MessagesList";

const Messages = () => {
    const buttonsRight = () => (
        <Link to={"/messages/creer"}>
            <button className={"btn-circle add-resource"} title={"Ajouter"}>
                <i className={"fas fa-plus"} />
            </button>
        </Link>
    )

    return (
        <Page title={"Messagerie"} headerButtons={buttonsRight()}>
            <MessagesList/>
        </Page>
    )
}

export default Messages
