import {ERROR} from "./action";

export const
    TOGGLE_SIDENAV = 'TOGGLE_SIDENAV'
;

export const toggleSidenav = (open = null) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_SIDENAV,
            open: open
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};
