import React, {useState, useEffect} from 'react'
import { Link } from "react-router-dom"
import { useDispatch, useSelector} from "react-redux"

import "../../css/pages/orders/list.scss"
import {deleteResource} from "../../redux/action"
import Table from "../../components/Table"
import {TextFilter, SelectFilter} from "../../components/List/index"
import {getColumnsWidth, getDefaultFiltered} from "../../utils/List"
import {onDelete} from "../../utils/Alert"
import {formatDate} from "../../utils/Date"
import {isDefined} from "../../utils/Data";

const frenchName = "commandes"
const localName  = "orders"
const apiName    = 'orders'

let localNames   = ["number", "ordersDate", "provider.name", "title", "delivery", "user.firstname", "amount"]
localNames       = localNames.map(name => localName + '-' + name)

const OrdersList = () => {
    const dispatch = useDispatch()
    const {rawToken, orders} = useSelector(({apiReducer}) => apiReducer)

    const initialColumns = [
        {
            Header: 'N°',
            accessor: 'number',
            width: getColumnsWidth(localNames[0], 80),
        },
        {
            Header: 'Date',
            accessor: 'ordersDate',
            width: getColumnsWidth(localNames[1], 100),
            Cell: ({ row }) => formatDate(row._original.ordersDate)
        },
        {
            Header: 'Fournisseur',
            accessor: 'provider.name',
            width: getColumnsWidth(localNames[2], 350),
            Cell: ({ row }) => (
                <Link to={"/fournisseurs/" + row._original.provider.id + "/modifier"}>{row._original.provider.name}</Link>
            )
        },
        {
            Header: 'Intitutlé',
            accessor: 'title',
            width: getColumnsWidth(localNames[3], 600),
        },
        {
            Header: 'Livraison',
            accessor: 'delivery',
            width: getColumnsWidth(localNames[4], 200),
            Filter: ({filter, onChange}) => <SelectFilter localName={localNames[4]} filter={filter} onChange={onChange} options={[{text: "Reims", value: 0}, {text: "Saint Martin sur le Pré", value: 1}]} default={"Toutes"}/>,
            Cell: ({row}) => row._original.delivery === true ? "Saint Martin sur le Pré" : "Reims",
        },
        {
            Header: 'Utilisateur',
            accessor: 'user.firstname',
            width: getColumnsWidth(localNames[5], 200),
            Cell: ({ row }) => isDefined(row._original.user) ? row._original.user.firstname : ""
        },
        {
            Header: 'Montant total',
            accessor: 'amount',
            sortable: false,
            resizable: false,
            filterable: false,
            width: getColumnsWidth(localNames[6], 150),
            Cell: ({row}) => {
                let total = 0
                row._original.productsOrders.forEach(product => {
                    const totalSurface = (parseFloat(product.surface) || 0) * (parseFloat(product.quantity) || 0)
                    total = total + ((parseFloat(totalSurface) || 0) * (parseFloat(product.unitPrice) || 0))
                })
                return <div className={"col-amount"}>{parseFloat(total).toFixed(2) + " €"}</div>
            }

        },
        {
            Header: 'Actions',
            accessor: 'actions',
            sortable: false,
            resizable: false,
            width: 100,
            Cell: ({ row }) => (
                <div className={"btn-flex"}>
                    <Link to={"/commandes/" + row._original.id + "/modifier"}>
                        <div className="btn-circle btn-warning" title={"Modifier"}>
                            <i className="fa fa-edit"/>
                        </div>
                    </Link>
                    <div className="btn-circle btn-danger" title={"Supprimer"}
                         onClick={() => handleDelete(row._original.id)}>
                        <i className="fa fa-trash"/>
                    </div>
                </div>
            )
        },
    ]

    const [columns, setColumns] = useState(initialColumns)

    useEffect(() => {
        setColumns(prev => prev.map((column, i) => {
            if (i < prev.length && i !== 4)
                column.Filter = ({filter, onChange}) => <TextFilter localName={localNames[i]} filter={filter} onChange={onChange}/>

            return column
        }))
    }, [])


    const handleDelete = id => onDelete('', 'Etes-vous sûr de vouloir supprimer cette commande ?', () => dispatch(deleteResource(apiName, id, rawToken)));

    return (
        <Table
            name={localName + "-list"}
            data={orders}
            columns={columns}
            frenchName={frenchName}
            localName={localName}
            apiName={apiName}
            className={"orders-list"}
            defaultFiltered={getDefaultFiltered(localName, columns)}
            defaultSorted={[
                {
                    id: "number",
                    desc: true
                }
            ]}
        />
    )
}


export default OrdersList
