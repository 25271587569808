import React, {Component} from 'react';
import { Link } from "react-router-dom"
import {connect} from "react-redux";

import {
    addChildToParent,
    deleteResource,
    getResources,
    updateResource
} from "../../redux/action";
import Table from "../../components/Table";
import ColorPickerList from "../../components/ColorPickerList";
import {getColumnsWidth, getDefaultFiltered} from "../../utils/List";
import {TextFilter, FreeTextInput} from "../../components/List/index";
import {onDelete} from "../../utils/Alert";
import {renderIfDefined} from "../../utils/Data";

class ProductsList extends Component {
    constructor(props) {
        super(props);

        this.frenchName = "produits";
        this.localName  = "product";
        this.apiName    = 'book_products';

        this.localNames = ['reference', 'code', 'free', 'provider.name', 'type.name', 'thickness', 'format', 'surface', 'designation', 'unitPrice', 'unity', 'marginCoefficient'];
        this.localNames = this.localNames.map(name => this.localName + '-' + name);

        this.state = {
            rowOpenColor: null,
            displayColorPicker: false,
            columns: [
                {
                    Header: 'Reférence',
                    accessor: 'reference',
                    width: getColumnsWidth(this.localNames[0], 90)
                },
                {
                    Header: 'Code',
                    accessor: 'code',
                    width: getColumnsWidth(this.localNames[1], 80),
                },
                {
                    Header: 'Sem',
                    accessor: 'free',
                    width: getColumnsWidth(this.localNames[2], 60),
                    filterMethod: (filter, row) => {
                        if(row._original.free !== null) {
                            if(filter.value.toLowerCase() === 'nv')
                                return row._original.free !== null;
                            else
                                return row._original.free.toLowerCase() === filter.value.toLowerCase()
                        }
                        else {
                            if(filter.value.toLowerCase() === 'v')
                                return row._original.free === null;
                            else
                                return false
                        }
                    },
                    Cell: ({row}) => {
                        const id = row._original.id;
                        return (
                            <FreeTextInput
                                value={renderIfDefined(row._original.free)}
                                id={id}
                                update={(type, body) => {this.props.dispatch(updateResource(this.apiName + type, id, body, this.props.rawToken))}}
                            />
                            /*<input
                                key={id}
                                type={"text"}
                                name={"free-" + id}
                                value={row._original.free}
                                onChange={(e) => this.onChangeFree(e, id)}
                                className={"product-free-input"}
                            />*/)
                    }
                },
                {
                    Header: 'Fournisseur',
                    accessor: 'provider.name',
                    width: getColumnsWidth(this.localNames[3], 200),
                },
                {
                    Header: 'Type',
                    accessor: 'type.name',
                    width: getColumnsWidth(this.localNames[4], 150),
                },
                {
                    Header: 'Epaisseur',
                    accessor: 'thickness',
                    width: getColumnsWidth(this.localNames[5], 80),
                },
                {
                    Header: 'Format',
                    accessor: 'format',
                    width: getColumnsWidth(this.localNames[6], 150),
                },
                {
                    Header: 'Surface',
                    accessor: 'surface',
                    width: getColumnsWidth(this.localNames[7], 80),
                },
                {
                    Header: 'Désignation',
                    accessor: 'designation',
                    width: getColumnsWidth(this.localNames[8], 500),
                },
                {
                    Header: 'Prix Unitaire',
                    accessor: 'unitPrice',
                    width: getColumnsWidth(this.localNames[9], 80),
                    Cell: ({ row }) => {
                        const unitPrice = parseFloat(row._original.unitPrice) || 0;
                        const mlPrice   = parseFloat(row._original.mlPrice) || 0;
                        const m2Price   = parseFloat(row._original.squarePrice) || 0;

                        return (
                            <div style={{ textAlign: 'right'}}>
                                {unitPrice > 0 ?
                                    unitPrice.toFixed(2) + ' €'
                                    : mlPrice > 0 ? mlPrice.toFixed(2) + ' €'
                                        : m2Price > 0 ? m2Price.toFixed(2) + ' €'
                                            : ''}
                            </div>)
                    }
                },
                {
                    Header: 'Unité',
                    accessor: 'unity',
                    width: getColumnsWidth(this.localNames[10], 80),
                    Cell: ({ row }) => {
                        const unitPrice = row._original.unitPrice;
                        const mlPrice = row._original.mlPrice;
                        const m2Price = row._original.squarePrice;

                        return unitPrice !== null ? 'Unitaire' : mlPrice !== null ? 'mL' : m2Price !== null ? 'm²' : '';
                    }
                },
                {
                    Header: 'Coefficient',
                    accessor: 'marginCoefficient',
                    width: getColumnsWidth(this.localNames[11], 80),
                },
                {
                    Header: 'Actions',
                    accessor: 'id',
                    sortable: false,
                    resizable: false,
                    width: 150,
                    Cell: ({ row }) => (
                        <div className={"btn-flex"}>
                            {this.props.addButton === true ?
                                <div className="btn-circle btn-primary" title={"Ajouter"}
                                     onClick={() => this.handleAdd(row._original.id)}>
                                    <i className="fas fa-upload"/>
                                </div>
                                : ''}
                            <div className="btn-circle btn-color" title={"Mettre en couleur"} onClick={() => this.handleColor(row._original.id)}>
                                <i className="fa fa-tint"/>
                            </div>
                            <Link to={"/produits/" + row._original.id + "/modifier"}>
                                <div className="btn-circle btn-warning" title={"Modifier"}>
                                    <i className="fa fa-edit"/>
                                </div>
                            </Link>
                            <div className="btn-circle btn-danger" title={"Supprimer"}
                                 onClick={() => this.handleDelete(row._original.id)}>
                                <i className="fa fa-trash"/>
                            </div>
                        </div>
                    )
                },
            ]
        };
    }

    componentDidMount() {
        this.props.dispatch(getResources('colors', this.props.rawToken));
        this.state.columns.forEach((column, i) => {
            if(i < this.state.columns.length)
                column.Filter = ({filter, onChange}) => <TextFilter localName={this.localNames[i]} filter={filter} onChange={onChange}/>
        })
    }

    handleColor = (id = null) => {
        this.setState(state => {
            return {
                rowOpenColor: id,
                displayColorPicker: !state.displayColorPicker
            }
        })
    };

    selectColor = (color = null) => {
        const colorApi = this.props.colors.find(item => item.hexa === color.hex);
        if(typeof colorApi !== "undefined")
            this.props.dispatch(addChildToParent(this.state.rowOpenColor, colorApi.id, this.apiName, 'colors', this.props.rawToken))
        this.handleColor()
    };

    onChangeFree = (e, id) => {
        e.preventDefault();

        const value = e.target.value;
        const body = {free: value};

        this.props.dispatch(updateResource(this.apiName, id, body, this.props.rawToken))
    };

    handleAdd = id => this.props.handleAdd(id);

    handleDelete = id => onDelete('', 'Etes-vous sûr de vouloir supprimer ce produit ?', () => this.props.dispatch(deleteResource(this.apiName, id, this.props.rawToken)));

    sortColor = () => this.props.colors.sort((c, c2) => {
        if(c.name > c2.name)
            return 1
        else if(c.name < c2.name)
            return -1
        else
            return 0
    })

    render() {
        return (
            <>
                <ColorPickerList
                    display={this.state.displayColorPicker}
                    handleClose={() => this.handleColor()}
                    colors={this.sortColor().map((color) => color.hexa)}
                    handleChange={(color) => this.selectColor(color)}
                />

                <Table
                    name={this.localName + "-list"}
                    data={this.props.products.filter(product => product.madeByForm === true)}
                    columns={this.state.columns}
                    frenchName={this.frenchName}
                    localName={this.localName}
                    apiName={this.apiName}
                    defaultFiltered={getDefaultFiltered(this.localName, this.state.columns)}
                    defaultSorted={[
                        {
                            id: "reference",
                            desc: false
                        }
                    ]}
                />
            </>
        )
    }
}

const mapStateToProps = ({apiReducer}) => {
    return {
        rawToken: apiReducer.rawToken,
        products: apiReducer.products,
        colors: apiReducer.colors,
    }
};

export default connect(mapStateToProps)(ProductsList)
