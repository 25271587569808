import React, {Component} from 'react';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {getNewNum, getNewNumModel, getResource, getResources} from "../../redux/action";
import {resetQuotationForm} from "../../redux/QuotationFormAction";

class QuotationsFormModel extends Component {
    componentDidMount() {
        this.props.dispatch(getResources(this.props.type, this.props.rawToken));
    }

    changeHandler = (e, newName, newValue, callback) => this.props.changeHandler(e, newName, newValue, callback);

    resourceToInput = (input) => this.props.resourceToInput(input);

    changeState = (value, callback) => this.props.changeState(value, callback);

    onSelectModel = (e) => {
        e.preventDefault();

        const value = e.target.value;

        if(value === 'default') {
            this.props.dispatch(resetQuotationForm()).then(
                this.props.dispatch(getNewNum(this.props.type, this.props.rawToken))
                    .then(() => this.changeHandler(null, 'number', this.props.newNum)));
            this.props.selectModel(false);
        }
        else {
            this.props.dispatch(getResource(this.props.type, value, this.props.rawToken))
                .then((quotation) => {
                    this.changeState(quotation,
                        () => Object.keys(this.props.data).map((input ) => {
                            this.resourceToInput(input);
                            this.props.dispatch(getNewNumModel(this.props.type, this.props.rawToken))
                                .then(() => this.changeHandler(null, 'number', this.props.newNum))
                        }));
                });
            this.props.selectModel();
        }
    };

    render() {
        const models = this.props.models.filter(quotation => quotation.model === true);

        return(
            <div className={"form-group"}>
                <select name={"models"} className={"models"} onChange={this.onSelectModel}>
                    <option value={"default"}>Choisir un modèle</option>
                    {models.map((model, i) =>
                        <option key={i} value={model.id}>{'N° ' + model.number + ' - ' + model.name + ' (' + parseFloat(model.totalPrice).toFixed(2) + ' €)'}</option>
                    )}
                </select>
            </div>
        )
    }
}

const mapStateToProps = ({apiReducer, quotationFormReducer}) => {
    return {
        rawToken: apiReducer.rawToken,
        models: apiReducer.quotations,
        data: quotationFormReducer.form,
        newNum: apiReducer.newNum
    }
};

export default withRouter(connect(mapStateToProps)(QuotationsFormModel))
