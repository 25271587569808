import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom'

import "../../css/pages/permissions.scss"
import Page from "../../components/Page/Page";
import {addChildToParent, deleteResource, getResources} from "../../redux/action";
import Table from "../../components/Table/Table";
import {onDelete} from "../../utils/Alert";

const Permissions = () => {
    const dispatch = useDispatch()
    const {roles, permissions, rawToken} = useSelector(({apiReducer}) => apiReducer)

    useEffect(() => {
        dispatch(getResources('roles', rawToken))
        dispatch(getResources('permissions', rawToken))
    }, [])

    useEffect(() => {
        setTable(getTable)
    }, [roles, permissions])

    const contain = (array, value) => {
        let bool = false;
        for(let i = 0; i < array.length; i++) {
            if(array[i] === value)
                bool = true;
        }
        return bool;
    }

    const getTable = permissions.map(permission => roles.map(role => role.name === "SUPER_ADMIN" || contain(role.permissions.map(p => p.id), permission.id)))

    const [table, setTable] = useState(getTable)

    const onChange = (e, row, col) => {
        const role          = roles[row]
        const permission    = permissions[col]
        dispatch(addChildToParent(role.id, permission.id, 'roles', 'permissions', rawToken))
    }

    const buttonsRight = () => (
        <Link to={"/permissions/creer"}>
            <button className={"btn-circle add-resource"} title={"Ajouter"}>
                <i className={"fas fa-plus"} />
            </button>
        </Link>
    )

    const handleDelete = id => onDelete('', 'Etes-vous sûr de vouloir supprimer cette permission ?', () => dispatch(deleteResource("permissions", id, rawToken)));

    return (
        <Page title={"Permissions"} headerButtons={buttonsRight()} className={"permissions"}>
            <Table
                head={
                    <tr>
                        <th>Permissions \ Rôles</th>
                        {roles.map((role, i) => <th key={i}><Link to={"/role/" + role.id}>{role.description}</Link></th>)}
                    </tr>
                }
                body={
                    table.map((row, i) =>
                    <tr key={i} title={permissions[i].description}>
                        <td key={i}>{permissions[i].description}</td>
                        {row.map((col, j) => <td key={permissions[i].id}>
                            <input type={"checkbox"} onChange={e => onChange(e, j, i)} checked={col}/>
                        </td>)}
                        <td>
                            <div className={"btn-flex"}>
                                <Link to={"/permissions/" + permissions[i].id + "/modifier"}>
                                    <div className="btn-circle btn-warning" title={"Modifier"}>
                                        <i className="fa fa-edit"/>
                                    </div>
                                </Link>
                                <div className="btn-circle btn-danger" title={"Supprimer"}
                                     onClick={() => handleDelete(permissions[i].id)}>
                                    <i className="fa fa-trash"/>
                                </div>
                            </div>
                        </td>
                    </tr>)
                }
            />
        </Page>
    )
}

export default Permissions
