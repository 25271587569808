import React, {Component} from 'react'
import {connect} from "react-redux"

import "../../css/pages/calendar/calendar.scss";
import {getResources} from "../../redux/action";
import CalendarTable from "./CalendarTable";
import CalendarForm from "./CalendarForm";
import CalendarTop from "./CalendarTop";
import CalendarShowUser from "./CalendarShowUser";
import Page from "../../components/Page/Page";

class Calendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showMadeSteps: false,
            userSelected: null,
            steps: this.getInitialSteps(),
        }
    }

    componentDidMount() {
        this.props.dispatch(getResources('stepsOfWeek', this.props.rawToken));
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({loading: true})
        this.getSteps(nextProps)
        this.setState({loading: false})
    }

    getSteps = (props) => {
        if(this.state.userSelected !== null)
            this.setState(state => {
                return {
                    ...state,
                    steps: props.steps.filter(step => step.planned !== null).filter(step => step.users.map(user => user.id).includes(this.state.userSelected))
                }
            })
        else
            this.setState(state => {
                return {
                    ...state,
                    steps: props.steps.filter(step => step.planned !== null)
                }
            })
    }

    getInitialSteps = () => this.props.steps.filter(step => step.planned !== null);

    toggleMadeSteps = () => this.setState(state => { return {showMadeSteps: !state.showMadeSteps}});

    toggleSelectUser = value => {
        if(value !== 'default' && value !== null) {
            value = parseInt(value, 10);
            this.setState(state => {
                return {
                    ...state,
                    userSelected: value,
                    steps: this.getInitialSteps().filter(step => step.users.map(user => user.id).includes(value))
                }
            });
        }
        else
            this.setState(state => {
                return {
                    ...state,
                    userSelected: null,
                    steps: this.getInitialSteps()
                }
            });
    };

    setLoading = (loading = true) => {
        this.setState({loading: loading})
    }

    render() {
        return (
            <Page className={"calendar " + (this.state.loading === true ? "loading" : "")}>
                {this.state.loading === true ? <div className={"overlay-loading"}/> : ""}
                <CalendarTop toggleMadeSteps={this.toggleMadeSteps} showMadeSteps={this.state.showMadeSteps} setLoading={this.setLoading}/>

                <div className={"content"}>
                    <CalendarShowUser value={this.state.userSelected} toggleSelectUser={user => this.toggleSelectUser(user)}/>
                    <CalendarForm setLoading={this.setLoading}/>
                </div>

                <CalendarTable loading={this.state.loading} steps={this.state.showMadeSteps ? this.state.steps : this.state.steps.filter(step => step.made === null)}/>
            </Page>
        )
    }
}

const mapStateToProps = ({apiReducer, calendarReducer}) => {
    return {
        rawToken: apiReducer.rawToken,
        steps: apiReducer.steps,
        date: calendarReducer.date,
    }
};

export default connect(mapStateToProps)(Calendar)
