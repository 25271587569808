import React from "react"
import {useSelector} from "react-redux";

import "../../css/components/menu.scss"
import MenuItem from "./MenuItem"
import MenuItemWithSubMenu from "./MenuItemWithSubMenu"
import {checkPermissions} from "../../security/Permissions"

const Menu = ({mobile}) => {
    const {user} = useSelector(({apiReducer}) => apiReducer)
    const {menu} = useSelector(({designReducer}) => designReducer)

    return (
        <ul className={mobile ? "sr-menu-mobile" : "sr-menu"}>
            <MenuItem
                mobile={mobile}
                icon={"fas fa-columns"}
                title={"Tableau de bord"}
                link={"/"}/>
        {checkPermissions('projets', user) ?
            <MenuItem
                mobile={mobile}
                icon={"fas fa-archive"}
                title={"Projets"}
                link={"/projets"}/>
            : ''
        }
        {checkPermissions('devis', user) || checkPermissions('factures', user) || checkPermissions('articles', user) || checkPermissions('categories-articles', user) || checkPermissions('suivi-activite', user) ?
            <MenuItemWithSubMenu
                mobile={mobile}
                icon={"fas fa-coins"}
                title={"Comptabilité"}
                subMenu={menu.accounting}/>
            : ''
        }
        {checkPermissions('catalogue', user) || checkPermissions('fournisseurs', user) || checkPermissions('types-produits', user) ?
            <MenuItemWithSubMenu
                mobile={mobile}
                icon={"fas fa-book"}
                title={"Stock"}
                subMenu={menu.catalogue}/>
            : ''}
        {checkPermissions('clients', user) ?
            <MenuItem
                mobile={mobile}
                icon={"fas fa-user-friends"}
                title={"Clients"}
                link={"/clients"}/>
            : ''
        }
            <MenuItemWithSubMenu
                mobile={mobile}
                icon={"fas fa-tools"}
                title={"Paramètres"}
                subMenu={menu.parameters}/>
            <MenuItem
                mobile={mobile}
                icon={"fas fa-book"}
                title={"Répertoire"}
                link={"/repertoire"}/>
        </ul>
    )
}

export default Menu
