import React from "react";

const DeliverySettlementPrint = ({resource, type}) => (
    type === 'devis' ?
        <div className={"row"}>
            <div className="col-7">
                <div className="row">
                    <div className="col-12">
                        <div className="panel panel-default">
                            <p className={"deliveryDelay"}>
                                DELAI DE REALISATION <span className={"bold"}>{resource.deliveryDelay !== null ? resource.deliveryDelay.name : ''}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="panel panel-default">
                            <p className={"deliveryDelay"}>
                                MODE DE REGLEMENT <span className={"bold"}>{resource.settlement !== null ? resource.settlement.name : ''}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-5">
                <div className="panel panel-default signature">
                <p className={"deliveryDelay"}>BON POUR ACCORD, SIGNATURE</p>
            </div>
        </div>
    </div>
    :
        <div className={"row"}>
            <div className="col-12">
                <div className="panel panel-default">
                    <p className={"deliveryDelay"}>
                        MODE DE REGLEMENT <span className={"bold"}>{resource.settlement !== null ? resource.settlement.name : ''}</span>
                    </p>
                </div>
            </div>
        </div>
);

export default DeliverySettlementPrint
