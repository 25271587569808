import React from "react"
import {useSelector} from "react-redux"
import ReactLoading from "react-loading"

import ProductRow from "./ProductRow"
import {isDefined} from "../../../utils/Data"

const OrdersFormList = ({loadingProjects}) => {
    const productsOrders      = useSelector(({orderFormReducer}) => orderFormReducer.data.productsOrders)

    return (isDefined(productsOrders) ? productsOrders.length > 0 ?
        <table className={"articles-list"}>
            <thead>
                <tr>
                    <th>Référence</th>
                    <th>Désignation</th>
                    <th>Stock</th>
                    <th>Format</th>
                    <th>Surface</th>
                    <th>Quantité</th>
                    <th>Surface totale</th>
                    <th>Prix unitaire</th>
                    <th>Prix total</th>
                    <th/>
                </tr>
            </thead>
            <tbody>
            {productsOrders.map((article, i) => loadingProjects ?
                <ReactLoading  type={"spin"} color={"#f8ac59"} height={70} width={70} className={"sr-loading"} />
                :
                <ProductRow article={article} index={i} loadingProjects={loadingProjects}/>)}
            </tbody>
        </table>
    : "" : "")
}

export default OrdersFormList
