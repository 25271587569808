import React, {Component} from 'react';
import {connect} from "react-redux";

class MeasuresForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMeasure: localStorage.getItem('quotationFormMeasure-' + this.props.id + '-show') !== null ? JSON.parse(localStorage.getItem('quotationFormMeasure-' + this.props.id + '-show')) : true,
        }
    }

    handleVisibility = (stateName, localStorageName) => {
        this.setState({
            [stateName]: !this.state[stateName]
        });
        localStorage.setItem(localStorageName + '-' + this.state.id + '-show', this.state[stateName] === true ? 'false' : 'true');
    };

    addMeasureForm = (e, measure = null, i = null) => this.props.addMeasureForm(e, measure, i);

    render() {
        return (
           <div className={"measures-form form-block"}>
               <div className={"measures-form-header form-group"}>
                   <button onClick={this.addMeasureForm}>Ajouter une mesure</button>

                   <div className={"btn-header-right show-measure"}>
                       <div className="btn-circle btn-primary btn-hide" title={this.state.showMeasure === true ? 'Cacher' : 'Voir'}  onClick={() => this.handleVisibility('showMeasure', 'quotationFormMeasure')}>
                           <i className={this.state.showMeasure === true ? 'fa fa-eye-slash' : 'fa fa-eye'}/>
                       </div>
                   </div>
               </div>

               <table className={"measures-table"} style={this.state.showMeasure === true ? {display: 'block'} : {display: 'none'}}>
               {this.props.measureForms.length > 0 ? (
                   <>
                       <thead>
                           <tr>
                               <th>Largeur</th>
                               <th>Hauteur</th>
                               <th>Surface</th>
                               <th>Quantité</th>
                               <th>Surface totale</th>
                               <th>Coeff. pub</th>
                               <th>Surf. pub</th>
                               <th>Périmètre</th>
                               <th/>
                           </tr>
                       </thead>
                       <tbody>
                         {this.props.measureForms}
                       </tbody>
                       <tfoot>
                           <tr>
                               <td>{this.props.measureTotal.width}</td>
                               <td>{this.props.measureTotal.height}</td>
                               <td>{this.props.measureTotal.surface}</td>
                               <td>{this.props.measureTotal.quantity}</td>
                               <td>{this.props.measureTotal.totalSurface}</td>
                               <td>{this.props.measureTotal.pubCoefficient}</td>
                               <td>{this.props.measureTotal.surfCoefficient}</td>
                               <td>{this.props.measureTotal.perimeter}</td>
                               <td/>
                           </tr>
                       </tfoot>
                   </>) : <></>}
               </table>
           </div>
        )
    }
}

const mapStateToProps = ({apiReducer, quotationFormReducer}) => {
    return {
        rawToken: apiReducer.rawToken,
        measureForms: quotationFormReducer.measureForms,
        measureTotal: quotationFormReducer.measureTotal,
        measureFormsId: quotationFormReducer.measureFormsId,
    }
};

export default connect(mapStateToProps)(MeasuresForm)
