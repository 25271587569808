import React from "react"
import {useDispatch, useSelector} from "react-redux"
import CreatableSelect  from 'react-select/creatable'

import {changeQuotation} from "../../../redux/QuotationFormAction"

const DeliveryDelay = () => {
    const dispatch          = useDispatch()
    const {deliveryDelays}  = useSelector(({apiReducer}) => apiReducer)
    const form              = useSelector(({quotationFormReducer}) => quotationFormReducer.form)

    const options = deliveryDelays.sort().map(item => {
        return {
            value: item.id,
            label: item.name
        }
    })

    const onChange = obj => {
        dispatch(changeQuotation(null, "deliveryDelay", obj.value))
    }

    return (
        <>
            <label>Délai de livraison</label>
            <CreatableSelect
                disabled={!deliveryDelays.length > 0}
                options={options}
                name={"deliveryDelay"}
                placeholder={deliveryDelays.length > 0 ? "Délai de livraison" : "Chargement ..."}
                value={options.find(option => option.value === form.deliveryDelay.value)}
                onChange={onChange}
            />
        </>
    )
}

export default DeliveryDelay


