import {ERROR} from "./action";
export const
    CHANGE_QUOTATION = 'CHANGE_QUOTATION',
    ADD_MEASURE_FORM = 'ADD_MEASURE_FORM',
    DELETE_MEASURE_FORM = 'DELETE_MEASURE_FORM',
    ADD_MEASURE = 'ADD_MEASURE',
    DELETE_MEASURE = 'DELETE_MEASURE',
    CHANGE_MEASURE = 'CHANGE_MEASURE',
    CHANGE_TOTAL_MEASURE = 'CHANGE_TOTAL_MEASURE',
    ADD_GROUP_FORM = 'ADD_GROUP_FORM',
    DELETE_GROUP_FORM = 'DELETE_GROUP_FORM',
    ADD_PRODUCT_FORM = 'ADD_PRODUCT_FORM',
    DELETE_PRODUCT_FORM = 'DELETE_PRODUCT_FORM',
    ADD_PRODUCT = 'ADD_PRODUCT',
    CHANGE_PRODUCT = 'CHANGE_PRODUCT',
    RESET_FORM = 'RESET_FORM',
    DELETE_PRODUCT = 'DELETE_PRODUCT',
    ADD_ITEM_TO_INPUT = 'ADD_ITEM_TO_INPUT',
    CHANGE_TOTAL_GROUP_PRODUCTS = "CHANGE_TOTAL_GROUP_PRODUCTS",
    CHANGE_VALIDITY_PROPERTY    = "CHANGE_VALIDITY_PROPERTY",
    CHECK_FORM_VALIDITY         = "CHECK_FORM_VALIDITY"


export const addMeasureForm = (form) => async dispatch => {
    try {
        dispatch({
            type: ADD_MEASURE_FORM,
            form: form
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const deleteMeasureForm = (id) => async dispatch => {
    try {
        dispatch({
            type: DELETE_MEASURE_FORM,
            id: id
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const addMeasure = (id, measure, idBase) => async dispatch => {
    try {
        dispatch({
            type: ADD_MEASURE,
            id: id,
            measure: measure,
            idBase: idBase,
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const deleteMeasure = (id) => async dispatch => {
    try {
        dispatch({
            type: DELETE_MEASURE,
            id: id
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const changeQuotation = (event, newName, newValue) => async dispatch => {
    try {
        const name  = newName !== undefined ? newName : event.target.name;
        const value = newValue !== undefined ? newValue : event.target.value;

        dispatch({
            type: CHANGE_QUOTATION,
            name: name,
            value: value,
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const changeMeasure = (event, id, newName, newValue) => async dispatch => {
    try {
        const name  = newName !== undefined ? newName : event.target.name;
        const value = newValue !== undefined ? newValue : event.target.value;

        dispatch({
            type: CHANGE_MEASURE,
            name: name,
            value: value,
            id: id
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const changeTotalMeasure = (name) => async dispatch => {
    try {
        dispatch({
            type: CHANGE_TOTAL_MEASURE,
            name: name,
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const addGroupForm = (id, name, form) => dispatch => {
    try {
        dispatch({
            type: ADD_GROUP_FORM,
            id: id,
            name: name,
            form: form,
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const deleteGroupForm = (id) => async dispatch => {
    try {
        dispatch({
            type: DELETE_GROUP_FORM,
            id: id
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const addProductForm = (groupFormId, form) => async dispatch => {
    try {
        dispatch({
            type: ADD_PRODUCT_FORM,
            groupFormId: groupFormId,
            form: form
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const deleteProductForm = (groupFormId, productFormId) => async dispatch => {
    try {
        dispatch({
            type: DELETE_PRODUCT_FORM,
            groupFormId: groupFormId,
            productFormId: productFormId
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const addProduct = (id, /*group,*/ idGroup, idBase = null) => async dispatch => {
    try {
        dispatch({
            type: ADD_PRODUCT,
            id: id,
            //group: group,
            idGroup: idGroup,
            idBase: idBase,
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const deleteProduct = (id) => async dispatch => {
    try {
        dispatch({
            type: DELETE_PRODUCT,
            id: id
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const changeProduct = (event, id, newName, newValue) => async dispatch => {
    try {
        const name  = newName !== undefined ? newName : event.target.name;
        const value = newValue !== undefined ? newValue : event.target.value;

        dispatch({
            type: CHANGE_PRODUCT,
            name: name,
            value: value,
            id: id
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const resetQuotationForm = () => async dispatch => {
    try {
        dispatch({
            type: RESET_FORM,
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const changeTotalGroupProducts = (idGroup, name = null) => async dispatch => {
    try {
        dispatch({
            type: CHANGE_TOTAL_GROUP_PRODUCTS,
            idGroup: idGroup,
            name: name,
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const changeValidityOfProperty = (name, validity) => dispatch => {
    try {
        dispatch({
            type: CHANGE_VALIDITY_PROPERTY,
            name: name,
            validity: validity
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const resetWhenOnChangeProject = () => dispatch => {
    dispatch(changeQuotation(null, 'projectId', null));
    dispatch(changeQuotation(null, 'project', ''));
    dispatch(changeQuotation(null, 'name', ''));
    dispatch(changeQuotation(null, 'customerId', null));
    dispatch(changeQuotation(null, 'customer', ''));
    dispatch(changeQuotation(null, 'customerName',''));
};

export const resetWhenOnChangeCustomer = () => dispatch => {
    dispatch(changeQuotation(null, 'customerId', null));
    dispatch(changeQuotation(null, 'customer', ''));
    dispatch(changeQuotation(null, 'customerName', ''));
};

export const checkFormValidity = (data) => dispatch => {
    try {
        const {number, customerName, project} = data
        const isValid = number.valid === true && customerName.valid === true && project.valid === true

        dispatch({
            type: CHECK_FORM_VALIDITY,
            isValid
        })


        return isValid
    }
    catch (e) {
        console.log(e)
    }
}
