import React, {useState, useEffect, useRef} from "react"

const TablePrint = ({resource, type}) => {
    const tableElement          = useRef(null);
    const [height, setHeight]   = useState(0)

    useEffect(() => {
        if(tableElement.current) {
            const heightPx  = tableElement.current.offsetHeight;
            let newHeight   = 0
            const heightCm  = heightPx * 0.0264583333

            if(type === 'devis') {
                if(heightCm <= 23)
                    newHeight = 23 - heightCm;
                else if(heightCm > 23 && heightCm <= 55)
                    newHeight = 55 - heightCm;
                else if(heightCm > 55 && heightCm <= 87)
                    newHeight = 87 - heightCm;
            }
            else {
                if(heightCm <= 24)
                    newHeight = 24 - heightCm;
                else if(heightCm > 24 && heightCm <= 56)
                    newHeight = 56 - heightCm;
                else if(heightCm > 56 && heightCm < 88)
                    newHeight = 88 - heightCm;
            }

            setHeight(newHeight)
        }
    }, [tableElement])

    return (
        <div className={"row articles-row"}>
            <div className={"col-12"}>
                <table
                    className="articles"
                    id="tablePrintDevis"
                    ref={tableElement}
                >
                    <thead>
                    <tr>
                        <th>DESIGNATION</th>
                        <th>QTE</th>
                        <th>P.U. HT</th>
                        <th>MONTANT HT</th>
                    </tr>
                    </thead>
                    <tbody>
                    {resource.articles.sort((row, row2) => row.position - row2.position).map((article, i) => (
                        <tr key={i}>
                            <td className={"text-article"} dangerouslySetInnerHTML={{__html: article.text}}/>
                            <td className={"amounts-article"}>
                                {article.quantity !== "0" ? article.quantity : ''}
                            </td>
                            <td className={"amounts-article"}>
                                {article.unitPrice !== "0.00" ? parseFloat(article.unitPrice).toFixed(2) : ''}
                            </td>
                            <td className={"amounts-article"}>
                                {article.quantity !=="0" && article.unitPrice !== "0.00" ? parseFloat(article.quantity * article.unitPrice).toFixed(2) : ''}
                            </td>
                        </tr>
                    ))}
                    <tr id={"rowHeight"} style={{ height: height + 'cm' }}>
                        <td/><td/><td/><td/>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TablePrint
