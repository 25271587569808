import React, {Component} from 'react'
import {connect} from "react-redux"
import { withRouter } from "react-router"

import {createResource, getResource, updateResource} from "../../redux/action"
import Page from "../../components/Page/Page"

class ProductTypesForm extends Component {
    constructor(props) {
        super(props);

        const pathname = window.location.pathname;
        const split = pathname.split('/');

        this.state = {
            id: split[2],
            product: null,
            form: {
                name: {
                    value: ''
                },
            }
        }
    }

    componentDidMount() {
        if(this.state.id !== 'creer') {
            this.props.dispatch(getResource('productTypes', this.state.id, this.props.rawToken))
                .then((productType) => {
                    this.setState({
                        productType: productType,
                    });
                    Object.keys(this.state.form).map((input) => this.productTypeToInput(input));
                });
        }
    }

    productTypeToInput = (input) => {
        this.setState({
            form: {
                ...this.state.form,
                [input]: {
                    ...this.state.form[input],
                    value: this.state.productType[input] !== null ? this.state.productType[input] : ''
                },
            }
        });
    };

    changeHandler = e => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            form: {
                ...this.state.form,
                [name]: {
                    ...this.state.form[name],
                    value
                }
            }
        })
    };

    handleSubmit = e => {
        e.preventDefault();

        let body = {};
        Object.keys(this.state.form).map((input) =>
            body[input] = this.state.form[input].value
        );

        if(this.state.id === 'creer') {
            this.props.dispatch(createResource('productTypes', body, this.props.rawToken)).then(() => this.props.history.goBack())
        }
        else {
            this.props.dispatch(updateResource('productTypes', this.state.id, body, this.props.rawToken)).then(() => this.props.history.goBack())
        }
    };

    render() {
        return (
            <Page title={this.state.id !== null && this.state.id !== 'creer' ? 'Modification d\'un type de produits' : 'Création d\'un type de produits'}>
                <form onSubmit={this.handleSubmit} className={"form-block form-group"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <label>Désignation</label>
                            <input type={"text"} name={"name"} placeholder={"Désignation"} value={this.state.form.name.value} onChange={this.changeHandler}/>
                        </div>
                    </div>
                    {this.props.id !== undefined || (this.state.id !== null && this.state.id !== 'creer') ? <button title={"Modifier"}>Modifier</button> :  <button title={"Créer"}>Créer</button>}
                </form>
            </Page>
        )
    }
}

const mapStateToProps = ({apiReducer}) => {
    return {
        rawToken: apiReducer.rawToken,
    }
};

export default withRouter(connect(mapStateToProps)(ProductTypesForm))
