import React, {Component} from 'react';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import HeaderBlock from "../../../components/HeaderBlock";
import {createResource, deleteResource, getResources, updateResource} from "../../../redux/action";
import {connect} from "react-redux";
import Modal from 'react-modal';
import "../../../css/components/modal.scss"
import {onDelete} from "../../../utils/Alert";

class DeliveryDelay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: localStorage.getItem('settings-deliveryDelay-show') !== null ? JSON.parse(localStorage.getItem('settings-deliveryDelay-show')) : true,
            deliveryDelayUpdated: null,
            modalIsOpen: false,
            form: {
                name: {
                    value: ''
                }
            }
        }
    }

    componentDidMount() {
        this.props.dispatch(getResources('deliveryDelays', this.props.rawToken))
    }

    handleVisibility = () => {
        this.setState(prevState => {
            return {
                show: !prevState.show
            }
        });
        localStorage.setItem('settings-deliveryDelay-show', this.state.show === true ? 'false' : 'true');
    };

    handleDelete = (id) => onDelete('', 'Etes-vous sûr de vouloir supprimer ce délai de livraison ?', () => this.props.dispatch(deleteResource('deliveryDelays', id, this.props.rawToken)))

    handleOpenModal = (id = null) => {
        this.setState( {
            modalIsOpen: true,
        });

        if(id !== null) {
            const deliveryDelay =
                this.props.deliveryDelays.find(deliveryDelay => {
                    return deliveryDelay.id === parseInt(id, 10)
                });

            if(deliveryDelay !== null)
                this.setState({
                    deliveryDelayUpdated: deliveryDelay,
                    form: {
                        ...this.state.form,
                        'name': {
                            ...this.state.form.name,
                            value: deliveryDelay.name !== null ? deliveryDelay.name : ''
                        },
                    }
                });
        }
        else {
            this.setState({
                deliveryDelayUpdated: null,
                form: {
                    ...this.state.form,
                    'name': {
                        ...this.state.form.name,
                        value: ''
                    },
                }
            })
        }
    };

    handleCloseModal = () => {
        this.setState({modalIsOpen: false});
    };

    changeHandler = (e, newName, newValue) => {
        const name  = newName !== undefined ? newName : e.target.name;
        let value   = newValue !== undefined ? newValue : e.target.value;
        this.setState({
            form: {
                ...this.state.form,
                [name]: {
                    ...this.state.form[name],
                    value
                }
            }
        })
    };

    handleSubmit = e => {
        e.preventDefault();

        let body = {};
        Object.keys(this.state.form).map((input) =>
            body[input] = this.state.form[input].value
        );

        if(this.state.deliveryDelayUpdated === null) {
           this.props.dispatch(createResource('deliveryDelays', body, this.props.rawToken))
                .then(() => {
                    this.setState( {
                        modalIsOpen: false
                    })
                })
        }
        else {
            this.props.dispatch(updateResource('deliveryDelays', this.state.deliveryDelayUpdated.id, body, this.props.rawToken))
                .then(() => {
                    this.setState({
                        modalIsOpen: false
                    })
                })
        }
    };

    render() {
        const columns = [
            {
                Header: 'Mode de règlement',
                accessor: 'name',
                width: localStorage.getItem('settings-deliveryDelay-name') !== null ? localStorage.getItem('settings-deliveryDelay-name') : 1650,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                width: 100,
                resizable: false,
                Cell: ({ row }) => {
                    return (
                        <div className={"btn-flex"}>
                            <div className="btn-circle btn-warning" title={"Modifier"} onClick={() => this.handleOpenModal(row._original.id)}>
                                <i className="fa fa-edit"/>
                            </div>
                            <div className="btn-circle btn-danger" title={"Supprimer"} onClick={() => this.handleDelete(row._original.id)}>
                                <i className="fa fa-trash"/>
                            </div>
                        </div>
                    )
                }
            }
        ];

        return (
            <div className={"block"}>
                <HeaderBlock title={"Delais de livraison"} handleVisibility={this.handleVisibility} show={this.state.show} handleOpenModal={this.handleOpenModal} addButtonActive={true} addButtonTitle={"Ajouter un delai de livraison"}/>

                <ReactTable
                    style={this.state.show === true ? {display: 'block'} : {display: 'none'}}
                    data={this.props.deliveryDelays}
                    columns={columns}
                    keyField={"id"}
                    showPagination={true}
                    showPageJump={true}
                    sortable={true}
                    multiSort={true}
                    defaultPageSize={5}
                    previousText={"Précédent"}
                    nextText={"Suivant"}
                    ofText={"sur"}
                    rowsText={"delais de livraison"}
                    loadingText={"Chargement ..."}
                    noDataText={"Aucun delais de livraison disponibles"}
                    onResizedChange={(newResized, event) => {
                        newResized.map((newResize) =>
                            localStorage.setItem('settings-deliveryDelay-' + newResize.id, newResize.value)
                        )
                    }}
                />

                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={() => this.handleCloseModal()}
                    contentLabel="Example Modal"
                    className="Modal"
                    overlayClassName="Overlay"
                    shouldCloseOnOverlayClick={true}
                >
                    <div className="modal-header">
                        <div className="modal-title">{this.state.deliveryDelayUpdated === null ? 'Créer' : 'Modifier'} un delai de livraison</div>

                        <div className="btn-circle btn-secondary modal-close" title={"Fermer"} onClick={() => this.handleCloseModal()}>
                            <i className="fas fa-times"/>
                        </div>
                    </div>

                    <div className="modal-body">
                        <form onSubmit={this.handleSubmit} className={"form-block"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <input type={"name"} name={"name"} placeholder={"Intitulé"} value={this.state.form.name.value} onChange={this.changeHandler}/>
                                </div>
                            </div>
                            <button title={this.state.deliveryDelayUpdated === null ? 'Créer' : 'Modifier'}>{this.state.deliveryDelayUpdated === null ? 'Créer' : 'Modifier'}</button>
                        </form>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = ({apiReducer}) => {
    return {
        rawToken: apiReducer.rawToken,
        deliveryDelays: apiReducer.deliveryDelays
    }
};

export default connect(mapStateToProps)(DeliveryDelay)
