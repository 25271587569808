import React, {Component} from 'react'
import {connect} from "react-redux"
import { withRouter } from "react-router"

import {createResource, getNewNum, getResource, getResources, updateResource} from "../../redux/action"
import MyAutosuggest from "../../components/MyAutosuggest"
import Page from "../../components/Page/Page"

class ProductForm extends Component {
    constructor(props) {
        super(props);

        const pathname = window.location.pathname;
        const split = pathname.split('/');

        this.state = {
            id: split[2],
            product: null,
            products: [],
            providers: [],
            productTypes: [],
            form: {
                reference: {
                    value: ''
                },
                code: {
                    value: ''
                },
                designation: {
                    value: ''
                },
                provider: {
                    value: ''
                },
                type: {
                    value: ''
                },
                thickness: {
                    value: ''
                },
                format: {
                    value: ''
                },
                surface: {
                    value: ''
                },
                squarePrice: {
                    value: ''
                },
                mlPrice: {
                    value: ''
                },
                unitPrice: {
                    value: ''
                },
                marginCoefficient: {
                    value: ''
                },
                note: {
                    value :''
                }
            }
        }
    }

    componentDidMount() {
        if(this.state.id !== 'creer') {
            this.props.dispatch(getResource('products', this.state.id, this.props.rawToken))
                .then((product) => {
                    this.setState({
                        product: product,
                    });
                    Object.keys(this.state.form).map((input) => this.productToInput(input));
                });
        }
        else {
            this.props.dispatch(getNewNum('products', this.props.rawToken))
                .then(() => this.setState({
                    form: {
                        ...this.state.form,
                        code: {
                            ...this.state.form.code,
                            value: this.props.newNum
                        }
                    }
                }));
        }

        this.props.dispatch(getResources('providers', this.props.rawToken))
            .then(() =>
                this.setState({
                    providers: this.props.providers
                })
        );

        this.props.dispatch(getResources('productTypes', this.props.rawToken))
            .then(() =>
                this.setState({
                    productTypes: this.props.productTypes
                })
            );
    }

    productToInput = (input) => {
        if(input === 'type') {
            this.setState({
                form: {
                    ...this.state.form,
                    type: {
                        ...this.state.form.type,
                        value: this.state.product.type !== null ? this.state.product.type.name : ''
                    }
                }
            });
        }
        else if(input === 'provider') {
            this.setState({
                form: {
                    ...this.state.form,
                    provider: {
                        ...this.state.form.provider,
                        value: this.state.product.provider !== null ? this.state.product.provider.name : ''
                    }
                }
            });
        }
        else {
            this.setState({
                form: {
                    ...this.state.form,
                    [input]: {
                        ...this.state.form[input],
                        value: this.state.product[input] !== null ? this.state.product[input] : ''
                    },
                }
            });
        }
    };

    changeHandler = (e, newName, newValue) => {
        const name  = newName !== undefined ? newName : e.target.name;
        const value = newValue !== undefined ? newValue : e.target.value;

        this.setState({
            form: {
                ...this.state.form,
                [name]: {
                    ...this.state.form[name],
                    value
                }
            }
        })
    };

    handleSubmit = e => {
        e.preventDefault();

        let body = {};
        Object.keys(this.state.form).map((input) =>
            body[input] = this.state.form[input].value
        );

        body.madeByForm = true;

        const provider = this.state.providers.find(provider => provider.name === this.state.form.provider.value);
        body['provider'] = provider.id;


        if(this.state.form.type.value === "")
            body.type = null;
        else {
            const type = this.state.productTypes.find(type => type.name === this.state.form.type.value);

            if (type === undefined) {
                this.props.dispatch(createResource('productTypes', {name: body.type}, this.props.rawToken)).then((type) => {
                    body.type = type.id;
                    if (this.state.id === 'creer') {
                        this.props.dispatch(createResource('products', body, this.props.rawToken)).then(() => this.props.history.goBack())
                    } else {
                        this.props.dispatch(updateResource('products', this.state.id, body, this.props.rawToken)).then(() => this.props.history.goBack())
                    }
                })
            } else {
                body.type = type.id;
                if (this.state.id === 'creer') {
                    this.props.dispatch(createResource('products', body, this.props.rawToken)).then(() => this.props.history.goBack())
                } else {
                    this.props.dispatch(updateResource('products', this.state.id, body, this.props.rawToken)).then(() => this.props.history.goBack())
                }
            }
        }
    };

    onChange = (event, { newValue }) => {
        this.setState({
            form: {
                ...this.state.form,
                provider: {
                    ...this.state.provider,
                    value: newValue
                }
            }
        });
    };

    render() {
        return (
            <Page title={(this.state.id !== 'creer' ? 'Modification ' : 'Création') + " d\'un produit"}>
                <form onSubmit={this.handleSubmit} className={"form-block"}>
                    <div className={"row"}>
                        <div className={"col-12 col-lg-2"}>
                            <label>Référence</label>
                            <input type={"text"} name={"reference"} placeholder={"Référence"} maxLength={17} value={this.state.form.reference.value} onChange={this.changeHandler}/>
                        </div>
                        <div className={"col-12 col-lg-2"}>
                            <label>Code</label>
                            <input type={"text"} name={"code"} placeholder={"Code"} maxLength={17} value={this.state.form.code.value} onChange={this.changeHandler}/>
                        </div>
                        <div className={"col-12 col-lg-4"}>
                            <label>Désignation</label>
                            <input type={"text"} name={"designation"} placeholder={"Désignation"} className={"designation"} value={this.state.form.designation.value} onChange={this.changeHandler}/>
                        </div>
                        <div className={"col-12 col-lg-2"}>
                            <label>Fournisseur</label>
                            <MyAutosuggest
                                placeholder={"Fournisseur"}
                                name={"provider"}
                                required={true}
                                items={this.state.providers}
                                onChange={this.changeHandler}
                                value={this.state.form.provider.value}
                            />
                        </div>
                        <div className={"col-12 col-lg-2"}>
                            <label>Type de produits</label>
                            <MyAutosuggest
                                placeholder={"Type de produits"}
                                name={"type"}
                                required={true}
                                items={this.state.productTypes}
                                onChange={this.changeHandler}
                                value={this.state.form.type.value}
                            />
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-12 col-lg-4"}>
                            <label>Epaisseur</label>
                            <input type={"text"} name={"thickness"} placeholder={"Epaisseur"} value={this.state.form.thickness.value}  onChange={this.changeHandler}/>
                        </div>
                        <div className={"col-12 col-lg-4"}>
                            <label>Format</label>
                            <input type={"text"} name={"format"} placeholder={"Format"} value={this.state.form.format.value} onChange={this.changeHandler}/>
                        </div>
                        <div className={"col-12 col-lg-4"}>
                            <label>Surface totale</label>
                            <input type={"text"} name={"surface"} placeholder={"Surface totale"} value={this.state.form.surface.value} onChange={this.changeHandler}/>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-12 col-lg-3"}>
                            <label>Prix unitaire</label>
                            <input type={"text"} name={"unitPrice"} placeholder={"Prix Unitaire"} value={this.state.form.unitPrice.value} onChange={this.changeHandler}/>
                        </div>
                        <div className={"col-12 col-lg-3"}>
                            <label>Prix au m²</label>
                            <input type={"text"} name={"squarePrice"} placeholder={"Prix au m²"} value={this.state.form.squarePrice.value} onChange={this.changeHandler}/>
                        </div>
                        <div className={"col-12 col-lg-3"}>
                            <label>Prix au mL</label>
                            <input type={"text"} name={"mlPrice"} placeholder={"Prix au mL"} value={this.state.form.mlPrice.value} onChange={this.changeHandler}/>
                        </div>
                        <div className={"col-12 col-lg-3"}>
                            <label>Coefficient de marge</label>
                            <input type={"text"} name={"marginCoefficient"} placeholder={"Coefficient de marge"} value={this.state.form.marginCoefficient.value} onChange={this.changeHandler}/>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-12"}>
                            <label>Note</label>
                            <textarea name={"note"} placeholder={"Note concernant ce produit"} value={this.state.form.note.value} onChange={this.changeHandler}/>
                        </div>
                    </div>

                    {this.props.id !== undefined || (this.state.id !== null && this.state.id !== 'creer') ? <button title={"Modifier"}>Modifier</button> :  <button title={"Créer"}>Créer</button>}
                </form>
            </Page>
        )
    }
}

const mapStateToProps = ({apiReducer}) => {
    return {
        rawToken: apiReducer.rawToken,
        newNum: apiReducer.newNum,
        products: apiReducer.products,
        providers: apiReducer.providers,
        productTypes: apiReducer.productTypes
    }
};

export default withRouter(connect(mapStateToProps)(ProductForm))
