import React, {Component} from 'react'
import {connect} from "react-redux"
import { withRouter } from "react-router"
import { SketchPicker } from 'react-color'

import {createResource, getResource, updateResource} from "../../redux/action"
import Page from "../../components/Page/Page"


class ColorForm extends Component {
    constructor(props) {
        super(props);

        const pathname = window.location.pathname;
        const split = pathname.split('/');

        this.state = {
            id: split[2],
            color: null,
            form: {
                name: {
                    value: ''
                },
                hexa: {
                    value: ''
                },
            },
        }
    }

    componentDidMount() {
        if(this.state.id !== 'creer') {
            this.props.dispatch(getResource('colors', this.state.id, this.props.rawToken))
                .then((color) => {
                    this.setState({
                        color: color,
                    });
                    Object.keys(this.state.form).map((input) => this.resourceToInput(input));
                });
        }
    }

    resourceToInput = (input) => {
       this.setState({
            form: {
                ...this.state.form,
                [input]: {
                    ...this.state.form[input],
                    value: this.state.color[input] !== null ? this.state.color[input] : ''
                },
            }
        });
    };

    changeHandler = (e, newName, newValue) => {
        const name  = newName !== undefined ? newName : e.target.name;
        let value   = newValue !== undefined ? newValue : e.target.value;

        let split = name.split('.');
        if(split.length > 1) {
            this.setState({
                form: {
                    ...this.state.form,
                    [split[0]]: {
                        ...this.state.form[split[0]],
                        [split[1]] : {
                            ...this.state.form[split[0]][split[1]],
                            value
                        }
                    }
                }
            })
        }
        else {
            this.setState({
                form: {
                    ...this.state.form,
                    [name]: {
                        ...this.state.form[name],
                        value
                    }
                }
            })
        }
    };

    handleSubmit = e => {
        e.preventDefault();

        let body = {};
        Object.keys(this.state.form).map((input) => body[input] = this.state.form[input].value);

        if(this.state.id === 'creer') {
            this.props.dispatch(createResource('colors', body, this.props.rawToken)).then(() => this.props.history.goBack());
        }
        else {
            this.props.dispatch(updateResource('colors', this.state.id, body, this.props.rawToken)).then(() => this.props.history.goBack())
        }
    };

    handleChange = (color) => {
        this.setState({
            form: {
                ...this.state.form,
                hexa: {
                    ...this.state.form.hexa,
                    value: color.hex
                }

            }
        })
    };

    render() {
        return (
            <Page title={(this.state.id !== 'creer' ? 'Modification ' : 'Création') + " d'une couleur"}>
                <form onSubmit={this.handleSubmit} className={"form-block"}>
                    <div className={"row"}>
                        <div className={"col-12 col-lg-4"}>
                            <label>Intitulé</label>
                            <input type={"text"} name={"name"} placeholder={"Désignation de la couleur"} value={this.state.form.name.value} onChange={this.changeHandler}/>
                        </div>
                        <div className={"col-12 col-lg-8"}>
                            <label>Rendu</label>
                            <div className={"btn-circle"} style={{backgroundColor: this.state.form.hexa.value}}/>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-12"}>
                            <label>Sélectionnez la couleur</label>
                            <SketchPicker onChange={this.handleChange} color={this.state.form.hexa.value !== null ? this.state.form.hexa.value : ''}/>
                        </div>
                    </div>

                    {this.props.id !== undefined || (this.state.id !== null && this.state.id !== 'creer') ? <button title={"Modifier"}>Modifier</button> :  <button title={"Créer"}>Créer</button>}
                </form>
            </Page>
        )
    }
}

const mapStateToProps = ({apiReducer}) => {
    return {
        rawToken: apiReducer.rawToken
    }
};

export default withRouter(connect(mapStateToProps)(ColorForm))
