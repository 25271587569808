import {getMonthByYear} from "../../utils/Date";
import {getAdvance, getAmountTTC, getPriceToPaid, getTotalPrice, getTva} from "../../utils/Amounts";

export const getAmount = (bills, month, year, func) => {
    let amount = 0;
    bills.forEach(bill => {
        if(getMonthByYear(bill.created, month, year)) {
            if(func === getAdvance)
                amount += func(bill.advance);
            else if(func === getPriceToPaid) {
                if(bill.paid !== null)
                    amount += 0;
                else
                    amount += func(bill.totalPrice, bill.advance, bill.discountEuros, bill.paid);
            }
            else
                amount += func(bill.totalPrice, bill.discountEuros);
        }
    });
    return amount;
};

export const getAmounts = (table, bills, year) =>
    [...table.map(row => {
        const month = row[0];
        return row.map((col, j) => {
            switch (j) {
                case 1:
                    return getAmount(bills, month, year, getTotalPrice);
                case 2:
                    return getAmount(bills, month, year, getTva);
                case 3:
                    return getAmount(bills, month, year, getAmountTTC);
                case 4:
                    return getAmount(bills, month, year, getAdvance);
                case 5:
                    return getAmount(bills, month, year, getPriceToPaid);
                default:
                    return col;
            }
        })
    })];

export const getTotals = (table, index) => {
    let amount = 0;
    table.forEach(row => amount += row[index]);
    return amount
};
