import React, {Component} from 'react'
import {connect} from "react-redux"
import {Link} from "react-router-dom"
import {Tab, TabList, TabPanel, Tabs} from "react-tabs"

import "../../css/pages/bills/list.scss"
import {addChildToParent, deleteResource, getResources, specialPut} from "../../redux/action"
import Table from "../../components/Table"
import ColorPickerList from "../../components/ColorPickerList"
import {TextFilter, SelectFilter} from "../../components/List/index"
import {getColumnsWidth, getDefaultFiltered} from "../../utils/List"
import {onDelete} from "../../utils/Alert"
import {isDefined} from "../../utils/Data"
import {formatDate} from "../../utils/Date"
import Page from "../../components/Page/Page"
import {getAdvance, getAmountTTC, getPriceToPaid, getTotalPrice, getTva} from "../../utils/Amounts"

class Bills extends Component {
    constructor(props) {
        super(props);

        this.frenchName = 'factures';
        this.localName  = 'bills';
        this.apiName    = 'bills';

        this.localName2 = "billsModels"
        this.apiName2   = "billsModels"

        this.localNames = ['number', 'quotation.number', 'project.number', 'customer.code', 'customer.name', 'name', 'created', 'sent', 'htAmount', 'tvaAmount', 'ttcAmount', 'advance', 'outstandingBalance', 'paid'];
        this.localNames = this.localNames.map(name => this.localName + '-' + name);

        const optionsSelectPaid = [
            {
                value:'all',
                text: 'Toutes',
            },
            {
                value:'paid',
                text: 'Payées',
            },
            {
                value:'unpaid',
                text: 'Impayées',
            }]

        this.state = {
            tabIndex: isDefined(localStorage.getItem(this.localName + "-tab")) ? parseInt(localStorage.getItem(this.localName + "-tab")) : 0,
            rowOpenColor: null,
            displayColorPicker: false,
            columns: [
                {
                    Header: 'N° Facture',
                    accessor: 'number',
                    width: getColumnsWidth(this.localNames[0], 95),
                },
                {
                    Header: 'N° Devis',
                    accessor: 'quotation.number',
                    width: getColumnsWidth(this.localNames[1], 70),
                    Cell: ({row}) => isDefined(row._original.quotation) ? <Link to={"/devis/" + row._original.quotation.id + "/modifier"}>{row._original.quotation.number}</Link> : ''
                },
                {
                    Header: 'Dossier',
                    accessor: 'project.number',
                    width: getColumnsWidth(this.localNames[2], 70),
                    Cell: ({row}) => row._original.project !== null ? <Link to={"/projets/" + row._original.project.id}>{row._original.project.number}</Link> : ''
                },
                {
                    Header: 'N°Client',
                    accessor: 'customer.code',
                    width: getColumnsWidth(this.localNames[3], 60),
                    Cell: ({row}) => row._original.project !== null ? row._original.project.customer !== null ? <Link to={"/clients/" + row._original.project.customer.id + "/modifier"}>{row._original.project.customer.code}</Link> : '' : ''
                },
                {
                    Header: 'Client',
                    accessor: 'customer.name',
                    width: getColumnsWidth(this.localNames[4], 250),
                    Cell: ({row}) => row._original.project !== null ? row._original.project.customer !== null ? <Link to={"/clients/" + row._original.project.customer.id + "/modifier"}>
                        {row._original.customerName !== null ? row._original.customerName : row._original.project.customer.name}
                    </Link> : '' : ''
                },
                {
                    Header: 'Intitulé',
                    accessor: 'name',
                    width: getColumnsWidth(this.localNames[5], 230),
                },
                {
                    Header: 'Facturé',
                    accessor: 'created',
                    width: getColumnsWidth(this.localNames[6], 95),
                    Cell: ({row}) => formatDate(row._original.created)
                },
                {
                    Header: 'Envoyé',
                    accessor: 'sent',
                    width: getColumnsWidth(this.localNames[7], 95),
                    Cell: ({row}) => formatDate(row._original.sent)
                },
                {
                    Header: 'Montant HT',
                    accessor: 'htAmount',
                    width: getColumnsWidth(this.localNames[8], 100),
                    className: 'text-right',
                    sortable: false,
                    Cell: ({row}) => parseFloat(getTotalPrice(row._original.totalPrice) - row._original.discountEuros).toFixed(2) + ' €'
                },
                {
                    Header: 'TVA',
                    accessor: 'tvaAmount',
                    width: getColumnsWidth(this.localNames[9], 100),
                    className: 'text-right',
                    sortable: false,
                    Cell: ({row}) => getTva(row._original.totalPrice, row._original.discountEuros).toFixed(2) + ' €'
                },
                {
                    Header: 'Montant TTC',
                    accessor: 'ttcAmount',
                    width: getColumnsWidth(this.localNames[10], 100),
                    className: 'text-right',
                    sortable: false,
                    Cell: ({row}) => getAmountTTC(row._original.totalPrice, row._original.discountEuros).toFixed(2) + ' €'
                },
                {
                    Header: 'Acompte',
                    accessor: 'advance',
                    width: getColumnsWidth(this.localNames[11], 100),
                    className: 'text-right',
                    sortable: false,
                    Cell: ({row}) => getAdvance(row._original.advance).toFixed(2) + ' €'
                },
                {
                    Header: 'Solde à régler',
                    accessor: 'outstandingBalance',
                    width: getColumnsWidth(this.localNames[12], 100),
                    className: 'text-right',
                    sortable: false,
                    Cell: ({row}) => getPriceToPaid(row._original.totalPrice, row._original.advance, row._original.discountEuros, row._original.paid).toFixed(2) + ' €'
                },
                {
                    Header: 'Payée',
                    accessor: 'paid',
                    width: getColumnsWidth(this.localNames[13], 50),
                    sortable: false,
                    Filter: ({ filter, onChange }) => <SelectFilter filter={filter} localName={this.localNames[13]} onChange={onChange} options={optionsSelectPaid} showDefault={false}/>,
                    Cell: ({row}) => row._original.paid !== null ? 'Oui' : 'Non'
                },
                {
                    Header: 'Actions',
                    accessor: 'actions',
                    sortable: false,
                    resizable: false,
                    width: 270,
                    Cell: ({ row }) => {
                        return (
                            <div className={"btn-flex"}>
                                <div className="btn-circle btn-color" title={"Mettre en couleur"} onClick={() => this.handleColor(row._original.id)}>
                                    <i className="fa fa-tint"/>
                                </div>
                                <div className="btn-circle btn-success" title={"Marquer comme payé"}
                                     onClick={() => this.handlePaid(row._original.id)}>
                                    <i className="fas fa-euro-sign"/>
                                </div>
                                <Link to={"/factures/" + row._original.id + "/notes"}>
                                    <div className="btn-circle btn-primary" title={"Voir les notes"}>
                                        <i className="fa fa-book"/>
                                    </div>
                                </Link>
                                <Link to={"/factures/" + row._original.id + "/dupliquer"}>
                                    <div className="btn-circle btn-secondary" title={"Dupliquer"}>
                                        <i className="fas fa-copy"/>
                                    </div>
                                </Link>
                                <Link to={"/factures/" + row._original.id + "/modifier"}>
                                    <div className="btn-circle btn-warning" title={"Modifier"}>
                                        <i className="fa fa-edit"/>
                                    </div>
                                </Link>
                                <div className="btn-circle btn-danger" title={"Supprimer"}
                                     onClick={() => this.handleDelete(row._original.id)}>
                                    <i className="fa fa-trash"/>
                                </div>
                            </div>
                        )
                    }
                },
            ]
        }
    }

    componentDidMount() {
        this.props.dispatch(getResources('colors', this.props.rawToken));
        this.state.columns.forEach((column, i) => {
            if (i < this.state.columns.length -2)
                column.Filter = ({filter, onChange}) => <TextFilter localName={this.localNames[i]} filter={filter} onChange={onChange}/>
        })
    }

    handlePaid = id => this.props.dispatch(specialPut(id, this.props.rawToken, 'bills', 'paid'));

    handleDelete = id => onDelete('', 'Etes-vous sûr de vouloir supprimer cette facture ?', () => this.props.dispatch(deleteResource('bills', id, this.props.rawToken)));

    selectTab = index => {
        this.setState({ tabIndex : index });
        localStorage.setItem(this.localName + '-tab', index);
    };

    handleColor = (id = null) => {
        this.setState(state => {
            return {
                rowOpenColor: id,
                displayColorPicker: !state.displayColorPicker
            }
        })
    };

    selectColor = (color = null) => {
        const colorApi = this.props.colors.find(item => item.hexa === color.hex);
        if(typeof colorApi !== "undefined")
            this.props.dispatch(addChildToParent(this.state.rowOpenColor, colorApi.id, 'bills', 'colors', this.props.rawToken))
        this.handleColor()
    };

    buttonsRight = () => (
        <>
            <Link to={"/factures/imprimer"}>
                <button className={"btn-circle btn-secondary"} title={"Imprimer"}>
                    <i className="fas fa-print"/>
                </button>
            </Link>
            <Link to={"/factures/creer"}>
                <button className={"btn-circle add-resource"} title={"Ajouter"}>
                    <i className={"fas fa-plus"} />
                </button>
            </Link>
        </>
    )

    render() {
        return (
            <Page siteTitle={"Factures"} title={'Gestion des factures'} headerButtons={this.buttonsRight()} className={"bills"}>
                <ColorPickerList
                    display={this.state.displayColorPicker}
                    handleClose={() => this.handleColor()}
                    colors={this.props.colors.map((color) => color.hexa)}
                    handleChange={(color) => this.selectColor(color)}
                />

                <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.selectTab(tabIndex)}>
                    <TabList>
                        <Tab>Factures</Tab>
                        <Tab>Modèles</Tab>
                    </TabList>

                    <TabPanel>
                        <Table
                            name={this.localName + "-list"}
                            data={this.props.bills}
                            columns={this.state.columns}
                            frenchName={this.frenchName}
                            localName={this.localName}
                            apiName={this.apiName}
                            defaultFiltered={getDefaultFiltered(this.localName, this.state.columns)}
                            defaultSorted={[
                                {
                                    id: "number",
                                    desc: true
                                }
                            ]}
                        />
                    </TabPanel>
                    <TabPanel>
                        <Table
                            name={"billsModel-list"}
                            data={this.props.billsModels}
                            columns={this.state.columns}
                            frenchName={"modèles"}
                            localName={this.localName2}
                            apiName={this.apiName2}
                            defaultFiltered={getDefaultFiltered(this.localName2, this.state.columns)}
                            defaultSorted={[
                                {
                                    id: "number",
                                    desc: true
                                }
                            ]}
                        />
                    </TabPanel>
                </Tabs>
            </Page>
        )
    }
}

const mapStateToProps = ({apiReducer}) => {
    return {
        rawToken: apiReducer.rawToken,
        bills: apiReducer.bills,
        billsModels: apiReducer.billsModels,
        colors: apiReducer.colors,
    }
};

export default connect(mapStateToProps)(Bills)
