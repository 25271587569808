import React from "react"
import {Link} from "react-router-dom"

import Page from "../../components/Page/Page"
import RepertoireList from "./RepertoireList";

const Repertoire = () => {
    const buttonsRight = () => (
        <Link to={"/repertoire/creer"}>
            <button className={"btn-circle add-resource"} title={"Ajouter"}>
                <i className={"fas fa-plus"} />
            </button>
        </Link>
    )

    return (
        <Page title={"Répertoire"} className={"repertoire"} headerButtons={buttonsRight()}>
            <RepertoireList/>
        </Page>
    )
}

export default Repertoire
