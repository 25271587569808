import {
    ADD_ROW,
    CHANGE_CITY,
    CHANGE_ROW,
    DELETE_ROW,
    REORDER,
    RESET_WRITE_QUOTATION_FORM
} from "./QuotationWriteAction";

const initialState = {
    rowId: 0,
    form: {
        rows: [],
        city: 'Reims',
    }
};

const quotationWriteReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ROW:
            return {
                ...state,
                rowId: state.rowId + 1,
                form: {
                    ...state.form,
                    rows: state.form.rows.concat(
                        {
                            id: state.rowId,
                            form: action.form,
                            data: {
                                position: state.rowId,
                                code: '',
                                text: '',
                                quantity: '0',
                                unitPrice: '0',
                                sellingPrice: 0,
                            }
                        }
                    ),
                },
            };
        case DELETE_ROW:
            return {
                ...state,
                form: {
                    ...state.form,
                    rows: state.form.rows.filter(row => row.id !== action.id)
                }
            };
        case CHANGE_ROW:
            return {
                ...state,
                form: {
                    ...state.form,
                    rows: state.form.rows.map(row => {
                        if (row.id === action.id)
                            return {
                                ...row,
                                data: {
                                    ...row.data,
                                    [action.name]: action.value,
                                }
                            };
                        else
                            return row;
                    })
                }
            };
        case CHANGE_CITY:
            return {
                ...state,
                form: {
                    ...state.form,
                    city: action.value
                }
            };
        case RESET_WRITE_QUOTATION_FORM:
            return initialState;
        case REORDER:
            /*console.log(state.form.rows.map(row => row.data.position))
            console.log(state.form.rows.sort((row, row2) => row.data.position - row2.data.position))*/
            return {
                ...state,
                form: {
                    ...state.form,
                    rows: [...state.form.rows.sort((row, row2) => row.data.position - row2.data.position)]
                }
            };
        default:
            return state;
    }
};

export default quotationWriteReducer;
