import React, {useState} from 'react';
import {Link} from "react-router-dom";
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import PropTypes from 'prop-types';

import HeaderBlock from "../../../components/HeaderBlock";

const Accounting = ({project}) => {
    const localName     = 'fiche-project-accounting-'
    const localKeyShow  = localName + 'key'

    const [show, setShow] = useState(localStorage.getItem(localKeyShow) !== null ? JSON.parse(localStorage.getItem(localKeyShow)) : true)

    const handleVisibility = () => {
        setShow(prevShow => !prevShow)
        localStorage.setItem(localKeyShow, show === true ? 'false' : 'true');
    };

    const columns = [
        {
            Header: 'Devis',
            accessor: 'quotations',
            width: 'auto',
            resizable: false,
            className: 'quotations',
            Cell: ({row}) => {
                return (
                    <ul>
                        {
                            row._original.quotations.length > 0 ?
                                row._original.quotations.map((quotation, i) => (
                                    quotation.model === false ?
                                        <li key={i}>
                                            <Link to={"/devis/" + quotation.id + "/modifier"}>
                                                {quotation.number + " - " + quotation.name}
                                            </Link>
                                        </li>
                                        : ''
                                ))
                                : 'Aucun devis pour ce projet'
                        }
                    </ul>
                )
            },
        },
        {
            Header: 'Factures',
            accessor: 'bills',
            width: 'auto',
            resizable: false,
            className: 'bills',
            Cell: ({row}) => (
                <ul>
                    {
                        row._original.bills.length > 0 ?
                            row._original.bills.map((bill, i) => (
                                bill.model === false ?
                                    <li key={i}>
                                        <Link to={"/factures/" + bill.id + "/modifier"}>
                                            {bill.number + " - " + bill.name}
                                        </Link>
                                    </li>
                                    : ''
                            ))
                            : 'Aucune facture pour ce projet'
                    }
                </ul>
            )},
    ];

    return (
        <div className={"block"}>
            <HeaderBlock title={"Comptabilité"} handleVisibility={handleVisibility} show={show}/>

            <ReactTable
                style={show === true ? {display: 'block'} : {display: 'none'}}
                data={[project]}
                columns={columns}
                keyField={"id"}
                showPagination={false}
                showPageJump={false}
                sortable={false}
                multiSort={false}
                defaultPageSize={1}
                onResizedChange={(newResized, event) => {
                    newResized.map((newResize) =>
                        localStorage.setItem(localName + newResize.id, newResize.value)
                    )
                }}
            />
        </div>
    )
}

Accounting.propTypes = {
    project: PropTypes.object
};

export default Accounting
