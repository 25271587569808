import React from 'react';

const SavedButtons = ({id, data, action, handleSubmit}) => {
    const canSave = () => {
        /*if(data.number.value !== '' && data.project.value !== '' && data.customer.value !== '') {*/
            if(id === 'creer')
                return true;
            else if(action === 'modifier' || action === 'dupliquer' || action === 'devis')
                return data.projectId.value !== null;
            else
                return false
        /*}
        else
            return false*/
    };

    const getMessage = () => {
        if(data.number.value === '')
            return "Veuillez entrer un n° de devis ou de facture"
        else if(data.project.value === '')
            return "Veuillez choisir un projet"
        else if(data.customer.value === '')
            return "Veuillez choisir un client"
    }

    return (
        <div className={"saved-buttons"}>
            {canSave() === true ?
                <>
                    {id !== 'creer' ? action === 'dupliquer' ? <button title={"Modifier"}>Dupliquer</button> : <button title={"Modifier"}>Modifier</button> : <button title={"Créer"}>Créer</button>}
                    <button title={"Sauvegarder"} onClick={(e) => handleSubmit(e,  false, false)}>Sauvegarder</button>
                    <button title={"Rédiger"} onClick={(e) => handleSubmit(e,  true)}>Rédiger</button>
                </>
                :
                <>
                    <button title={getMessage()} disabled={true}>Modifier</button>
                    <button title={getMessage()} disabled={true}>Sauvegarder</button>
                    <button title={getMessage()} disabled={true}>Rédiger</button>
                </>}
        </div>
    )
}

export default SavedButtons
