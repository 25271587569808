import React, {useRef} from "react"
import ReactToPrint from 'react-to-print'

import OrdersPrint from "../print/OrdersPrint"

const Buttons = () => {
    const componentRef = useRef()

    return (
        <>
            <button className={"submitButton"} title={"Sauvegarder"}>Sauvegarder</button>
            <ReactToPrint
                trigger={() => <button className={"submitButton"} title={"Générer un PDF"} type={"button"}>Générer un PDF</button>}
                content={() => componentRef.current}
            />
            <OrdersPrint ref={componentRef}/>
        </>
    )
}

export default Buttons
