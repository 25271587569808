import React from 'react'
import 'react-table/react-table.css'

import '../css/components/block.scss'

const HeaderBlock = ({handleVisibility, handleOpenModal, title, show, addButtonTitle, addButtonActive}) => (
    <div className={"block-title"}>
        <span>{title}</span>
        <div className="btn-circle btn-primary btn-hide" title={show === true ? 'Cacher' : 'Voir'}  onClick={handleVisibility}>
            <i className={show === true ? 'fa fa-eye-slash' : 'fa fa-eye'}/>
        </div>

        {addButtonActive === true ?
            <button className={"btn-circle btn-success block-add"} title={addButtonTitle} onClick={() => handleOpenModal()}>
            <i className={"fas fa-plus"} />
        </button>
            : ''}
    </div>
)

export default HeaderBlock
