import React from "react";
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';

import "../../../css/pages/accounting/buttons.scss"

const GoTo = props => (
    props.disabled ?
        <div className="btn-circle btn-primary btn-goto disabled" title={props.title}>
            <i className={"fa fa-external-link-alt"}/>
        </div>
    :
        <Link to={props.link} target="_blank">
            <div className="btn-circle btn-primary btn-goto" title={props.title}>
                <i className={"fa fa-external-link-alt"}/>
            </div>
        </Link>
);

GoTo.propTypes = {
    disabled: PropTypes.bool,
    link: PropTypes.string,
    title: PropTypes.string,
};

export default GoTo
