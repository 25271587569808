import React, {Component} from 'react';
import {connect} from "react-redux";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {changeRow, deleteRow} from "../../../redux/QuotationWriteAction";
import MyAutosuggest from "../../../components/MyAutosuggest";
import {deleteResource, getResource, getResources} from "../../../redux/action";
import MyModal from "../../../components/MyModal";
import ArticlesList from "../../articles/ArticlesList";
import {onDelete} from "../../../utils/Alert";

class WriteRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: false,
        }
    }

    componentDidMount() {
        this.props.dispatch(getResources('articles', this.props.rawToken));
        this.resourceToInput();
    }

    resourceToInput = () => {
        const article = this.props.article;
        if(article !== null)
            Object.keys(article).map((input) => {
                if(input === 'unitPrice')
                    this.changeRow(null, input, article[input] !== null ? article[input] : '')
                else
                    this.itemToInput(input, article[input] !== null ? article[input] : '')
            });
    };

    itemToInput = (input, item) => {
        this.props.dispatch(changeRow(null, this.props.id, input, item))
    };

    changeRow = (e, newName, newValue) => {
        const name  = newName !== undefined ? newName : e.target.name;
        let value   = newValue !== undefined ? newValue : e.target.value;

        if (e !== null)
            e.persist();

        this.props.dispatch(changeRow(e, this.props.id, name, value)).then(() => {
            const row           = this.props.data.rows.find(row => row.id === this.props.id);
            const data          = row.data;
            const quantity      = parseFloat(data.quantity).toFixed(2) || 0;
            const unitPrice     = parseFloat(data.unitPrice).toFixed(2) || 0;
            let sellingPrice    = quantity * unitPrice;
            sellingPrice        = parseFloat(sellingPrice).toFixed(2) || 0;

            this.props.dispatch(changeRow(e, this.props.id, 'sellingPrice', sellingPrice))
        })
    };

    onDelete = () => {
        onDelete('', 'Etes-vous sûr de vouloir supprimer cette ligne ?', () => {
            this.props.dispatch(deleteRow(this.props.id));
            if(this.props.article !== null)
                this.props.dispatch(deleteResource('articles', this.props.article.id, this.props.rawToken))
        })
    };

    changeCode = (e, newName, newValue) => {
        const article = this.props.articles.find(article => article.code === newValue);
        this.changeWithArticle(article);
    };

    changeWithArticle = article => {
        if(article !== undefined && article !== null) {
            this.itemToInput('code', article.code !== null ? article.code : '');
            this.itemToInput('text', article.text !== null ? article.text : '');
            this.itemToInput('quantity', article.quantity !== null ? article.quantity : '');
            this.changeRow(null, 'unitPrice', article.unitPrice !== null ? parseFloat(article.unitPrice).toFixed(2) : '')
        }
    };

    handleOpenCloseModal = () => {
        this.setState(prevState => {
            return {
                modalIsOpen: !prevState.modalIsOpen
            }
        });
    };

    handleAdd = (id) => {
        this.props.dispatch(getResource('articles', id, this.props.rawToken)).then(data => this.changeWithArticle(data));
        this.handleOpenCloseModal();
    };

    onDrag = (up = true) => this.props.onDrag(this.props.id, up)

    render() {
        const row           = this.props.data.rows.find(row => row.id === this.props.id);
        const articles      = this.props.articles.filter(article => article.madeByForm === true);
        const articleCodes  = articles.map(article => article.code);

        return (
                <tr className={"article"}>
                    <td className={"code"}>
                        <MyModal
                            isOpen={this.state.modalIsOpen}
                            handleClose={() => this.handleOpenCloseModal()}
                            title={"Catalogue des articles"}
                            content={<ArticlesList addButton={true} handleAdd={id => this.handleAdd(id)}/>}
                            className={"products-modal"}
                        />
                        <div className={"btn-circle btn-secondary btn-drag drag-up"} title={"Déplacer vers le haut"} onClick={() => this.onDrag()}>
                            <i className="fas fa-arrow-up"/>
                        </div>
                        <div className={"btn-circle btn-secondary btn-drag drag-down"} title={"Déplacer vers le bas"} onClick={() => this.onDrag(false)}>
                            <i className="fas fa-arrow-down"/>
                        </div>
                        <div className={"btn-circle btn-products btn-warning"} title={"Ouvrir le catalogue"} onClick={this.handleOpenCloseModal}>
                            <i className="fas fa-book"/>
                        </div>
                        <MyAutosuggest
                            searchWithoutName={true}
                            items={articleCodes}
                            name={"code"}
                            placeholder={"Code"}
                            value={row.data.code}
                            onChange={this.changeCode}/>
                    </td>
                    <td>
                        {<CKEditor
                            editor={ ClassicEditor }
                            data={row.data.text}
                            onInit={editor => {
                                editor.setData(row.data.text)
                            }}
                            onChange={( event, editor ) => {
                                const data = editor.getData();
                                this.changeRow(null, 'text', data);
                            }}
                            onFocus={( event, editor ) => {}}
                        />}
                    </td>
                    <td><input type={"text"} name={"quantity"} className={"little-cell"} value={row.data.quantity} onChange={this.changeRow} /></td>
                    <td><input type={"text"} name={"unitPrice"} className={"little-cell"} value={row.data.unitPrice} onChange={this.changeRow} /></td>
                    <td><input type={"number"} name={"sellingPrice"} className={"little-cell"} value={row.data.sellingPrice} onChange={this.changeRow} disabled={true}/></td>
                    <td className={"actions"}>
                        <div className={"deleteContact btn-circle btn-danger"} title={"Supprimer cette ligne"} onClick={this.onDelete}>
                            <i className="fas fa-trash-alt"/>
                        </div>
                    </td>
                </tr>
        )
    }
}

const mapStateToProps = ({apiReducer, quotationWriteReducer}) => {
    return {
        rawToken: apiReducer.rawToken,
        data: quotationWriteReducer.form,
        articles: apiReducer.articles,
        item: apiReducer.item,
    }
};

export default connect(mapStateToProps)(WriteRow)
