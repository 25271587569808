import React from 'react'
import {useSelector} from "react-redux"
import {withRouter} from "react-router"

import MenuItem from "./MenuItem"
import {checkPermissions} from "../../security/Permissions"

const SubMenu = ({menuItems, opened, mobile, onOpenedSubmenu}) => {
    const {user} = useSelector(({apiReducer}) => apiReducer)
    return (
        <ul className={"sr-menu-submenu-content " + (opened && mobile ? "active" : "")}  onBlur={() => onOpenedSubmenu} onFocus={() => onOpenedSubmenu} tabIndex="0">
            {menuItems.map((item, i) =>
                checkPermissions(item.link, user) ? <MenuItem key={i} mobile={mobile} {...item}/> : ''
            )}
        </ul>
    )
}

export default withRouter(SubMenu)
