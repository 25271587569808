import React, {Component} from 'react';
import {connect} from "react-redux";

import {changeProduct, changeTotalGroupProducts} from "../../../redux/QuotationFormAction";
import ProductRow from "./ProductRow";
import {renderIfDefinedParams} from "../../../utils/Data";

class TechnicalCostsForm extends Component {
    onDelete = () => this.props.onDelete();

    getData = () => this.props.form.products.value.find(product =>  product.id === this.props.id);

    changeHandler = (e, newName, newValue) => {
        newValue = newName === 'type' ? newValue.id : newValue;
        this.props.dispatch(changeProduct(e, this.props.id, newName, newValue)).then(() => this.getTotals().then(this.props.dispatch(changeTotalGroupProducts(this.props.idGroup))));
    };

    getTotals = async () => {
        const data          = this.getData();
        const hours         = parseFloat(data.hours) || 0;
        const minutes       = parseFloat(data.minutes) || 0;
        const hourlyRate    = parseFloat(data.hourlyRate) || 0;
        const sellingPrice  = parseFloat((hours * hourlyRate) + ((minutes / 60) * hourlyRate)).toFixed(2) || 0;

        return Promise.all([
            this.props.dispatch(changeProduct(null, this.props.id, 'sellingPrice', sellingPrice)).then(() => this.props.changeTotalPrice()),
        ])
    };

    inputs = () => {
        const data = this.getData();
        return (
            <>
                <td className={"cell-long"}>
                    <input
                        type={"text"}
                        name={"designation"}
                        placeholder={"Frais techniques"}
                        value={renderIfDefinedParams(data, "designation")}
                        onChange={this.changeHandler}/>
                </td>
                <td>
                    <input
                        type={"text"}
                        name={"hours"}
                        placeholder={"Heures"}
                        value={renderIfDefinedParams(data, "hours")}
                        onChange={this.changeHandler}/>
                </td>
                <td>
                    <input
                        type={"text"}
                        name={"minutes"}
                        placeholder={"Minutes"}
                        value={renderIfDefinedParams(data, "minutes")}
                        onChange={this.changeHandler}/>
                </td>
                <td>
                    <input
                        type={"text"}
                        name={"hourlyRate"}
                        placeholder={"Tarif horaire"}
                        value={renderIfDefinedParams(data, "hourlyRate")}
                        onChange={this.changeHandler}/>
                </td>
            </>
        )
    };

    render() {
        const props = this.props;
        return (
            <ProductRow
                id={props.id}
                idGroup={props.idGroup}
                data={this.getData()}
                product={props.product}
                inputs={this.inputs()}
                changeHandler={this.changeHandler}
                onDelete={() => this.onDelete()}
                getTotals={this.getTotals}/>
        )
    }
}

const mapStateToProps = ({apiReducer,quotationFormReducer}) => {
    return {
        rawToken: apiReducer.rawToken,
        products: apiReducer.products,
        form: quotationFormReducer.form,
    }
};

export default connect(mapStateToProps)(TechnicalCostsForm)

