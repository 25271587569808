import React, {Component} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import {deleteResource} from "../../redux/action";
import Table from "../../components/Table";
import {getColumnsWidth, getDefaultFiltered} from "../../utils/List";
import {TextFilter} from "../../components/List/index";
import {onDelete} from "../../utils/Alert";
import Page from "../../components/Page/Page";

const frenchName = "couleurs";
const localName  = "colors";
const apiName    = 'colors';

let localNames   = ['name', 'color', 'hexa'];
localNames       = localNames.map(name => localName + '-' + name);

class Colors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    Header: 'Désignation',
                    accessor: 'name',
                    width: getColumnsWidth(localNames[0], 1400),
                },
                {
                    Header: 'Couleur',
                    accessor: 'color',
                    filterable: false,
                    sortable: false,
                    width: getColumnsWidth(localNames[1], 90),
                    Cell: ({ row }) => <div className="btn-circle" style={{backgroundColor: row._original.hexa}}/>
                },
                {
                    Header: 'Code hexadécimal',
                    accessor: 'hexa',
                    width: getColumnsWidth(localNames[2], 150),
                },
                {
                    Header: 'Actions',
                    accessor: 'actions',
                    sortable: false,
                    resizable: false,
                    width: 100,
                    Cell: ({ row }) => {
                        return (
                            <div className={"btn-flex"}>
                                <Link to={"/couleurs/" + row._original.id + "/modifier"}>
                                    <div className="btn-circle btn-warning" title={"Modifier"}>
                                        <i className="fa fa-edit"/>
                                    </div>
                                </Link>
                                <div className="btn-circle btn-danger" title={"Supprimer"}
                                     onClick={() => this.handleDelete(row._original.id)}>
                                    <i className="fa fa-trash"/>
                                </div>
                            </div>
                        )
                    }
                },
            ]
        }
    }

    componentDidMount() {
        this.state.columns.forEach((column, i) => {
            if (i < this.state.columns.length)
                column.Filter = ({filter, onChange}) => <TextFilter localName={localNames[i]} filter={filter} onChange={onChange}/>
        })
    }

    handleDelete = id => onDelete('', 'Etes-vous sûr de vouloir supprimer cette couleur ?', () => this.props.dispatch(deleteResource(apiName, id, this.props.rawToken)));

    buttonsRight = () => (
        <Link to={"/couleurs/creer"}>
            <button className={"btn-circle add-resource"} title={"Ajouter"}>
                <i className={"fas fa-plus"} />
            </button>
        </Link>
    )

    render() {
        return (
            <Page siteTitle={"Couleurs"} title={"Gestion des couleurs"} headerButtons={this.buttonsRight()}>
                <Table
                    name={localName + "-list"}
                    data={this.props[apiName]}
                    columns={this.state.columns}
                    frenchName={frenchName}
                    localName={localName}
                    apiName={apiName}
                    defaultFiltered={getDefaultFiltered(localName, this.state.columns)}
                    defaultSorted={[
                        {
                            id: "name",
                            desc: false
                        }
                    ]}
                />
            </Page>
        )
    }
}

const mapStateToProps = ({apiReducer}) => {
    return {
        rawToken: apiReducer.rawToken,
        [apiName]: apiReducer[apiName]
    }
};

export default connect(mapStateToProps)(Colors)
