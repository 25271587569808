import React, {Component} from 'react';
import {connect} from "react-redux";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import "../../css/pages/bills/billsNotes.scss";
import {createResource, deleteResource, getResource, getResources, updateResource} from "../../redux/action";
import SimpleTable from "../../components/SimpleTable";
import {formatDate} from "../../utils/Date";
import Page from "../../components/Page/Page";
import {getParams} from "../../utils/Data";

class BillsNotes extends Component {
    constructor(props) {
        super(props);

        const {id} = getParams()

        this.state = {
            id: id,
            noteUpdated: null,
            formContent: '',
            selected: -1,
        }
    }

    componentDidMount() {
        this.props.dispatch(getResource('bills', this.state.id, this.props.rawToken))
        this.props.dispatch(getResources('notes', this.props.rawToken));
    }

    handleUpdate = id => {
        const note = this.props.notes.find(note => note.id === id);
        this.setState({
            noteUpdated: note
        })
    };

    handleDelete = id => this.props.dispatch(deleteResource('notes', id, this.props.rawToken));

    handleSubmit = e => {
        e.preventDefault();

        let body = {
            content: this.state.formContent
        };

        if(this.state.noteUpdated === null) {
            body.bill = this.props.item.id;
            this.props.dispatch(createResource('notes', body, this.props.rawToken))
                .then(this.setState({
                    formContent: '',
                    noteUpdated: null,
                }))
        }
        else {
            this.props.dispatch(updateResource('notes', this.state.noteUpdated.id, body, this.props.rawToken))
                .then(this.setState({
                    formContent: '',
                    noteUpdated: null,
                }))
        }
    };

    selectRow = (id) => {
        this.setState({
            selected: id
        })
    };

    render() {
        const columns = [
            {
                Header: 'Date de création',
                accessor: 'created',
                width: localStorage.getItem('billsNotes-created') !== null ? localStorage.getItem('billsNotes-created') : 200,
                filterable: false,
                filterMethod: (filter, row) => formatDate(row._original.created).toLowerCase().includes(filter.value.toLowerCase()),
                Cell: ({row}) => {
                    return (row._original.created !== null ? formatDate(row._original.created) : '')
                }
            },
            {
                Header: 'Auteur de la note',
                accessor: 'creator',
                width: localStorage.getItem('billsNotes-creator') !== null ? localStorage.getItem('billsNotes-creator') : 300,
                filterable: false,
                filterMethod: (filter, row) => {
                    const creator = row._original.creator;
                    const value = filter.value.toLowerCase();
                    return creator.firstname.toLowerCase().includes(value) || creator.lastname.toLowerCase().includes(value)
                },
                Cell: ({row}) => {
                    return(<div className={"author"}>{row._original.creator.firstname} {row._original.creator.lastname}</div>)
                }
            },
            {
                Header: 'Contenu',
                accessor: 'content',
                width: localStorage.getItem('billsNotes-content') !== null ? localStorage.getItem('billsNotes-content') : 1150,
                filterable: false,
                Cell: ({row}) => {
                    return(<div className={"text"} dangerouslySetInnerHTML={{__html: row._original.content.substring(0, 150)}}/>)
                }
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                width: 100,
                resizable: false,
                filterable: false,
                Cell: ({ row }) => {
                    return (
                        <div className={"btn-flex"}>
                            <div className="btn-circle btn-warning" title={"Modifier"} onClick={() => this.handleUpdate(row._original.id)}>
                                <i className="fa fa-edit"/>
                            </div>
                            <div className="btn-circle btn-danger" title={"Supprimer"} onClick={() => this.handleDelete(row._original.id)}>
                                <i className="fa fa-trash"/>
                            </div>
                        </div>
                    )
                }
            }
        ];

        const bill = this.props.item;
        const notesWithBill = bill !== null ? this.props.notes.filter(note => note.bill !== null) : [];
        const notes = notesWithBill.filter(note => note.bill.id === bill.id)

        console.log(bill, this.props.notes)

        return (
            bill !== null ?
                <Page title={"Notes de la facture n° "  + bill.number}>
                    <SimpleTable
                        data={notes}
                        columns={columns}
                        frenchName={"notes"}
                        localName={"billsNotes"}
                        selected={this.state.selected}
                        selectRow={id => this.selectRow(id)}
                        defaultPageSize={5}
                    />

                    <form onSubmit={this.handleSubmit} className={"form-group form-bills-notes"}>
                        <CKEditor
                            editor={ ClassicEditor }
                            data={this.state.noteUpdated !== null ? this.state.noteUpdated.content : this.state.formContent}
                            onInit={ editor => {
                                editor.setData(this.state.noteUpdated !== null ? this.state.noteUpdated.content : this.state.formContent)
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                this.setState({formContent:  data})
                            } }
                        />
                        <button title={this.state.noteUpdated === null ? 'Créer' : 'Modifier'}>{this.state.noteUpdated === null ? 'Créer' : 'Modifier'}</button>
                    </form>
                </Page>
                :
            'Chargement ...'
        )
    }
}

const mapStateToProps = ({apiReducer}) => {
    return {
        rawToken: apiReducer.rawToken,
        item: apiReducer.item,
        notes: apiReducer.notes,
    }
};

export default connect(mapStateToProps)(BillsNotes)
