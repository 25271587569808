import React, {Component} from 'react';
import {connect} from "react-redux";
import {createResource, getResources} from "../../redux/action";

class CalendarForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            planned: '',
            name: '',
            priority: '',
            hours: '',
            users: [],
            project: null,
        }
    }

    componentDidMount() {
        this.props.dispatch(getResources('users', this.props.rawToken))
    }

    onChange = e => {
        const name  = e.target.name;
        let value   = e.target.value;

        if(name === 'users') {
            let options = e.target.options;
            value = [];
            for (let i = 0, l = options.length; i < l; i++) {
                if (options[i].selected) {
                    value.push(options[i].value);
                }
            }
        }

        this.setState({
            [name]: value
        });
    };

    onSubmit = e => {
        e.preventDefault();
        this.props.setLoading(true)
        this.setState(state => {
            return {
                ...state,
                //hours: parseInt(state.hours, 10) || null,
                priority: state.priority !== null ? parseInt(state.priority, 10)  : 1,
            }
        }, () => this.props.dispatch(createResource('steps', this.state, this.props.rawToken)).then(() => this.props.setLoading(false)));
    };

    render() {
        return (
            <form className={"form-block addStep"} onSubmit={e => this.onSubmit(e)}>
                <div className="row">
                    <div className="col-12 col-lg">
                       <input type={"date"} placeholder={"Date"} name={"planned"} onChange={e => this.onChange(e)} value={this.state.planned}/>
                    </div>
                    <div className="col-12 col-lg">
                        <input type={"text"} placeholder={"Ajouter une tâche"} name={"name"} onChange={e => this.onChange(e)} value={this.state.name}/>
                    </div>
                    <div className="col-12 col-lg">
                        <select name={"priority"} onChange={e => this.onChange(e)} value={this.state.priority}>
                            <option value={"default"}>Priorité</option>
                            <option value={1}>!</option>
                            <option value={2}>!!</option>
                            <option value={3}>!!!</option>
                            <option value={4}>Point dossier</option>
                            <option value={0}>Relance</option>
                        </select>
                    </div>
                    <div className="col-12 col-lg">
                        <input type={"text"} placeholder={"Nombre d'heures"} name={"hours"} onChange={e => this.onChange(e)} value={this.state.hours}/>
                    </div>
                    <div className={"col-12 col-lg"}>
                        <select name={"users"} multiple={true} onChange={e => this.onChange(e)} value={this.state.users}>
                            <option value={null}>Affecter à une personne</option>
                            {this.props.users.map((user, i) => <option value={user.id} key={i}>{user.firstname}</option>)}
                        </select>
                    </div>
                    <div className={"col-12 col-lg"}>
                        <button className={"btn"}>Ajouter</button>
                    </div>
                </div>
            </form>
        )
    }
}

const mapStateToProps = ({apiReducer}) => {
    return {
        rawToken: apiReducer.rawToken,
        users: apiReducer.users,
    }
};

export default connect(mapStateToProps)(CalendarForm)
