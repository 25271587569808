import React, {Component} from 'react';
import {connect} from "react-redux";
import { withRouter } from "react-router";
import {Link} from "react-router-dom";
import NewWindow from 'react-new-window'

import {title} from "../../security/layout";
import {
    createResource,
    getNewNum,
    getResource, getResourceByProperty,
    getResourcesByProperty,
    updateResource
} from "../../redux/action"
    import MyAutosuggest from "../../components/MyAutosuggest"
import ContactForm from "./ContactForm"
import Page from "../../components/Page/Page"
import {isDefined} from "../../utils/Data"


class ProjectForm extends Component {
    constructor(props) {
        super(props);

        const pathname = window.location.pathname;
        const split = pathname.split('/');

        this.state = {
            showContactForm: false,
            id: split[2],
            project: null,
            form: {
                number: {
                    value: split[2] === 'creer' ? this.props.newNum : ''
                },
                name: {
                    value: ''
                },
                idCustomer: {
                    value: null,
                },
                nameCustomer: {
                    value: '',
                },
                customer: {
                    value: ''
                },
                contacts: {
                    list: [],
                    value: []
                },
                deliveryDate: {
                    value: ''
                },
                freeDeliveryDate: {
                    value: ''
                },
                address: {
                    number: {
                        value: ''
                    },
                    street: {
                        value: ''
                    },
                    postalCode: {
                        value: ''
                    },
                    city: {
                        value: ''
                    },
                    complement: {
                        value: ''
                    }
                },
                path: {
                    value: ''
                },
            },
        }
    }

    componentDidMount() {
        this.props.dispatch(getResourcesByProperty('customers', 'code', this.props.rawToken));

        if(this.state.id !== 'creer') {
            this.props.dispatch(getResource('projects', this.state.id, this.props.rawToken))
                .then((project) => {
                    this.setState({
                        project: project,
                    });
                    Object.keys(this.state.form).map((input) => this.projectToInput(input));
                });
        }
        else {
            this.props.dispatch(getNewNum('projects', this.props.rawToken))
                .then(() => this.setState({
                    form: {
                        ...this.state.form,
                        number: {
                            ...this.state.form.number,
                            value: this.props.newNum
                        }
                    }
                }));
        }
    }

    projectToInput = (input) => {
        if(input === 'address') {
            Object.keys(this.state.form.address).map((input) => {
                const value =   this.state.project.address !== null ? this.state.project.address[input] !== null ? this.state.project.address[input] : '' : '';
                this.setState({
                    form: {
                        ...this.state.form,
                        address: {
                            ...this.state.form.address,
                            [input]: {
                                ...this.state.form.address[input],
                                value: value
                            }
                        },
                    }
                });
            });
        }
        else if(input === 'deliveryDate') {
            let split = this.state.project[input] !== null ? this.state.project[input].split('T') : null;
            this.setState({
                form: {
                    ...this.state.form,
                    [input]: {
                        ...this.state.form[input],
                        value: this.state.project[input] !== null ? split[0] : ''
                    },
                }
            });
        }
        else if(input === 'customer') {
            this.setState({
                form: {
                    ...this.state.form,
                    idCustomer: {
                        ...this.state.form.idCustomer,
                        value: this.state.project.customer !== null ? this.state.project.customer.id : null
                    },
                    customer: {
                        ...this.state.form.customer,
                        value: this.state.project.customer !== null ? this.state.project.customer.code : ''
                    },
                    nameCustomer: {
                        ...this.state.form.nameCustomer,
                        value: this.state.project.customer !== null ? this.state.project.customer.name : ''
                    },
                    contacts: {
                        ...this.state.form.contacts,
                        list: this.state.project.customer.contacts,
                    }
                }
            });
        }
        else if(input === 'contacts') {
            if(this.state.project.contacts !== null)
                this.state.project.contacts.map(contact => (
                    this.setState({
                        form: {
                            ...this.state.form,
                            contacts: {
                                ...this.state.form.contacts,
                                value: [...this.state.form.contacts.value, contact.id]
                            }
                        }
                    })));
        }
        else {
            this.setState({
                form: {
                    ...this.state.form,
                    [input]: {
                        ...this.state.form[input],
                        value: this.state.project[input] !== null ? this.state.project[input] : ''
                    },
                }
            });
        }
    };

    changeHandler = (e, newName, newValue) => {
        const name  = newName !== undefined ? newName : e.target.name;
        let value   = newValue !== undefined ? newValue : e.target.value;

        let split = name.split('.');
        if(split.length > 1) {
            this.setState({
                form: {
                    ...this.state.form,
                    [split[0]]: {
                        ...this.state.form[split[0]],
                        [split[1]] : {
                            ...this.state.form[split[0]][split[1]],
                            value
                        }
                    }
                }
            })
        }
        else {
            this.setState({
                form: {
                    ...this.state.form,
                    [name]: {
                        ...this.state.form[name],
                        value
                    }
                }
            })
        }
    };

    onChangeCustomerFromContact = (e, newName, newValue) => {
        this.props.dispatch(getResource('customers', newValue, this.props.rawToken))
            .then(() => this.changeCustomer(this.props.item))
    };

    onChangeCustomer = (e, newName, newValue) => {
        this.props.dispatch(getResourceByProperty('customers', 'code', newValue, this.props.rawToken))
            .then(id => {
                console.log(id)
                if (isDefined(id))
                    this.props.dispatch(getResource('customers', id, this.props.rawToken))
                        .then(() => this.changeCustomer(this.props.item))
                else
                    this.resetWhenOnChangeCustomer()
            })
    }

    resetWhenOnChangeCustomer = () => {
        this.setState({
            form: {
                ...this.state.form,
                idCustomer: {
                    ...this.state.form.idCustomer,
                    value: null
                },
                customer: {
                    ...this.state.form.customer,
                    value: ''
                },
                nameCustomer: {
                    ...this.state.form.nameCustomer,
                    value: ''
                },
                address: {
                    ...this.state.form.address,
                    number: {
                        ...this.state.form.address.number,
                        value: ''
                    },
                    street: {
                        ...this.state.form.address.street,
                        value: ''
                    },
                    postalCode: {
                        ...this.state.form.address.postalCode,
                        value: ''
                    },
                    city: {
                        ...this.state.form.address.city,
                        value: ''
                    },
                    complement: {
                        ...this.state.form.address.complement,
                        value: ''
                    }
                },
                contacts: {
                    ...this.state.form.contacts,
                    list: []
                }
            }
        })
    };

    changeCustomer = customer => {
        if (isDefined(customer)) {
            this.setState({
                form: {
                    ...this.state.form,
                    idCustomer: {
                        ...this.state.form.idCustomer,
                        value: customer.id
                    },
                    customer: {
                        ...this.state.form.customer,
                        value: customer.code
                    },
                    nameCustomer: {
                        ...this.state.form.nameCustomer,
                        value: customer.name
                    },
                    address: {
                        ...this.state.form.address,
                        number: {
                            ...this.state.form.address.number,
                            value: customer.address !== null ? customer.address.number : ''
                        },
                        street: {
                            ...this.state.form.address.street,
                            value: customer.address !== null ? customer.address.street : ''
                        },
                        postalCode: {
                            ...this.state.form.address.postalCode,
                            value: customer.address !== null ? customer.address.postalCode : ''
                        },
                        city: {
                            ...this.state.form.address.city,
                            value: customer.address !== null ? customer.address.city : ''
                        },
                        complement: {
                            ...this.state.form.address.complement,
                            value: customer.address !== null ? customer.address.complement : ''
                        }
                    },
                    contacts: {
                        ...this.state.form.contacts,
                        list: customer.contacts
                    }
                }
            })
        }
    }

    onChangeContacts = e => {
        e.persist();
        let options = e.target.selectedOptions;
        let values = [];

        Array.from(options,option => values.push(option.value));

        this.setState({
            form: {
                ...this.state.form,
                contacts: {
                    ...this.state.form.contacts,
                    value: values
                }
            }
        });
    };

    handleSubmit = e => {
        e.preventDefault();

        let body = {};
        Object.keys(this.state.form).map((input) => {
            if(input === 'address') {
                body.address = {};
                Object.keys(this.state.form.address).map((subInput) => {
                    body.address[subInput] = this.state.form.address[subInput].value;
                });
            }
            else if(input === 'customer') {
                body.customer = this.state.form.idCustomer.value
            }
            else {
                body[input] = this.state.form[input].value
            }
        });

        body.deliveryDate   = body.deliveryDate === '' ? null : body.deliveryDate;

        if(this.props.id === undefined && this.state.id === 'creer') {
            this.props.dispatch(createResource('adresses', body.address, this.props.rawToken)).then((res) => {
                delete body.address;
                body.address = res.id;
                this.props.dispatch(createResource('projects', body, this.props.rawToken)).then(() => this.props.history.goBack())
            });
        }
        else {
            if(this.state.project.address === null) {
                this.props.dispatch(createResource('adresses', body.address, this.props.rawToken)).then((res) => {
                    delete body.address;
                    body.address = res.id;
                    this.props.dispatch(updateResource('projects', this.state.id, body, this.props.rawToken)).then(() => this.props.history.goBack())
                });
            }
            else {
                this.props.dispatch(updateResource('adresses', this.state.project.address.id, body.address, this.props.rawToken)).then((res) => {
                    delete body.address;
                    body.address = res.id;
                    this.props.dispatch(updateResource('projects', this.state.id, body, this.props.rawToken)).then(() => this.props.history.goBack())
                });
            }
        }
    };

    onAddContact = e => {
        e.preventDefault();
        this.toggleWindowPortal();
    };

    toggleWindowPortal = (close = null, callback = null) => {
       if(callback !== null)
           callback();

        this.setState(state => ({
            showContactForm: close !== null ? close : !state.showContactForm,
        }));

    };

    loadingButton = () => this.state.form.customer.value !== '';

    buttonsRight = () => (
        this.props.id !== undefined || (this.state.id !== null && this.state.id !== 'creer') ?
            <Link to={"/projets/" + this.state.id}>
                <button className={"btn-circle btn-primary"} title={"Voir la fiche"}>
                    <i className={"fas fa-sticky-note"} />
                </button>
            </Link>
            : ''
    )

    render() {
        const features = {
            height: '150',
            width: '1200'
        };

        const customersCode     = this.props.numbers.customers;
        const loadingCustomers  = isDefined(customersCode) && customersCode.length > 0;

        return (
            <Page title={this.props.id !== undefined || (this.state.id !== null && this.state.id !== 'creer') ? 'Modification du projet' : 'Création du projet'}>
                <form onSubmit={this.handleSubmit} className={"form-block form-projects"}>
                    <div className={"form-group row"}>
                        <div className={"col-12 col-lg-1"}>
                            <label>Dossier</label>
                            <input type={"text"} name={"number"} placeholder={"Dossier"} value={this.state.form.number.value} onChange={this.changeHandler}/>
                        </div>
                        <div className={"col-12 col-lg-11"}>
                            <label>Intitulé</label>
                            <input type={"text"} name={"name"} placeholder={"Intitulé"} value={this.state.form.name.value} onChange={this.changeHandler}/>
                        </div>
                    </div>
                    <div className={"form-group row"}>
                        <div className={"col-12 col-lg-1"}>
                            <label>Code client</label>
                            <MyAutosuggest
                                searchWithoutName={true}
                                items={customersCode}
                                name={"customer"}
                                placeholder={loadingCustomers ? "Code Client" : "Chargement ..."}
                                value={this.state.form.customer.value}
                                onChange={this.onChangeCustomer}
                                required={true}
                                disabled={!loadingCustomers}
                            />
                        </div>
                        <div className={"col-12 col-lg-7"}>
                            <label>Client</label>
                            <MyAutosuggest
                                items={this.props.customers}
                                name={"nameCustomer"}
                                placeholder={loadingCustomers ? "Client" : "Chargement ..."}
                                value={this.state.form.nameCustomer.value}
                                onChange={this.onChangeCustomer}
                                required={true}
                                disabled={!loadingCustomers}/>
                        </div>
                        <div className={"col-12 col-lg-2"}>
                            <label>Contacts</label>
                            <select
                                name={"contacts"}
                                multiple={true}
                                value={this.state.form.contacts.value}
                                onChange={this.onChangeContacts}>
                                {this.state.form.contacts.list.map((contact, i) =>
                                    <option key={i} value={contact.id}>{contact.firstname} {contact.lastname}</option>
                                )}
                            </select>
                        </div>
                        <div className={"col-12 col-lg-2"}>
                            <button
                                title={this.state.form.customer.value === '' ? 'Choisissez un client pour ajouter un nouveau contact' : 'Ajouter un nouveau contact au client et au projet'}
                                disabled={this.state.form.customer.value === ''}
                                onClick={e => this.onAddContact(e)}>
                                Ajouter un nouveau contact
                            </button>
                        </div>
                    </div>
                    <div className={"form-group row"}>
                        <div className={"col-12 col-lg-1"}>
                            <label>N°</label>
                            <input type={"text"} name={"address.number"} placeholder={"N°"} value={this.state.form.address.number.value} onChange={this.changeHandler}/>
                        </div>
                        <div className={"col-12 col-lg-4"}>
                            <label>Adresse</label>
                            <textarea rows={1} name={"address.street"} placeholder={"Adresse"} value={this.state.form.address.street.value} onChange={this.changeHandler}/>
                        </div>
                        <div className={"col-12 col-lg-1"}>
                            <label>Code postal</label>
                            <input type={"text"} name={"address.postalCode"} placeholder={"CP"} value={this.state.form.address.postalCode.value} onChange={this.changeHandler}/>
                        </div>
                        <div className={"col-12 col-lg-4"}>
                            <label>Ville</label>
                            <input type={"text"} name={"address.city"} placeholder={"Ville"} value={this.state.form.address.city.value} onChange={this.changeHandler}/>
                        </div>
                    </div>
                    <div className={"form-group row"}>
                        <div className={"col-12 col-lg-2"}>
                            <label>Date de livraison</label>
                            <input type={"date"} name={"deliveryDate"} placeholder={"Date de livraison"} value={this.state.form.deliveryDate.value} onChange={this.changeHandler}/>
                        </div>
                        <div className={"col-12 col-lg-2"}>
                            <label>Date de livraison libre</label>
                            <input type={"text"} name={"freeDeliveryDate"} placeholder={"Date de livraison libre"} value={this.state.form.freeDeliveryDate.value} onChange={this.changeHandler}/>
                        </div>
                    </div>
                    <div className={"form-group row"}>
                        <div className={"col-12"}>
                            <label>Chemin du dossier pour le projet</label>
                            <input type={"text"} name={"path"} placeholder={"Chemin du dossier pour le projet"} value={this.state.form.path.value} onChange={this.changeHandler}/>
                        </div>
                    </div>

                    {this.loadingButton() ?
                            this.props.id !== undefined || (this.state.id !== null && this.state.id !== 'creer') ?
                                <button title={"Modifier"}>Modifier</button>
                            :  <button title={"Créer"}>Créer</button>
                    : <button disabled={true} title={"Selectionner un client"}>Selectionner un client</button>}
                </form>

                {this.state.showContactForm === true ?
                    <NewWindow
                        title={"Ajout d'un contact" + title}
                        onUnload={() => this.toggleWindowPortal(false)}
                        features={features}>
                        <ContactForm
                            customer={this.state.form.idCustomer.value}
                            toggleWindowPortal={this.toggleWindowPortal}
                            onChangeCustomer={this.onChangeCustomerFromContact}/>
                    </NewWindow>
                : ''}
            </Page>
        )
    }
}

const mapStateToProps = ({apiReducer}) => {
    return {
        rawToken:   apiReducer.rawToken,
        newNum:     apiReducer.newNum,
        numbers:    apiReducer.numbers,
        item:       apiReducer.item,
    }
};

export default withRouter(connect(mapStateToProps)(ProjectForm))
