import React from 'react';
import {Link} from "react-router-dom";

import "../../css/pages/products/list.scss";
import ProductsList from "./ProductsList";
import Page from "../../components/Page/Page";

const Products = () => {
    const buttonsRight = () => (
        <Link to={"/produits/creer"}>
            <button className={"btn-circle add-resource"} title={"Ajouter"}>
                <i className={"fas fa-plus"} />
            </button>
        </Link>
    )

    return (
        <Page title={"Catalogue"} className={"products"} headerButtons={buttonsRight()}>
            <ProductsList/>
        </Page>
    )
}

export default Products
