import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {forwardDay, forwardWeek, getStepsForWeek, previousDay, previousWeek} from "../../redux/calendarAction";
import {onSuccessAlert} from "../../utils/Alert";

const CalendarTop = ({toggleMadeSteps, showMadeSteps, setLoading}) => {
    const dispatch  = useDispatch();
    const {rawToken} = useSelector(({apiReducer}) => apiReducer)
    const {date}    = useSelector(({calendarReducer}) => calendarReducer)

    useEffect(() => {
        if(date !== "default") {
            setLoading(true)
            dispatch(getStepsForWeek(date, rawToken)).then(() => setLoading(false))
        }
    }, [date])

    const prevWeek = () => {
        dispatch(previousWeek())
    }

    const prevDay = () => {
        dispatch(previousDay())
    }

    const forDay = () => {
        dispatch(forwardDay())
    }

    const forWeek = () => {
        dispatch(forwardWeek())
    }

    const reload = () => {
        setLoading(true)
        dispatch(getStepsForWeek(date, rawToken)).then(() => {
            setLoading(false)
            onSuccessAlert("Les tâches ont été mises à jour")
        })
    }

    return (
        <div className={"top"}>
            <div className={"btn-flex"}>
                <div className="btn-circle" title={"Voir la semaine précédente"} onClick={() => prevWeek()}>
                    <i className="fas fa-angle-double-left"/>
                </div>
                <div className="btn-circle" title={"Voir le jour précédent"} onClick={() => prevDay()}>
                    <i className="fas fa-angle-left"/>
                </div>
                <div className="btn-circle"
                     title={showMadeSteps === true ? "Cacher les tâches réalisées" : "Afficher les tâches réalisées"}
                     onClick={() => toggleMadeSteps()}
                >
                    <i className={showMadeSteps === true ? "fas fa-eye-slash" : "fas fa-eye"}/>
                </div>
                <div className="btn-circle" title={"Rafraichir"} onClick={() => reload()}>
                    <i className="fas fa-sync"/>
                </div>
                <div className="btn-circle" title={"Voir le jour suivant"} onClick={() => forDay()}>
                    <i className="fas fa-angle-right"/>
                </div>
                <div className="btn-circle" title={"Voir la semaine suivante"} onClick={() => forWeek()}>
                    <i className="fas fa-angle-double-right"/>
                </div>
            </div>
        </div>
    )
}

export default CalendarTop
