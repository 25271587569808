import React from 'react'
import {useSelector} from "react-redux"
import {isDefined} from "../../../utils/Data";

const ProductFooter = ({id, title, show}) => {
    const {groupInputForms} = useSelector(({quotationFormReducer}) => quotationFormReducer)
    const group = groupInputForms.find(group => group.id === id)

    const groupIsNull = (param) => isDefined(group) ? isDefined(group.totals) ? group.totals[param] : "" : ""

    const renderWithTitle = () => {
        switch (title) {
            case 'Produits':
                return (
                    <>
                        <td>Total</td>
                        <td/>
                        <td/>
                        <td/>
                        <td>{groupIsNull("quantity")}</td>
                        <td/>
                        <td/>
                        <td>{groupIsNull("totalha")}</td>
                        <td>{groupIsNull("marginCoefficient")}</td>
                        <td>{groupIsNull("sellingPrice")}</td>
                        <td/>
                    </>
                );
            case 'Sous traitance':
                return (
                    <>
                        <td>Total</td>
                        <td/>
                        <td>{groupIsNull("quantity")}</td>
                        <td/>
                        <td>{groupIsNull("totalha")}</td>
                        <td>{groupIsNull("marginCoefficient")}</td>
                        <td>{groupIsNull("sellingPrice")}</td>
                        <td/>
                    </>
                );
            case 'Prestation':
                return (
                    <>
                        <td>Total</td>
                        <td/>
                        <td>{groupIsNull("quantity")}</td>
                        <td>{groupIsNull("unitPrice")}</td>
                        <td>{groupIsNull("sellingPrice")}</td>
                        <td/>
                    </>
                );
            case 'Façonnage':
                return (
                    <>
                        <td>Total</td>
                        <td/>
                        <td>{groupIsNull("quantity")}</td>
                        <td/>
                        <td>{groupIsNull("totalha")}</td>
                        <td>{groupIsNull("marginCoefficient")}</td>
                        <td>{groupIsNull("sellingPrice")}</td>
                        <td/>
                    </>
                );
            case 'Adhésif':
                return (
                    <>
                        <td>Total</td>
                        <td/>
                        <td/>
                        <td>{groupIsNull("quantity")}</td>
                        <td>{groupIsNull("totalSurface")}</td>
                        <td/>
                        <td>{groupIsNull("totalha")}</td>
                        <td>{groupIsNull("marginCoefficient")}</td>
                        <td>{groupIsNull("sellingPrice")}</td>
                        <td/>
                    </>
                );
            case 'Frais techniques':
                return (
                    <>
                        <td>Total</td>
                        <td/>
                        <td>{groupIsNull("hours")}</td>
                        <td>{groupIsNull("minutes")}</td>
                        <td/>
                        <td>{groupIsNull("sellingPrice")}</td>
                        <td/>
                    </>
                );
            case 'Déplacement':
                return (
                    <>
                        <td>Total</td>
                        <td/>
                        <td>{groupIsNull("quantity")}</td>
                        <td/>
                        <td/>
                        <td>{groupIsNull("daysNumber")}</td>
                        <td>{groupIsNull("sellingPrice")}</td>
                        <td/>
                    </>
                );
            case 'Fournitures':
                return (
                    <>
                        <td>Total</td>
                        <td/>
                        <td>{groupIsNull("quantity")}</td>
                        <td/>
                        <td>{groupIsNull("totalha")}</td>
                        <td>{groupIsNull("marginCoefficient")}</td>
                        <td>{groupIsNull("sellingPrice")}</td>
                        <td/>
                    </>
                );
            case 'Pose':
                return (
                    <>
                        <td>Total</td>
                        <td/>
                        <td>{groupIsNull("quantity")}</td>
                        <td/>
                        <td>{groupIsNull("totalha")}</td>
                        <td>{groupIsNull("marginCoefficient")}</td>
                        <td>{groupIsNull("sellingPrice")}</td>
                        <td/>
                    </>
                );
            default: break;
        }
    };

    return (
        <tr className={"products-row products-row-header products-row-footer"} style={show ? {display: 'table-row'} : {display: 'none'}}>
            {renderWithTitle()}
        </tr>
    )
}

export default ProductFooter
