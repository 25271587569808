import React from 'react';
import { withRouter } from "react-router";
import GeneralForm from "../accounting/GeneralForm";

const QuotationsForm = () => {
    return (
        <GeneralForm name={"quotations"} singularName={"quotation"}/>
    )
};

export default withRouter(QuotationsForm)
