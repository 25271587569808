import React from "react"

import {isDefined} from "../../../utils/Data"

const Row = ({product, index, project, stock, total, totalSurface}) => (
    <tr key={index} className={"article"}>
        <td className={"cell-input small-cell"}>
            {product.reference}
        </td>
        <td className={"cell-input"}>
            {product.designation.split("\n").map((item, key) => (
                <span key={key}>
                {item}
                <br/>
                </span>
            ))}
            {isDefined(project) ? <p>Client : {isDefined(project.customer) ? project.customer.name : ""}</p> : ""}
        </td>
        <td className={"small-cell"}>
            {stock}
        </td>
        <td className={"align-right"}>
            {product.format}
        </td>
        <td className={"text-right small-cell"}>
            {product.surface}
        </td>
        <td className={"text-right small-cell"}>
            {product.quantity}
        </td>
        <td className={"text-right small-cell"}>
            {totalSurface}
        </td>
        <td className={"text-right medium-cell"}>
            {parseFloat(product.unitPrice).toFixed(2) + " €"}
        </td>
        <td className={"text-right medium-cell"}>{total + " €"}</td>
    </tr>
)

export default Row
