import React, {Component} from 'react';
import {withRouter} from "react-router";
import {connect} from 'react-redux'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {createResource, getResource, getResources, getResourcesByProperty, updateResource} from "../../redux/action"
import MyAutosuggest from "../../components/MyAutosuggest"
import {isDefined} from "sr-dates"
import Page from "../../components/Page/Page"

class ArticlesForm extends Component {
    constructor(props) {
        super(props);

        const pathname = window.location.pathname;
        const split = pathname.split('/');

        this.state = {
            id: split[2],
            article: null,
            form: {
                code: {
                    value: ''
                },
                quantity: {
                    value: ''
                },
                unitPrice: {
                    value: ''
                },
                category: {
                    value: ''
                },
                text: {
                    value: ''
                },
            },
        }
    }

    componentDidMount() {
        this.props.dispatch(getResources('articleCategories', this.props.rawToken));
        this.props.dispatch(getResourcesByProperty('articles', 'code', this.props.rawToken));

        if(this.state.id !== 'creer') {
            this.props.dispatch(getResource('articles', this.state.id, this.props.rawToken))
                .then((article) => {
                    this.setState({
                        article: article,
                    });
                    Object.keys(this.state.form).map((input) => this.resourceToInput(input));
                });
        }
    }

    resourceToInput = (input) => {
        if(input === 'category') {
            const category =  this.state.article.category;
            this.changeHandler(null, input,  category!== null ? category.id : null)
        }
        else {
            const value = this.state.article[input] !== null ? this.state.article[input] : '';
            this.changeHandler(null, input, value);
        }
    };

    changeHandler = (e, newName, newValue) => {
        const name  = newName !== undefined ? newName : e.target.name;
        let value   = newValue !== undefined ? newValue : e.target.value;

        this.setState({
            form: {
                ...this.state.form,
                [name]: {
                    ...this.state.form[name],
                    value
                }
            }
        })
    };

    handleSubmit = e => {
        e.preventDefault();

        let body = {};
        Object.keys(this.state.form).map((input) => body[input] = this.state.form[input].value);

        body.quantity   = body.quantity !== '' ? body.quantity : '0';
        body.unitPrice  = body.quantity !== '' ? parseFloat(body.unitPrice).toFixed(2) : '0';
        body.category   = body.category !== '' ? body.category : null ;
        body.madeByForm = true;

        if(this.state.id === 'creer') {
            this.props.dispatch(createResource('articles', body, this.props.rawToken)).then(() => this.props.history.goBack());
        }
        else {
            this.props.dispatch(updateResource('articles', this.state.id, body, this.props.rawToken)).then(() => this.props.history.goBack())
        }
    };

    render() {
        const form = this.state.form;
        const articlesCode      = this.props.numbers.articles;
        const loadingArticles   = isDefined(articlesCode) && articlesCode.length > 0;

        return (
            <Page title={(this.state.id !== 'creer' ? 'Modification ' : 'Création') + ' d\'un article'}>
                <form onSubmit={this.handleSubmit} className={"form-block"}>
                    <div className={"row"}>
                        <div className={"col-12 col-lg-2"}>
                            <label>Code</label>
                            <MyAutosuggest
                            disabled={!loadingArticles}
                            searchWithoutName={true}
                            items={articlesCode}
                            name={"code"}
                            placeholder={loadingArticles ? "Code" : "Chargement ..."}
                            value={form.code.value}
                            onChange={this.changeHandler}/>
                        </div>
                        <div className={"col-12 col-lg-2"}>
                            <label>Quantité</label>
                            <input type={"text"} name={"quantity"} placeholder={"Quantité"} value={form.quantity.value} onChange={this.changeHandler}/>
                        </div>
                        <div className={"col-12 col-lg-2"}>
                            <label>Prix unitaire</label>
                            <input type={"text"} name={"unitPrice"} placeholder={"Prix unitaire"} value={form.unitPrice.value} onChange={this.changeHandler}/>
                        </div>
                        <div className={"col-12 col-lg-6"}>
                            <label>Catégorie</label>
                            <select name={"category"} value={form.category.value} onChange={this.changeHandler} required={true}>
                                <option value={null}>Catégorie</option>
                                {this.props.articleCategories.map((category, i) =>
                                    <option key={i} value={category.id}>{category.name}</option>
                                )}
                            </select>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-12"}>
                            <CKEditor
                                editor={ ClassicEditor }
                                data={form.text.value}
                                onInit={ editor => {
                                    editor.setData(form.text.value)
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    this.changeHandler(null, 'text', data);
                                } }
                            />
                        </div>
                    </div>

                    {this.state.id !== 'creer' ? <button title={"Modifier"}>Modifier</button> : <button title={"Créer"}>Créer</button>}
                </form>
            </Page>
        )
    }
}

const mapStateToProps = ({apiReducer}) => {
    return {
        rawToken: apiReducer.rawToken,
        articleCategories: apiReducer.articleCategories,
        numbers: apiReducer.numbers,
    }
};

export default withRouter(connect(mapStateToProps)(ArticlesForm))
