import React, {Component} from 'react';
import 'react-table/react-table.css'
import HeaderBlock from "../../../components/HeaderBlock";
import Modal from 'react-modal';
import {connect} from "react-redux";
import {createResource, deleteResource, getResources, updateResource} from "../../../redux/action";
import "../../../css/components/modal.scss"
import {formatDate} from "../../../utils/Date";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MyModal from "../../../components/MyModal";
import Table from "../../../components/Table";
import {onDelete} from "../../../utils/Alert";
import SimpleTable from "./Steps";

Modal.setAppElement('#root');

class Notes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: localStorage.getItem('fiche-project-notes-show') !== null ? JSON.parse(localStorage.getItem('fiche-project-notes-show')) : true,
            modalIsOpen: false,
            noteUpdated: null,
            selected: -1,
            noteViewed: {
                creator: {
                    firstname: '',
                    lastname: '',
                },
                created: '',
                content: '',
            },
            form: {
                content: {
                    value: ''
                },
            },
            modalViewNote: {
                isOpen: false,
            }
        };
    }

    componentDidMount() {
        this.props.dispatch(getResources('notes', this.props.rawToken))
    }

    handleVisibility = () => {
        this.setState(prevState => {
            return {
                show: !prevState.show
            }
        });
        localStorage.setItem('fiche-project-notes-show', this.state.show === true ? 'false' : 'true');
    };

    handleDelete = id => onDelete('', 'Etes-vous sûr de vouloir supprimer cette note ?', () => {
        this.props.removeNote("notes", id)
        this.props.dispatch(deleteResource('notes', id, this.props.rawToken))
    });

    handleOpenModal = (id = null) => {
        this.setState( {
             modalIsOpen: true,
        });

        if(id !== null) {
            this.setState({
                noteUpdated: this.props.notes.find(note => note.id === parseInt(id, 10))
            }, () => {
                this.setState({
                    form: {
                        ...this.state.form,
                        'content': {
                            ...this.state.form.name,
                            value: this.state.noteUpdated.content !== null ? this.state.noteUpdated.content : ''
                        }
                    }
                });
            });
        }
        else {
            this.setState({
                noteUpdated: null,
                form: {
                    ...this.state.form,
                    'content': {
                        ...this.state.form.name,
                        value: ''
                    }
                }
            })
        }
    };

    handleCloseModal = (isViewNote = false) => {
        isViewNote === true ?
            this.setState({
                modalViewNote: {
                    ...this.state.modalViewNote,
                    isOpen: false,
                },
            })
        :
            this.setState({modalIsOpen: false});
    };


    changeHandler = (e, newName, newValue) => {
        const name  = newName !== undefined ? newName : e.target.name;
        let value   = newValue !== undefined ? newValue : e.target.value;

        this.setState({
            form: {
                ...this.state.form,
                [name]: {
                    ...this.state.form[name],
                    value
                }
            }
        })
    };

    handleSubmit = e => {
        e.preventDefault();

        let body = {};
        Object.keys(this.state.form).map((input) =>
            body[input] = this.state.form[input].value
        );

        if(this.state.noteUpdated === null) {
            body.project = this.props.project.id;

            this.props.dispatch(createResource('notes', body, this.props.rawToken))
                .then(note => {
                    this.props.addNote("notes", note)
                    this.setState( {
                        modalIsOpen: false
                    })
                })
        }
        else {
            this.props.dispatch(updateResource('notes', this.state.noteUpdated.id, body, this.props.rawToken))
                .then(note => {
                    this.props.updateNote("notes", note)
                    this.setState({
                        modalIsOpen: false
                    })
                })
        }
    };

    handleOpenModalViewNote = (id) => {
        this.setState({
            noteViewed: this.props.notes.find(note => note.id === parseInt(id, 10))},
            () => {
                this.setState({
                    modalViewNote: {
                        ...this.state.modalViewNote,
                        isOpen: true,
                    },
                });
        });
    };

    selectRow = (id) => {
        this.setState({
            selected: id
        })
    };

    render() {
        const columns = [
            {
                Header: 'Date de création',
                accessor: 'created',
                width: localStorage.getItem('fiche-project-notes-created') !== null ? localStorage.getItem('fiche-project-notes-created') : 170,
                Cell: ({row}) => {
                    return (row._original.created !== null ? formatDate(row._original.created) : '')
}
            },
            {
                Header: 'Auteur de la note',
                accessor: 'creator',
                width: localStorage.getItem('fiche-project-notes-creator') !== null ? localStorage.getItem('fiche-project-notes-creator') : 200,
                Cell: ({row}) => {
                    return(<div className={"author"}>{row._original.creator.firstname} {row._original.creator.lastname}</div>)
                }
            },
            {
                Header: 'Contenu',
                accessor: 'content',
                width: localStorage.getItem('fiche-project-notes-content') !== null ? localStorage.getItem('fiche-project-notes-content') : 1200,
                Cell: ({row}) => {
                    return(<div className={"text"} dangerouslySetInnerHTML={{__html: row._original.content.substring(0, 150) + (row._original.content.length > 150 ? "[...] <p>(Cliquez-sur le bouton bleu pour voir la note en entier)</p>" : "")}}/>)
                }
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                width: 150,
                resizable: false,
                filterable: false,
                sortable: false,
                Cell: ({ row }) => {
                    return (
                        <div className={"btn-flex"}>
                            <div className="btn-circle btn-primary" title={"Voir"} onClick={() => this.handleOpenModalViewNote(row._original.id)}>
                                <i className="fa fa-eye"/>
                            </div>
                            <div className="btn-circle btn-warning" title={"Modifier"} onClick={() => this.handleOpenModal(row._original.id)}>
                                <i className="fa fa-edit"/>
                            </div>
                            <div className="btn-circle btn-danger" title={"Supprimer"} onClick={() => this.handleDelete(row._original.id)}>
                                <i className="fa fa-trash"/>
                            </div>
                        </div>
                    )
                }
            }
        ];

        const notes = this.props.project.notes //.notes.filter(note => note.project !== null).filter(note => note.project.id === this.props.project.id);

        return (
            <div className={"block"}>
                <HeaderBlock title={"Notes"} handleVisibility={this.handleVisibility} handleOpenModal={this.handleOpenModal} show={this.state.show} addButtonActive={true} addButtonTitle={"Ajouter une note"}/>

                <Table
                    style={this.state.show === true ? {display: 'block'} : {display: 'none'}}
                    data={notes}
                    columns={columns}
                    filterable={false}
                    sortable={false}
                    multiSort={false}
                    defaultPageSize={5}
                    frenchName={"notes"}
                    localName={"fiche-project-notes"}
                    apiName={"notes"}
                    selected={this.state.selected}
                    selectRow={id => this.selectRow(id)}
                />

                <MyModal
                    isOpen={this.state.modalIsOpen}
                    handleClose={() => this.handleCloseModal()}
                    title={this.state.noteUpdated === null ? 'Créer une note' : 'Modifier une note'}
                    content={
                        <form onSubmit={this.handleSubmit}  className={"form-block"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data="<p>Hello from CKEditor 5!</p>"
                                        onInit={ editor => {
                                            editor.setData(this.state.form.content.value)
                                        } }
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            this.setState({
                                                form: {
                                                    ...this.state.form,
                                                    content: {
                                                        ...this.state.form.content,
                                                        value: data
                                                    }
                                                }
                                            })
                                        } }
                                    />
                                </div>
                            </div>
                            <button title={this.state.noteUpdated === null ? 'Créer' : 'Modifier'}>{this.state.noteUpdated === null ? 'Créer' : 'Modifier'}</button>
                        </form>
                    }
                />

                <MyModal
                    isOpen={this.state.modalViewNote.isOpen}
                    handleClose={() => this.handleCloseModal(true)}
                    title={'Note créée par ' + this.state.noteViewed.creator.firstname + ' ' + this.state.noteViewed.creator.lastname + ' le ' + formatDate(this.state.noteViewed.created)}
                    content={this.state.noteViewed.content}
                    contentIsHtml={true}
                />
            </div>
        )
    }
}

const mapStateToProps = ({apiReducer}) => {
    return {
        rawToken: apiReducer.rawToken,
        notes: apiReducer.notes,
    }
};

export default connect(mapStateToProps)(Notes)
