import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router";
import ReactToPrint from 'react-to-print';

import "../../../css/pages/quotations/write.scss";
import {addRow, changeCity, changeRow, resetWriteQuotationForm} from "../../../redux/QuotationWriteAction";
import WriteRow from "./WriteRow";
import { createResource, getResource, specialPut, updateResource} from "../../../redux/action";
import PrintQuotation from "../Print/PrintQuotation";
import {onSaved} from "../../../utils/Alert";
import Page from "../../../components/Page/Page";
import {isDefined} from "../../../utils/Data";

class WriteQuotation extends Component {
    constructor(props) {
        super(props);

        const pathname = window.location.pathname;
        const split = pathname.split('/');

        this.state = {
            id: parseInt(split[2], 10),
            resourceType: split[1],
            resource: null,
            canPrint: false,
            needSave: false,
        };
    }

    componentDidMount() {
        this.reset();
    }

    componentWillUnmount() {
        this.props.dispatch(resetWriteQuotationForm())
    }

    reset = () => this.props.dispatch(resetWriteQuotationForm()).then(this.resourceToInput());

    resourceToInput = () => {
        this.props.dispatch(getResource(this.state.resourceType === 'devis' ? 'quotations' : 'bills', this.state.id, this.props.rawToken))
            .then((resource) =>
                this.setState({
                    resource: resource
                }, this.addRowWhenUpdate))
    };

    handleBack = () =>  this.props.history.goBack();

    addRowWhenUpdate = () => {
        const resource = this.state.resource;

        if(resource !== null) {
            if(resource.city !== null)
                this.props.dispatch(changeCity(resource.city));

            if (resource.articles.length > 0)
                resource.articles.map((article, i) => {
                    this.addRow(null, article, i)
                });
            else
                this.addRow();
        }
    };

    addRow = (e = null, article = null, i = null) => {
        if(e !== null)
            e.preventDefault();
        const id = i !== null ? i : this.props.rowId;
        this.props.dispatch(addRow(<WriteRow key={id} id={id} article={article} onDrag={this.onDrag} />))
    };

    onChangeCity = e => {
        e.preventDefault();
        const value = e.target.value;
        this.props.dispatch(changeCity(value));
    };

    onChangeAmountAtZero = e => {
        e.preventDefault()

        const value         = e.target.checked;
        const resource      = this.props.item;
        const resourceType  = this.state.resourceType === 'devis' ? 'quotations' : 'bills';

        this.props.dispatch(updateResource(resourceType, resource.id, {amountAtZero: value}, this.props.rawToken))
    }

    handleValidation = () => {
        if(isDefined(this.state.resource)) {
            const totalResource = parseFloat(this.state.resource.totalPrice).toFixed(2) || "0.00"

            if(this.state.canPrint !== (this.getTotalPrice() === totalResource))
                this.setState({
                    canPrint: this.getTotalPrice() === totalResource
                })
        }

    }

    addArticleToQuotation = async (resource) => {
        await Promise.all(this.props.data.rows.map((row) => {
            row.data.quotation  = this.state.resourceType === 'devis' ? resource.id : null;
            row.data.bill       = this.state.resourceType === 'factures' ? resource.id : null;
            row.data.unitPrice  = parseFloat(row.data.unitPrice).toFixed(2) || '0';

            if(row.data.id === undefined)
                return this.props.dispatch(createResource('articles', row.data, this.props.rawToken));
            else
                return this.props.dispatch(updateResource('articles', row.data.id, row.data, this.props.rawToken));
        }));
        this.reset();
        onSaved('/' + this.state.resourceType + '/' + resource.id + '/rediger');
    };

    getTotalPrice = () => {
        let totalPrice = 0

        this.props.data.rows.forEach(row => {
            totalPrice += parseFloat(row.data.sellingPrice)
        })

        return parseFloat(totalPrice).toFixed(2)
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const data          = this.props.data;
        const resource      = this.state.resource;
        const resourceType  = this.state.resourceType === 'devis' ? 'quotations' : 'bills';
        const totalPrice    = parseFloat(this.getTotalPrice());

        this.props.dispatch(updateResource(resourceType, resource.id, {city: data.city, totalPrice: totalPrice}, this.props.rawToken))
            .then(resource => this.addArticleToQuotation(resource));

        if((this.state.resource.drafted === null && this.props.data.rows.length > 0) || (this.state.resource.drafted !== null && this.props.data.rows.length <= 0))
            this.props.dispatch(specialPut(resource.id, this.props.rawToken, resourceType, 'draft'));
    };

    onDrag = (id, up = true) => {
        const from  = this.props.data.rows.findIndex(row => row.id === id);
        const to    = up ? from - 1 : from + 1;

        const oldRows = [...this.props.data.rows]
        const newRows = this.reorderArray(from, to, oldRows)

        newRows.forEach((row, i) => {
            this.props.dispatch(changeRow(null, row.id, 'position', i))
        })
    }

    reorderArray = (from, to ,originalArray) => {
        const movedItem         = originalArray.find((item, index) => index === from);
        const remainingItems    = originalArray.filter((item, index) => index !== from);

        const reorderedItems = [
            ...remainingItems.slice(0, to),
            movedItem,
            ...remainingItems.slice(to)
        ];

        return reorderedItems;
    }

    buttonsRight = () => (
        <>
            <button className={"btn-circle btn-primary"} title={"Retour"} onClick={this.handleBack}>
                <i className={"fas fa-arrow-left"} />
            </button>
            <button className={"btn-circle btn-success"} title={"Ajouter une ligne"} onClick={e => this.addRow(e)}>
                <i className={"fas fa-plus"} />
            </button>
        </>
    )

    render() {
        const resource  = this.state.resource;
        const data      = this.props.data;
        const title1    = "Rédaction " + (this.state.resourceType === 'devis' ? 'du devis' : 'de la facture');
        const title2    = resource !== null ? (' n° ' + resource.number + ' - ' + resource.name + ' (' + (resource.totalPrice !== null ? parseFloat(resource.totalPrice).toFixed(2) : this.getTotalPrice()) + ' €)') : '';

        return (
            <Page title={title1 + title2} headerButtons={this.buttonsRight()} className={"write-quotation"}>
                <form onSubmit={this.handleSubmit} className={"form-group"} onChange={this.handleValidation()}>
                    <div className={"form-group row"}>
                        <div className={"col-12 col-lg-2 city"}>
                            <label>Ville affichée </label>
                            <select name={"city"} onChange={this.onChangeCity} value={data.city}>
                                <option value={"Reims"}>Reims</option>
                                <option value={"Châlons"}>Châlons</option>
                            </select>
                        </div>
                    </div>
                    <div className={"flex-form"}>
                        <table className={"write"}>
                            <thead>
                                <tr>
                                    <td className={"code"}>Code</td>
                                    <td>Texte</td>
                                    <td className={"little-cell"}>Quantité</td>
                                    <td className={"little-cell"}>Prix unitaire</td>
                                    <td className={"little-cell"}>Prix de vente</td>
                                    <td className={"actions"}/>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.data.rows.sort((row, row2) => row.data.position - row2.data.position).map(row => row.form)}
                            </tbody>
                        </table>
                        <button title={'Enregistrer'} className={"submit"}>Enregistrer</button>
                        {this.state.canPrint ?
                            <ReactToPrint
                                trigger={() => <div className={"btn-div"}>Imprimer</div>}
                                content={() => this.componentRef}
                            />
                            :
                            <div className={"btn-div sr-btn sr-btn-disabled"}>Enregistrer pour pouvoir imprimer</div>
                        }
                        <PrintQuotation ref={el => (this.componentRef = el)} resource={this.state.resource} type={this.state.resourceType} needSave={this.state.needSave}/>
                    </div>
                </form>
            </Page>
        )
    }
}

const mapStateToProps = ({apiReducer, quotationWriteReducer}) => {
    return {
        rawToken: apiReducer.rawToken,
        rowId: quotationWriteReducer.rowId,
        quotations: apiReducer.quotations,
        bills: apiReducer.bills,
        data: quotationWriteReducer.form,
        item: apiReducer.item,
    }
};

export default withRouter(connect(mapStateToProps)(WriteQuotation))
