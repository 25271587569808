import React, {Component} from 'react';
import { Link } from "react-router-dom"
import {connect} from "react-redux";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

import {
    deleteResource, getResources,
} from "../../redux/action";
import {formatDateTime} from "../../utils/Date";
import SimpleTable from "../../components/SimpleTable";
import {getColumnsWidth} from "../../utils/List";
import {isDefined, renderIfDefinedParams} from "../../utils/Data";
import {onDelete} from "../../utils/Alert";

class MessagesList extends Component {
    constructor(props) {
        super(props);

        this.frenchName = 'messages';
        this.localName  = 'messages';
        this.apiName    = 'messages';

        this.localNames = ['sent', 'project', 'customer.code', 'customer.name', 'title', 'sender', 'seen'];
        this.localNames = this.localNames.map(name => this.localName + '-' + name);

        this.state = {
            columns: [
                {
                    Header: 'Date d\'envoi',
                    accessor: 'sent',
                    filterable: false,
                    sortable: false,
                    width: getColumnsWidth(this.localNames[0], 150),
                    Cell: ({row}) => formatDateTime(row._original.sent)
                },
                {
                    Header: 'Projet',
                    accessor: 'project',
                    filterable: false,
                    sortable: false,
                    width: getColumnsWidth(this.localNames[1], 70),
                    /*Cell: ({row}) =>
                        <Link to={"/projets/" + row._original.project.id }>
                            {row._original.project.number}
                        </Link>*/
                    Cell: ({row}) => renderIfDefinedParams(row._original.project, 'number')
                },
                {
                    Header: 'N° Client',
                    accessor: 'customer.code',
                    filterable: false,
                    sortable: false,
                    width: getColumnsWidth(this.localNames[2], 70),
                    Cell: ({row}) => isDefined(row._original.project) ? isDefined(row._original.project.customer) ? row._original.project.customer.code : '' : ''
                },
                {
                    Header: 'Client',
                    accessor: 'customer.name',
                    filterable: false,
                    sortable: false,
                    width: getColumnsWidth(this.localNames[3], 530),
                    Cell: ({row}) => isDefined(row._original.project) ? isDefined(row._original.project.customer) ? row._original.project.customer.name : '' : ''
                },
                {
                    Header: 'Titre',
                    accessor: 'title',
                    filterable: false,
                    sortable: false,
                    width: getColumnsWidth(this.localNames[4], 470),
                },
                {
                    Header: 'Auteur',
                    accessor: 'sender',
                    filterable: false,
                    sortable: false,
                    width: getColumnsWidth(this.localNames[5], 150),
                    Cell: ({row}) => row._original.sender.firstname + " " + row._original.sender.lastname
                },
                {
                    Header: 'Vu le',
                    accessor: 'seen',
                    filterable: false,
                    sortable: false,
                    width: getColumnsWidth(this.localNames[6], 170),
                    Cell: ({row}) => {
                        const messaging = row._original.messagings[0]
                        return isDefined(messaging) ? formatDateTime(messaging.seen) : ""
                    }
                },
                {
                    Header: 'Actions',
                    accessor: 'id',
                    sortable: false,
                    resizable: false,
                    filterable: false,
                    width: 150,
                    Cell: ({ row }) => {
                        return (
                            <div className={"btn-flex"}>
                                <Link to={"/messages/" + row._original.id}>
                                    <div className="btn-circle btn-primary" title={"Voir"}>
                                        <i className="fa fa-eye"/>
                                    </div>
                                </Link>
                                <Link to={"/messages/" + row._original.id + "/modifier"}>
                                    <div className="btn-circle btn-warning" title={"Modifier"}>
                                        <i className="fa fa-edit"/>
                                    </div>
                                </Link>
                                <div className="btn-circle btn-danger" title={"Supprimer"}
                                     onClick={() => this.handleDelete(row._original.id)}>
                                    <i className="fa fa-trash"/>
                                </div>
                            </div>
                        )
                    }
                },
            ],
            columnsSent: []
        }
    }

    componentDidMount() {
        this.props.dispatch(getResources('messages', this.props.rawToken))
        this.props.dispatch(getResources('messagesSent', this.props.rawToken))
            .then(() => {
                this.setState({
                    columnsSent: [...this.state.columns]
                }, () => {
                    this.setState({
                        columnsSent: [
                            ...this.state.columnsSent.map((column, i) =>
                                i === 3 ?
                                    {
                                        Header: 'Destinaires',
                                        accessor: 'receivers',
                                        filterable: false,
                                        sortable: false,
                                        width: getColumnsWidth(this.localName + '-receivers', 250),
                                        Cell: ({row}) => row._original.messagings.map(messaging => <li key={i}>{renderIfDefinedParams(messaging.receiver, 'firstname') + " " + renderIfDefinedParams(messaging.receiver, 'lastname') }</li>
                                        )
                                    }
                                    : i === 6 ?
                                    {
                                        Header: 'Vu le',
                                        accessor: 'seen',
                                        filterable: false,
                                        sortable: false,
                                        width: getColumnsWidth(this.localNames[6], 200),
                                        Cell: ({row}) => row._original.messagings.map((messaging, i) =>  <li key={i}>{formatDateTime(messaging.seen)}</li>)
                                    }
                                     : column
                            ),
                        ]
                    });
                });

            })
    }

    handleDelete = id => onDelete('', 'Etes-vous sûr de vouloir supprimer ce message ?', () => this.props.dispatch(deleteResource('messages', id, this.props.rawToken)));

    render() {
        return (
            <Tabs>
                <TabList>
                    <Tab>Messages reçus</Tab>
                    <Tab>Messages envoyés</Tab>
                </TabList>

                <TabPanel>
                    <SimpleTable
                        data={this.props.messages}
                        columns={this.state.columns}
                        frenchName={this.frenchName}
                        localName={this.localName}
                        apiName={this.apiName}
                        filterable={false}
                        defaultSorted={[
                            {
                                id: "sent",
                                desc: true
                            }
                        ]}
                    />
                </TabPanel>
                <TabPanel>
                    <SimpleTable
                        data={this.props.messagesSent}
                        columns={this.state.columnsSent}
                        frenchName={this.frenchName}
                        localName={this.localName}
                        apiName={this.apiName}
                        filterable={false}
                        defaultSorted={[
                            {
                                id: "sent",
                                desc: true
                            }
                        ]}
                    />
                </TabPanel>
            </Tabs>
        )
    }
}

const mapStateToProps = ({apiReducer}) => {
    return {
        rawToken: apiReducer.rawToken,
        messages: apiReducer.messages,
        messagesSent: apiReducer.messagesSent,
    }
};

export default connect(mapStateToProps)(MessagesList)
