import {CHANGE_PROPERTY, RESET} from "../../actions/forms/permissionFormAction";

const initialState = {
    data: {
        firstname: '',
        lastname: '',
        society: '',
        job: '',
        phone: '',
        email: '',
        address: {
            number: '',
            street: '',
            postalCode: '',
            city: '',
            complement: ''
        }
    },
}

const repertoireFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_PROPERTY:
            const split = action.name.split('.');

            if(split.length > 1) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        [split[0]]: {
                            ...state.data[split[0]],
                            [split[1]]: action.value
                        }
                    }
                };
            }
            else {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        [action.name]: action.value
                    }
                };
            }
        case RESET:
            return initialState;
        default:
            return state;
    }
}

export default repertoireFormReducer
