import React from "react"

import Logo from "../../../images/MarneEnseignes.png"
import {formatDate} from "../../../utils/Date"

const TopPrint = props => {
    const resource      = props.resource;
    const type          = props.type;
    const addressExist  = resource !== null ? resource.project.address !== undefined && resource.project.address !== null : false;

    return (
        <div className="row header">
            <div className="col-5 left-header">
                <div className={"text-center"}>
                    <img className={"logo-me"} src={Logo} alt={"Logo de Marne Enseignes"}/>
                </div>
                <p>
                    {resource.city === 'Reims' ?
                        <>Z.I.S.E. Rue Maurice Hollande BP 362 <br/> 51689 REIMS CEDEX 02</>
                        :
                        <>Rue Raoul Follereau <br/> 51520 Saint Martin sur le Pré</>
                    }
                </p>
                <p className={"bold"}>
                    Tél : 03 26 82 52 95<br/>
                    contact@marne-enseignes.fr
                </p>
                <p>
                    Siret : 43204006100017<br/>
                    Sarl au capital de 7622 €
                    <br/><br/>
                    TVA INTRACOMMUNAUTAIRE : FR 84 432 040 061
                </p>
            </div>
            <div className="col-7">
                <div className="row">
                    <div className="col-7">
                        <div className="panel panel-default panel-header">
                            <p className={"text-center"}>
                                {type === 'devis' ? 'Devis' : 'Facture'} numéro <span className={"bold"} id="numDevis">{resource.number}</span>
                            </p>
                            <p className={"text-center"}>
                                Réf. dossier n°<span className={"bold"}>{resource.project.number}</span>
                            </p>
                            <p className={"text-center"}>
                                {resource.city === 'Reims' ? 'Reims' : 'Saint Martin sur le Pré'} le <span className={"bold"}>{formatDate(resource.created)}</span>
                            </p>
                        </div>
                    </div>
                    <div className="col-5">
                        <div className="panel panel-default panel-header">
                            <p className={"text-center"}>REF CLIENT</p>
                            <p className={"text-center"}><span className={"bold"}>{resource.project.customer.code}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className="col-12 customer">
                        <h5 id="nomSociete">{resource.customerName !== null ? resource.customerName : resource.project.customer.name}</h5>
                        <p style={{whiteSpace: 'pre-line'}}>{addressExist === true ? resource.project.address.number  + ' ' + resource.project.address.street : ""}
                        </p>
                        <p>
                            {addressExist === true ? resource.project.address.postalCode : ''} <span className={"uppercase"}>{addressExist === true ? resource.project.address.city : ''}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default TopPrint
