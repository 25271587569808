import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";

import {createResource, deleteResource, getResources, updateResource} from "../../../redux/action";
import HeaderBlock from "../../../components/HeaderBlock"
import SimpleTable from "../../../components/SimpleTable";
import MyModal from "../../../components/MyModal";
import {onDelete} from "../../../utils/Alert";

const StepSetting = () => {
    const frenchName    = 'tâches pré-définies';
    const localName     = 'settings-stepSettings';
    const apiName       = 'stepSettings';

    const [show, setShow] = useState(localStorage.getItem(localName + '-show') !== null ? JSON.parse(localStorage.getItem(localName + '-show')) : true)
    const [stepUpdated, setStepUpdated] = useState(null)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [name, setName] = useState('')

    const dispatch = useDispatch()
    const {rawToken, stepSettings} = useSelector(({apiReducer}) => apiReducer)

    useEffect(() => {
        dispatch(getResources(apiName, rawToken))
    }, [])

    const handleVisibility = () => {
        setShow(oldShow => !oldShow)
        localStorage.setItem(localName + '-show', "" + !show);
    };

    const handleDelete = id => onDelete('', 'Etes-vous sûr de vouloir supprimer cette tâche pré-définie ?', () => dispatch(deleteResource(apiName, id, rawToken)))

    const handleOpenModal = (id = null) => {
        setModalIsOpen(true)

        if (id !== null) {
            const stepSetting = stepSettings.find(stepSetting => stepSetting.id === parseInt(id, 10));

            if (stepSetting !== null) {
                setStepUpdated(stepSetting)
                setName(stepSetting.name !== null ? stepSetting.name : '')
            }
        } else {
            setStepUpdated(null)
            setName('')
        }
    };

    const handleCloseModal = () => setModalIsOpen(false)

    const changeHandler = e => setName(e.target.value)

    const handleSubmit = e => {
        e.preventDefault();

        if (stepUpdated === null) {
            dispatch(createResource(apiName, {name: name}, rawToken))
                .then(() => setModalIsOpen(false))
        } else {
            dispatch(updateResource(apiName, stepUpdated.id, {name: name}, rawToken))
                .then(() => setModalIsOpen(false))
        }
    };

    const columns = [
        {
            Header: 'Tâches pré-définies',
            accessor: 'name',
            width: localStorage.getItem(localName + '-name') !== null ? localStorage.getItem(localName +  '-name') : 1650,
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            width: 100,
            filterable: false,
            sortable: false,
            resizable: false,
            Cell: ({row}) => {
                return (
                    <div className={"btn-flex"}>
                        <div className="btn-circle btn-warning" title={"Modifier"}
                             onClick={() => handleOpenModal(row._original.id)}>
                            <i className="fa fa-edit"/>
                        </div>
                        <div className="btn-circle btn-danger" title={"Supprimer"}
                             onClick={() => handleDelete(row._original.id)}>
                            <i className="fa fa-trash"/>
                        </div>
                    </div>
                )
            }
        }
    ];

    return (
        <div className={"block"}>
            <HeaderBlock title={"Tâches pré-définies"}
                         handleVisibility={handleVisibility}
                         show={show}
                         handleOpenModal={handleOpenModal}
                         addButtonActive={true}
                         addButtonTitle={"Ajouter une tâche pré-définie"}/>

             <SimpleTable
                 data={stepSettings}
                 columns={columns}
                 frenchName={frenchName}
                 localName={localName}
                 style={show === true ? {display: 'block'} : {display: 'none'}}
                 defaultPageSize={5}
                 defaultSorted={[
                     {
                         id: "name",
                         desc: false
                     }
                 ]}
             />

             <MyModal
                 title={(stepUpdated === null ? "Créer" : "Modifier") + " une tâche pré-définie"}
                 isOpen={modalIsOpen}
                 handleClose={handleCloseModal}
                 content={
                     <form onSubmit={handleSubmit} className={"form-block"}>
                         <div className={"row"}>
                             <div className={"col-12"}>
                                <input type={"name"} name={"name"} placeholder={"Intitulé"} value={name} onChange={changeHandler}/>
                             </div>
                         </div>
                         <button title={stepUpdated === null ? 'Créer' : 'Modifier'}>{stepUpdated === null ? 'Créer' : 'Modifier'}</button>
                     </form>
                 }
             />
        </div>
    )
}

export default StepSetting
