import React, {Component} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import {deleteResource} from "../../redux/action";
import Table from "../../components/Table";
import {getColumnsWidth, getDefaultFiltered} from "../../utils/List"
import {TextFilter} from "../../components/List/index"
import Page from "../../components/Page/Page";
import {onDelete} from "../../utils/Alert";

class Providers extends Component {
    constructor(props) {
        super(props);

        this.frenchName = "fournisseurs";
        this.localName  = "providers";
        this.apiName    = 'providers';

        this.localNames     = ['name', 'phone', 'email', 'fax', 'address'];
        this.localNames     = this.localNames.map(name => this.localName + '-' + name);

        this.state = {
            selected: -1,
            columns: [
                {
                    Header: 'Nom',
                    accessor: 'name',
                    width: getColumnsWidth(this.localNames[0], 450),
                },
                {
                    Header: 'Téléphone',
                    accessor: 'phone',
                    width: getColumnsWidth(this.localNames[1], 200),
                },
                {
                    Header: 'Adresse mail',
                    accessor: 'email',
                    width: getColumnsWidth(this.localNames[2], 300),
                },
                {
                    Header: 'Fax',
                    accessor: 'fax',
                    width: getColumnsWidth(this.localNames[3], 300),
                },
                {
                    Header: 'Adresse',
                    accessor: 'address',
                    width: getColumnsWidth(this.localNames[4], 400),
                    filterable: false,
                    sortable: false,
                    Cell: ({row}) => (
                        <div>
                            {
                                row._original.address !== null ?
                                    <p>
                                        {row._original.address.number} {row._original.address.street},<br/>
                                        {row._original.address.postalCode} {row._original.address.city}
                                    </p>
                                    :
                                    ''
                            }
                        </div>
                    )
                },
                {
                    Header: 'Actions',
                    accessor: 'id',
                    sortable: false,
                    resizable: false,
                    width: 100,
                    Cell: ({ row }) => {
                        return (
                            <div className={"btn-flex"}>
                                <Link to={"/fournisseurs/" + row._original.id + "/modifier"}>
                                    <div className="btn-circle btn-warning" title={"Modifier"}>
                                        <i className="fa fa-edit"/>
                                    </div>
                                </Link>
                                <div className="btn-circle btn-danger" title={"Supprimer"}
                                     onClick={() => this.handleDelete(row._original.id)}>
                                    <i className="fa fa-trash"/>
                                </div>
                            </div>
                        )
                    }
                },
            ]
        }
    }

    componentDidMount() {
        this.state.columns.forEach((column, i) => {
            if (i < this.state.columns.length)
                column.Filter = ({filter, onChange}) => <TextFilter localName={this.localNames[i]} filter={filter} onChange={onChange}/>
        })
    }

    handleDelete = id => onDelete('', 'Etes-vous sûr de vouloir supprimer ce fournisseur ?', () => this.props.dispatch(deleteResource(this.apiName, id, this.props.rawToken)));

    selectRow = (id) => {
        this.setState({
            selected: id
        })
    };

    buttonsRight = () => (
        <Link to={"/fournisseurs/creer"}>
            <button className={"btn-circle add-resource"} title={"Ajouter"}>
                <i className={"fas fa-plus"} />
            </button>
        </Link>
    )

    render() {
        return (
            <Page siteTitle={"Fournisseurs"} title={"Gestion des fournisseurs"} headerButtons={this.buttonsRight()}>
                <Table
                    name={this.localName + "-list"}
                    data={this.props.providers}
                    columns={this.state.columns}
                    frenchName={this.frenchName}
                    localName={this.localName}
                    apiName={this.apiName}
                    defaultFiltered={getDefaultFiltered(this.localName, this.state.columns)}
                    defaultSorted={[
                        {
                            id: "name",
                            desc: false
                        }
                    ]}
                />
            </Page>
        )
    }
}

const mapStateToProps = ({apiReducer}) => {
    return {
        rawToken: apiReducer.rawToken,
        providers: apiReducer.providers
    }
};

export default connect(mapStateToProps)(Providers)
