import React, {Component} from 'react';
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";

import "../../css/components/tabs.scss";
import "../../css/pages/projects/projects.scss";
import {
    addChildToParent,
    deleteResource,
    getResources,
    updateResource
} from "../../redux/action";
import {formatDate, sortDate} from "../../utils/Date";
import ColorPickerList from "../../components/ColorPickerList";
import Table from "../../components/Table";
import {getColumnsWidth, getDefaultFiltered} from "../../utils/List";
import {isDefined, renderIfDefined} from "../../utils/Data";
import Page from "../../components/Page/Page";
import {TextFilter, SelectFilter, AutoSuggestFilter, FreeTextInput} from "../../components/List/index"
import {onDelete} from "../../utils/Alert"

class Projects extends Component {
    constructor(props) {
        super(props);

        this.frenchName = "projets";
        this.localName  = "project";
        this.apiName    = 'projects';

        this.localName2  = "projectsDelivered";
        this.apiName2    = 'projectsDelivered';

        this.localName3  = "projectsWaiting";
        this.apiName3    = 'projectsWaiting';

        this.localName4  = "projectsRevived";
        this.apiName4    = 'projectsRevived';

        this.localName5  = "projectsToBill";
        this.apiName5    = 'projectsToBill';

        this.localNames = ['number', 'free', 'free2', 'free3', 'deliveryDate', 'customer.code', 'customer.name', 'contacts', 'name', 'steps', 'steps.users', 'steps.planned'];
        this.localNames = this.localNames.map(name => this.localName + '-' + name);

        this.localNames2 = ['number', 'free', 'free2', 'free3', 'deliveryDate', 'customer.code', 'customer.name', 'contacts', 'name', 'steps', 'steps.users', 'steps.planned'];
        this.localNames2 = this.localNames2.map(name => this.localName2 + '-' + name);

        this.localNames3 = ['number', 'free', 'free2', 'free3', 'deliveryDate', 'customer.code', 'customer.name', 'contacts', 'name', 'steps', 'steps.users', 'steps.planned'];
        this.localNames3 = this.localNames3.map(name => this.localName3 + '-' + name);

        this.localNames4 = ['number', 'free', 'free2', 'free3', 'deliveryDate', 'customer.code', 'customer.name', 'contacts', 'name', 'steps', 'steps.users', 'steps.planned'];
        this.localNames4 = this.localNames4.map(name => this.localName4 + '-' + name);

        this.localNames5 = ['number', 'free', 'free2', 'free3', 'deliveryDate', 'customer.code', 'customer.name', 'contacts', 'name', 'steps', 'steps.users', 'steps.planned'];
        this.localNames5 = this.localNames5.map(name => this.localName5 + '-' + name);

        this.state = {
            rowOpenColor: null,
            displayColorPicker: false,
            type: '',
            tabIndex: isDefined(localStorage.getItem(this.localName + "-tab")) ? parseInt(localStorage.getItem(this.localName + "-tab")) : 0,
            columns: [
                {
                    Header: 'Dossier',
                    accessor: 'number',
                    width: getColumnsWidth(this.localNames[0], 70),
                    Cell: ({row}) => <div className={"project-number"}>{row._original.number}</div>
                },
                {
                    Header: 'Sem',
                    accessor: 'free',
                    width: getColumnsWidth(this.localNames[1], 60),
                    Cell: ({row}) => {
                        const id = row._original.id;
                        return (
                            <FreeTextInput
                                value={renderIfDefined(row._original.free)}
                                id={id}
                                update={(type, body) => {this.props.dispatch(updateResource('projects' + type, id, body, this.props.rawToken))}}
                            />
                            /*<input
                                key={id}
                                type={"text"}
                                name={"free-" + id}
                                value={renderIfDefined(row._original.free)}
                                onChange={(e) => this.onChangeFree(e, id)}
                                className={"project-free-input"}
                            />*/
                        )
                    }
                },
                {
                    Header: 'Sem2',
                    accessor: 'free2',
                    width: getColumnsWidth(this.localNames[2], 60),
                    Cell: ({row}) => {
                        const id = row._original.id;
                        return (
                            <FreeTextInput
                                value={renderIfDefined(row._original.free2)}
                                id={id}
                                number={2}
                                update={(type, body) => {this.props.dispatch(updateResource('projects' + type, id, body, this.props.rawToken))}}
                            />
                            /*<input
                                key={id}
                                type={"text"}
                                name={"free-" + id}
                                value={renderIfDefined(row._original.free2)}
                                onChange={(e) => this.onChangeFree(e, id, 2)}
                                className={"project-free-input"}
                            />*/
                            )
                    }
                },
                {
                    Header: 'Sem3',
                    accessor: 'free3',
                    width: getColumnsWidth(this.localNames[3], 60),
                    Cell: ({row}) => {
                        const id = row._original.id;

                        return (
                            <FreeTextInput
                                value={renderIfDefined(row._original.free3)}
                                id={id}
                                number={3}
                                update={(type, body) => {this.props.dispatch(updateResource('projects' + type, id, body, this.props.rawToken))}}
                            />
                            /*<input
                                key={id}
                                type={"text"}
                                name={"free-" + id}
                                value={renderIfDefined(row._original.free3)}
                                onChange={(e) => this.onChangeFree(e, id, 3)}
                                className={"project-free-input"}
                            />*/)
                    }
                },
                {
                    Header: 'Livraison',
                    accessor: 'deliveryDate',
                    width: getColumnsWidth(this.localNames[4], 100),
                    filterMethod: (filter, row) => formatDate(row._original.deliveryDate).toLowerCase().includes(filter.value.toLowerCase()),
                    Cell: ({row}) => isDefined(row._original.freeDeliveryDate) ? row._original.freeDeliveryDate.length > 0 ? row._original.freeDeliveryDate : formatDate(row._original.deliveryDate) : formatDate(row._original.deliveryDate)
                },
                {
                    Header: 'Code Client',
                    accessor: 'customer.code',
                    width: getColumnsWidth(this.localNames[5], 80),
                    Cell: ({row}) => row._original.customer !== null ? <Link to={"/clients/" + row._original.customer.id + "/modifier"}>{row._original.customer.code}</Link> : ''
                },
                {
                    Header: 'Client',
                    accessor: 'customer.name',
                    width: getColumnsWidth(this.localNames[6], 200),
                    Cell: ({row}) => row._original.customer !== null ? <Link to={"/clients/" + row._original.customer.id + "/modifier"}>{row._original.customer.name}</Link> : ''
                },
                {
                    Header: 'Contacts',
                    accessor: 'contacts',
                    width: getColumnsWidth(this.localNames[7], 150),
                    Cell: ({row}) => <div>{row._original.contacts.map((contact, i) => {
                        if(row._original.contacts.length > 1)
                            if(i < row._original.contacts.length - 1)
                                return contact.lastname + ', '
                            else
                                return contact.lastname
                        else
                            return contact.lastname
                    })}</div>
                },
                {
                    Header: 'Intitulé',
                    accessor: 'name',
                    width: getColumnsWidth(this.localNames[8], 300),
                },
                {
                    Header: 'Etapes',
                    accessor: 'steps',
                    width: getColumnsWidth(this.localNames[9], 230),
                    Filter: ({filter, onChange}) =>
                        <AutoSuggestFilter
                            localName={this.localNames[9]}
                            filter={filter}
                            onChange={onChange}
                            items={this.props.stepSettings.map(step => step.name)}
                        />,
                    Cell: ({row}) => {
                        const steps = row._original.steps.filter(step => step.finished === null && step.waiting === false && step.made === null);
                        return (sortDate(steps, "planned").map((step, i) => (
                            <li key={i}>
                                {step.name}
                            </li>
                        )))
                    }
                },
                {
                    Header: 'Par',
                    accessor: 'steps.users',
                    width: getColumnsWidth(this.localNames[10], 150),
                    Filter: ({filter, onChange}) => <SelectFilter localName={this.localNames[10]} filter={filter} onChange={onChange} options={this.props.users.map(user => user.firstname)} default={"Tous"}/>,
                    Cell: ({row}) => {
                        const steps = row._original.steps.filter(step => step.finished === null && step.waiting === false && step.made === null);
                        return (sortDate(steps, "planned").map((step, j) => {
                            const nbUsers = step.users.length;
                            return <li key={j}>
                                    {step.users.map((user, i) => (isDefined(user.firstname) ? user.firstname : '') + (nbUsers > 1 ? i + 1 === nbUsers ? '' : ', ' : ''))}
                                    {step.hours !== null ? ' (' + step.hours + ' h)' : ''}
                                </li>
                        }))
                    }
                },
                {
                    Header: 'Plannifié',
                    accessor: 'steps.planned',
                    width: getColumnsWidth(this.localNames[11], 90),
                    filterable: false,
                    sortable: false,
                    Cell: ({row}) => {
                        const steps = row._original.steps.filter(step => step.finished === null && step.waiting === false && step.made === null && step !== null);
                        return sortDate(steps, "planned").map((step, i) => (
                            <div key={i}>
                                {isDefined(step.planned) ? formatDate(step.planned) : 'N.P'}
                            </div>
                        ))
                    }
                },
                {
                    Header: 'Actions',
                    accessor: 'actions',
                    sortable: false,
                    resizable: false,
                    width: 200,
                    Filter: () => <input placeholder={"a"}/>,
                    Cell: ({ row }) => (
                        <div className={"btn-flex"}>
                            <div className="btn-circle btn-color" title={"Mettre en couleur"} onClick={() => this.handleColor(row._original.id)}>
                                <i className="fa fa-tint"/>
                            </div>
                            <Link to={"/projets/" + row._original.id}>
                                <div className="btn-circle btn-primary" title={"Voir"}>
                                    <i className="fa fa-eye"/>
                                </div>
                            </Link>
                            <Link to={"/projets/" + row._original.id + "/modifier"}>
                                <div className="btn-circle btn-warning" title={"Modifier"}>
                                    <i className="fa fa-edit"/>
                                </div>
                            </Link>
                            <div className="btn-circle btn-danger" title={"Supprimer"}
                                 onClick={() => this.handleDelete(row._original.id)}>
                                <i className="fa fa-trash"/>
                            </div>
                        </div>
                    )
                },
            ],
            columns2: [
                {
                    Header: 'Dossier',
                    accessor: 'number',
                    width: getColumnsWidth(this.localNames2[0], 70),
                    Cell: ({row}) => <div className={"project-number"}>{row._original.number}</div>
                },
                {
                    Header: 'Sem',
                    accessor: 'free',
                    width: getColumnsWidth(this.localNames2[1], 60),
                    Cell: ({row}) => {
                        const id = row._original.id;
                        return (
                            <FreeTextInput
                                value={renderIfDefined(row._original.free)}
                                id={id}
                                update={(type, body) => {this.props.dispatch(updateResource('projects' + "Delivered", id, body, this.props.rawToken))}}
                            />
                            /*<input
                                key={id}
                                type={"text"}
                                name={"free-" + id}
                                value={renderIfDefined(row._original.free)}
                                onChange={(e) => this.onChangeFree(e, id, 1, "Delivered")}
                                className={"project-free-input"}
                            />*/
                            )
                    }
                },
                {
                    Header: 'Sem2',
                    accessor: 'free2',
                    width: getColumnsWidth(this.localNames2[2], 60),
                    Cell: ({row}) => {
                        const id = row._original.id;
                        return (
                            <FreeTextInput
                                value={renderIfDefined(row._original.free2)}
                                id={id}
                                number={2}
                                update={(type, body) => {this.props.dispatch(updateResource('projects' + "Delivered", id, body, this.props.rawToken))}}
                            />
                            /*<input
                                key={id}
                                type={"text"}
                                name={"free-" + id}
                                value={renderIfDefined(row._original.free2)}
                                onChange={(e) => this.onChangeFree(e, id, 2, "Delivered")}
                                className={"project-free-input"}
                            />*/)
                    }
                },
                {
                    Header: 'Sem3',
                    accessor: 'free3',
                    width: getColumnsWidth(this.localNames2[3], 60),
                    Cell: ({row}) => {
                        const id = row._original.id;
                        return (
                            <FreeTextInput
                                value={renderIfDefined(row._original.free3)}
                                id={id}
                                number={3}
                                update={(type, body) => {this.props.dispatch(updateResource('projects' + "Delivered", id, body, this.props.rawToken))}}
                            />
                            /*<input
                                key={id}
                                type={"text"}
                                name={"free-" + id}
                                value={renderIfDefined(row._original.free3)}
                                onChange={(e) => this.onChangeFree(e, id, 3, "Delivered")}
                                className={"project-free-input"}
                            />*/)
                    }
                },
                {
                    Header: 'Livraison',
                    accessor: 'deliveryDate',
                    width: getColumnsWidth(this.localNames2[4], 100),
                    filterMethod: (filter, row) => formatDate(row._original.deliveryDate).toLowerCase().includes(filter.value.toLowerCase()),
                    Cell: ({row}) => isDefined(row._original.freeDeliveryDate) ? row._original.freeDeliveryDate.length > 0 ? row._original.freeDeliveryDate : formatDate(row._original.deliveryDate) : formatDate(row._original.deliveryDate)
                },
                {
                    Header: 'Code Client',
                    accessor: 'customer.code',
                    width: getColumnsWidth(this.localNames2[5], 80),
                    Cell: ({row}) => row._original.customer !== null ? <Link to={"/clients/" + row._original.customer.id + "/modifier"}>{row._original.customer.code}</Link> : ''
                },
                {
                    Header: 'Client',
                    accessor: 'customer.name',
                    width: getColumnsWidth(this.localNames2[6], 200),
                    Cell: ({row}) => row._original.customer !== null ? <Link to={"/clients/" + row._original.customer.id + "/modifier"}>{row._original.customer.name}</Link> : ''
                },
                {
                    Header: 'Contacts',
                    accessor: 'contacts',
                    width: getColumnsWidth(this.localNames2[7], 150),
                    Cell: ({row}) => <div>{row._original.contacts.map((contact, i) => {
                        if(row._original.contacts.length > 1)
                            if(i < row._original.contacts.length - 1)
                                return contact.lastname + ', '
                            else
                                return contact.lastname
                        else
                            return contact.lastname
                    })}</div>
                },
                {
                    Header: 'Intitulé',
                    accessor: 'name',
                    width: getColumnsWidth(this.localNames2[8], 300),
                },
                {
                    Header: 'Etapes',
                    accessor: 'steps',
                    width: getColumnsWidth(this.localNames2[9], 230),
                    Filter: ({filter, onChange}) =>
                        <AutoSuggestFilter
                            localName={this.localNames2[9]}
                            filter={filter}
                            onChange={onChange}
                            items={this.props.stepSettings.map(step => step.name)}
                        />,
                    Cell: ({row}) => {
                        const steps = row._original.steps.filter(step => step.finished === null && step.waiting === false && step.made === null);
                        return (sortDate(steps, "planned").map((step, i) => (
                            <li key={i}>
                                {step.name}
                            </li>
                        )))
                    }
                },
                {
                    Header: 'Par',
                    accessor: 'steps.users',
                    width: getColumnsWidth(this.localNames2[10], 150),
                    Filter: ({filter, onChange}) => <SelectFilter localName={this.localNames2[10]} filter={filter} onChange={onChange} options={this.props.users.map(user => user.firstname)} default={"Tous"}/>,
                    Cell: ({row}) => {
                        const steps = row._original.steps.filter(step => step.finished === null && step.waiting === false && step.made === null);
                        return (sortDate(steps, "planned").map((step, j) => {
                            const nbUsers = step.users.length;
                            return <li key={j}>
                                {step.users.map((user, i) => (isDefined(user.firstname) ? user.firstname : '') + (nbUsers > 1 ? i + 1 === nbUsers ? '' : ', ' : ''))}
                                {step.hours !== null ? ' (' + step.hours + ' h)' : ''}
                            </li>
                        }))
                    }
                },
                {
                    Header: 'Plannifié',
                    accessor: 'steps.planned',
                    width: getColumnsWidth(this.localNames2[11], 90),
                    filterable: false,
                    sortable: false,
                    Cell: ({row}) => {
                        const steps = row._original.steps.filter(step => step.finished === null && step.waiting === false && step.made === null);
                        return (sortDate(steps, "planned").map((step, i) => (
                            <div key={i}>
                                {isDefined(step.planned) ? formatDate(step.planned) : 'N.P'}
                            </div>
                        )))
                    }
                },
                {
                    Header: 'Actions',
                    accessor: 'actions',
                    sortable: false,
                    resizable: false,
                    width: 200,
                    Cell: ({ row }) => (
                        <div className={"btn-flex"}>
                            <div className="btn-circle btn-color" title={"Mettre en couleur"} onClick={() => this.handleColor(row._original.id, 'Delivered')}>
                                <i className="fa fa-tint"/>
                            </div>
                            <Link to={"/projets/" + row._original.id}>
                                <div className="btn-circle btn-primary" title={"Voir"}>
                                    <i className="fa fa-eye"/>
                                </div>
                            </Link>
                            <Link to={"/projets/" + row._original.id + "/modifier"}>
                                <div className="btn-circle btn-warning" title={"Modifier"}>
                                    <i className="fa fa-edit"/>
                                </div>
                            </Link>
                            <div className="btn-circle btn-danger" title={"Supprimer"}
                                 onClick={() => this.handleDelete(row._original.id)}>
                                <i className="fa fa-trash"/>
                            </div>
                        </div>
                    )
                },
            ],
            columns3: [
                {
                    Header: 'Dossier',
                    accessor: 'number',
                    width: getColumnsWidth(this.localNames3[0], 70),
                    Cell: ({row}) => <div className={"project-number"}>{row._original.number}</div>
                },
                {
                    Header: 'Sem',
                    accessor: 'free',
                    width: getColumnsWidth(this.localNames3[1], 60),
                    Cell: ({row}) => {
                        const id = row._original.id;
                        return (
                            <FreeTextInput
                                value={renderIfDefined(row._original.free)}
                                id={id}
                                update={(type, body) => {this.props.dispatch(updateResource('projects' + "Waiting", id, body, this.props.rawToken))}}
                            />
                            /*<input
                                key={id}
                                type={"text"}
                                name={"free-" + id}
                                value={renderIfDefined(row._original.free)}
                                onChange={(e) => this.onChangeFree(e, id, 1, "Waiting")}
                                className={"project-free-input"}
                            />*/)
                    }
                },
                {
                    Header: 'Sem2',
                    accessor: 'free2',
                    width: getColumnsWidth(this.localNames3[2], 60),
                    Cell: ({row}) => {
                        const id = row._original.id;
                        return (
                            <FreeTextInput
                                value={renderIfDefined(row._original.free2)}
                                id={id}
                                number={2}
                                update={(type, body) => {this.props.dispatch(updateResource('projects' + "Waiting", id, body, this.props.rawToken))}}
                            />
                           /*<input
                                key={id}
                                type={"text"}
                                name={"free-" + id}
                                value={renderIfDefined(row._original.free2)}
                                onChange={(e) => this.onChangeFree(e, id, 2, "Waiting")}
                                className={"project-free-input"}
                            />*/)
                    }
                },
                {
                    Header: 'Sem3',
                    accessor: 'free3',
                    width: getColumnsWidth(this.localNames3[3], 60),
                    Cell: ({row}) => {
                        const id = row._original.id;
                        return (
                            <FreeTextInput
                                value={renderIfDefined(row._original.free3)}
                                id={id}
                                number={3}
                                update={(type, body) => {this.props.dispatch(updateResource('projects' + "Waiting", id, body, this.props.rawToken))}}
                            />
                            /*<input
                                key={id}
                                type={"text"}
                                name={"free-" + id}
                                value={renderIfDefined(row._original.free3)}
                                onChange={(e) => this.onChangeFree(e, id, 3, "Waiting")}
                                className={"project-free-input"}
                            />*/)
                    }
                },
                {
                    Header: 'Livraison',
                    accessor: 'deliveryDate',
                    width: getColumnsWidth(this.localNames3[4], 100),
                    filterMethod: (filter, row) => formatDate(row._original.deliveryDate).toLowerCase().includes(filter.value.toLowerCase()),
                    Cell: ({row}) => isDefined(row._original.freeDeliveryDate) ? row._original.freeDeliveryDate.length > 0 ? row._original.freeDeliveryDate : formatDate(row._original.deliveryDate) : formatDate(row._original.deliveryDate)
                },
                {
                    Header: 'Code Client',
                    accessor: 'customer.code',
                    width: getColumnsWidth(this.localNames3[5], 80),
                    Cell: ({row}) => row._original.customer !== null ? <Link to={"/clients/" + row._original.customer.id + "/modifier"}>{row._original.customer.code}</Link> : ''
                },
                {
                    Header: 'Client',
                    accessor: 'customer.name',
                    width: getColumnsWidth(this.localNames3[6], 200),
                    Cell: ({row}) => row._original.customer !== null ? <Link to={"/clients/" + row._original.customer.id + "/modifier"}>{row._original.customer.name}</Link> : ''
                },
                {
                    Header: 'Contacts',
                    accessor: 'contacts',
                    width: getColumnsWidth(this.localNames3[7], 150),
                    Cell: ({row}) => <div>{row._original.contacts.map((contact, i) => {
                        if(row._original.contacts.length > 1)
                            if(i < row._original.contacts.length - 1)
                                return contact.lastname + ', '
                            else
                                return contact.lastname
                        else
                            return contact.lastname
                    })}</div>
                },
                {
                    Header: 'Intitulé',
                    accessor: 'name',
                    width: getColumnsWidth(this.localNames3[8], 300),
                },
                {
                    Header: 'Etapes',
                    accessor: 'steps',
                    width: getColumnsWidth(this.localNames3[9], 230),
                    Filter: ({filter, onChange}) =>
                        <AutoSuggestFilter
                            localName={this.localNames3[9]}
                            filter={filter}
                            onChange={onChange}
                            items={this.props.stepSettings.map(step => step.name)}
                        />,
                    Cell: ({row}) => {
                        const steps = row._original.steps.filter(step => step.finished === null && step.waiting === false && step.made === null);
                        return (sortDate(steps, "planned").map((step, i) => (
                            <li key={i}>
                                {step.name}
                            </li>
                        )))
                    }
                },
                {
                    Header: 'Par',
                    accessor: 'steps.users',
                    width: getColumnsWidth(this.localNames3[10], 150),
                    Filter: ({filter, onChange}) => <SelectFilter localName={this.localNames3[10]} filter={filter} onChange={onChange} options={this.props.users.map(user => user.firstname)} default={"Tous"}/>,
                    Cell: ({row}) => {
                        const steps = row._original.steps.filter(step => step.finished === null && step.waiting === false && step.made === null);
                        return (sortDate(steps, "planned").map((step, j) => {
                            const nbUsers = step.users.length;
                            return <li key={j}>
                                {step.users.map((user, i) => (isDefined(user.firstname) ? user.firstname : '') + (nbUsers > 1 ? i + 1 === nbUsers ? '' : ', ' : ''))}
                                {step.hours !== null ? ' (' + step.hours + ' h)' : ''}
                            </li>
                        }))
                    }
                },
                {
                    Header: 'Plannifié',
                    accessor: 'steps.planned',
                    width: getColumnsWidth(this.localNames3[11], 90),
                    filterable: false,
                    sortable: false,
                    Cell: ({row}) => {
                        const steps = row._original.steps.filter(step => step.finished === null && step.waiting === false && step.made === null);
                        return (sortDate(steps, "planned").map((step, i) => (
                            <div key={i}>
                                {isDefined(step.planned) ? formatDate(step.planned) : 'N.P'}
                            </div>
                        )))
                    }
                },
                {
                    Header: 'Actions',
                    accessor: 'actions',
                    sortable: false,
                    resizable: false,
                    width: 200,
                    Cell: ({ row }) => (
                        <div className={"btn-flex"}>
                            <div className="btn-circle btn-color" title={"Mettre en couleur"} onClick={() => this.handleColor(row._original.id, 'Waiting')}>
                                <i className="fa fa-tint"/>
                            </div>
                            <Link to={"/projets/" + row._original.id}>
                                <div className="btn-circle btn-primary" title={"Voir"}>
                                    <i className="fa fa-eye"/>
                                </div>
                            </Link>
                            <Link to={"/projets/" + row._original.id + "/modifier"}>
                                <div className="btn-circle btn-warning" title={"Modifier"}>
                                    <i className="fa fa-edit"/>
                                </div>
                            </Link>
                            <div className="btn-circle btn-danger" title={"Supprimer"}
                                 onClick={() => this.handleDelete(row._original.id)}>
                                <i className="fa fa-trash"/>
                            </div>
                        </div>
                    )
                },
            ],
            columns4: [
                {
                    Header: 'Dossier',
                    accessor: 'number',
                    width: getColumnsWidth(this.localNames4[0], 70),
                    Cell: ({row}) => <div className={"project-number"}>{row._original.number}</div>
                },
                {
                    Header: 'Sem',
                    accessor: 'free',
                    width: getColumnsWidth(this.localNames4[1], 60),
                    Cell: ({row}) => {
                        const id = row._original.id;
                        return (
                            <FreeTextInput
                                value={renderIfDefined(row._original.free)}
                                id={id}
                                update={(type, body) => {this.props.dispatch(updateResource('projects' + "Revived", id, body, this.props.rawToken))}}
                            />
                            /*<input
                                key={id}
                                type={"text"}
                                name={"free-" + id}
                                value={renderIfDefined(row._original.free)}
                                onChange={(e) => this.onChangeFree(e, id, 1, "Revived")}
                                className={"project-free-input"}
                            />*/)
                    }
                },
                {
                    Header: 'Sem2',
                    accessor: 'free2',
                    width: getColumnsWidth(this.localNames4[2], 60),
                    Cell: ({row}) => {
                        const id = row._original.id;
                        return (
                            <FreeTextInput
                                value={renderIfDefined(row._original.free2)}
                                id={id}
                                number={2}
                                update={(type, body) => {this.props.dispatch(updateResource('projects' + "Revived", id, body, this.props.rawToken))}}
                            />
                            /*<input
                                key={id}
                                type={"text"}
                                name={"free-" + id}
                                value={renderIfDefined(row._original.free2)}
                                onChange={(e) => this.onChangeFree(e, id, 2, "Revived")}
                                className={"project-free-input"}
                            />*/)
                    }
                },
                {
                    Header: 'Sem3',
                    accessor: 'free3',
                    width: getColumnsWidth(this.localNames4[3], 60),
                    Cell: ({row}) => {
                        const id = row._original.id;
                        return (
                            <FreeTextInput
                                value={renderIfDefined(row._original.free3)}
                                id={id}
                                number={3}
                                update={(type, body) => {this.props.dispatch(updateResource('projects' + "Revived", id, body, this.props.rawToken))}}
                            />
                            /*<input
                                key={id}
                                type={"text"}
                                name={"free-" + id}
                                value={renderIfDefined(row._original.free3)}
                                onChange={(e) => this.onChangeFree(e, id, 3, "Revived")}
                                className={"project-free-input"}
                            />*/)
                    }
                },
                {
                    Header: 'Livraison',
                    accessor: 'deliveryDate',
                    width: getColumnsWidth(this.localNames4[4], 100),
                    filterMethod: (filter, row) => formatDate(row._original.deliveryDate).toLowerCase().includes(filter.value.toLowerCase()),
                    Cell: ({row}) => isDefined(row._original.freeDeliveryDate) ? row._original.freeDeliveryDate.length > 0 ? row._original.freeDeliveryDate : formatDate(row._original.deliveryDate) : formatDate(row._original.deliveryDate)
                },
                {
                    Header: 'Code Client',
                    accessor: 'customer.code',
                    width: getColumnsWidth(this.localNames4[5], 80),
                    Cell: ({row}) => row._original.customer !== null ? <Link to={"/clients/" + row._original.customer.id + "/modifier"}>{row._original.customer.code}</Link> : ''
                },
                {
                    Header: 'Client',
                    accessor: 'customer.name',
                    width: getColumnsWidth(this.localNames4[6], 200),
                    Cell: ({row}) => row._original.customer !== null ? <Link to={"/clients/" + row._original.customer.id + "/modifier"}>{row._original.customer.name}</Link> : ''
                },
                {
                    Header: 'Contacts',
                    accessor: 'contacts',
                    width: getColumnsWidth(this.localNames4[7], 150),
                    Cell: ({row}) => <div>{row._original.contacts.map((contact, i) => {
                        if(row._original.contacts.length > 1)
                            if(i < row._original.contacts.length - 1)
                                return contact.lastname + ', '
                            else
                                return contact.lastname
                        else
                            return contact.lastname
                    })}</div>
                },
                {
                    Header: 'Intitulé',
                    accessor: 'name',
                    width: getColumnsWidth(this.localNames4[8], 300),
                },
                {
                    Header: 'Etapes',
                    accessor: 'steps',
                    width: getColumnsWidth(this.localNames4[9], 230),
                    Filter: ({filter, onChange}) =>
                        <AutoSuggestFilter
                            localName={this.localNames4[9]}
                            filter={filter}
                            onChange={onChange}
                            items={this.props.stepSettings.map(step => step.name)}
                        />,
                    Cell: ({row}) => {
                        const steps = row._original.steps.filter(step => step.finished === null && step.waiting === false && step.made === null);
                        return (sortDate(steps, "planned").map((step, i) => (
                            <li key={i}>
                                {step.name}
                            </li>
                        )))
                    }
                },
                {
                    Header: 'Par',
                    accessor: 'steps.users',
                    width: getColumnsWidth(this.localNames4[10], 150),
                    Filter: ({filter, onChange}) => <SelectFilter localName={this.localNames4[10]} filter={filter} onChange={onChange} options={this.props.users.map(user => user.firstname)} default={"Tous"}/>,
                    Cell: ({row}) => {
                        const steps = row._original.steps.filter(step => step.finished === null && step.waiting === false && step.made === null);
                        return (sortDate(steps, "planned").map((step, j) => {
                            const nbUsers = step.users.length;
                            return <li key={j}>
                                {step.users.map((user, i) => (isDefined(user.firstname) ? user.firstname : '') + (nbUsers > 1 ? i + 1 === nbUsers ? '' : ', ' : ''))}
                                {step.hours !== null ? ' (' + step.hours + ' h)' : ''}
                            </li>
                        }))
                    }
                },
                {
                    Header: 'Plannifié',
                    accessor: 'steps.planned',
                    width: getColumnsWidth(this.localNames4[11], 90),
                    filterable: false,
                    sortable: false,
                    Cell: ({row}) => {
                        const steps = row._original.steps.filter(step => step.finished === null && step.waiting === false && step.made === null);
                        return (sortDate(steps, "planned").map((step, i) => (
                            <div key={i}>
                                {isDefined(step.planned) ? formatDate(step.planned) : 'N.P'}
                            </div>
                        )))
                    }
                },
                {
                    Header: 'Actions',
                    accessor: 'actions',
                    sortable: false,
                    resizable: false,
                    width: 200,
                    Cell: ({ row }) => (
                        <div className={"btn-flex"}>
                            <div className="btn-circle btn-color" title={"Mettre en couleur"} onClick={() => this.handleColor(row._original.id, 'Revived')}>
                                <i className="fa fa-tint"/>
                            </div>
                            <Link to={"/projets/" + row._original.id}>
                                <div className="btn-circle btn-primary" title={"Voir"}>
                                    <i className="fa fa-eye"/>
                                </div>
                            </Link>
                            <Link to={"/projets/" + row._original.id + "/modifier"}>
                                <div className="btn-circle btn-warning" title={"Modifier"}>
                                    <i className="fa fa-edit"/>
                                </div>
                            </Link>
                            <div className="btn-circle btn-danger" title={"Supprimer"}
                                 onClick={() => this.handleDelete(row._original.id)}>
                                <i className="fa fa-trash"/>
                            </div>
                        </div>
                    )
                },
            ],
            columns5: [
                {
                    Header: 'Dossier',
                    accessor: 'number',
                    width: getColumnsWidth(this.localNames5[0], 70),
                    Cell: ({row}) => <div className={"project-number"}>{row._original.number}</div>
                },
                {
                    Header: 'Sem',
                    accessor: 'free',
                    width: getColumnsWidth(this.localNames5[1], 60),
                    Cell: ({row}) => {
                        const id = row._original.id;
                        return (
                            <FreeTextInput
                                value={renderIfDefined(row._original.free)}
                                id={id}
                                update={(type, body) => {this.props.dispatch(updateResource('projects' + "ToBill", id, body, this.props.rawToken))}}
                            />
                            /*<input
                                key={id}
                                type={"text"}
                                name={"free-" + id}
                                value={renderIfDefined(row._original.free)}
                                onChange={(e) => this.onChangeFree(e, id, 1, "ToBill")}
                                className={"project-free-input"}
                            />*/)
                    }
                },
                {
                    Header: 'Sem2',
                    accessor: 'free2',
                    width: getColumnsWidth(this.localNames5[2], 60),
                    Cell: ({row}) => {
                        const id = row._original.id;
                        return (
                            <FreeTextInput
                                value={renderIfDefined(row._original.free2)}
                                id={id}
                                number={2}
                                update={(type, body) => {this.props.dispatch(updateResource('projects' + "ToBill", id, body, this.props.rawToken))}}
                            />
                            /*<input
                                key={id}
                                type={"text"}
                                name={"free-" + id}
                                value={renderIfDefined(row._original.free2)}
                                onChange={(e) => this.onChangeFree(e, id, 2, "ToBill")}
                                className={"project-free-input"}
                            />*/)
                    }
                },
                {
                    Header: 'Sem3',
                    accessor: 'free3',
                    width: getColumnsWidth(this.localNames5[3], 60),
                    Cell: ({row}) => {
                        const id = row._original.id;
                        return (
                            <FreeTextInput
                                value={renderIfDefined(row._original.free3)}
                                id={id}
                                number={3}
                                update={(type, body) => {this.props.dispatch(updateResource('projects' + "ToBill", id, body, this.props.rawToken))}}
                            />
                            /*<input
                                key={id}
                                type={"text"}
                                name={"free-" + id}
                                value={renderIfDefined(row._original.free3)}
                                onChange={(e) => this.onChangeFree(e, id, 3, "ToBill")}
                                className={"project-free-input"}
                            />*/)
                    }
                },
                {
                    Header: 'Livraison',
                    accessor: 'deliveryDate',
                    width: getColumnsWidth(this.localNames5[4], 100),
                    filterMethod: (filter, row) => formatDate(row._original.deliveryDate).toLowerCase().includes(filter.value.toLowerCase()),
                    Cell: ({row}) => isDefined(row._original.freeDeliveryDate) ? row._original.freeDeliveryDate.length > 0 ? row._original.freeDeliveryDate : formatDate(row._original.deliveryDate) : formatDate(row._original.deliveryDate)
                },
                {
                    Header: 'Code Client',
                    accessor: 'customer.code',
                    width: getColumnsWidth(this.localNames5[5], 80),
                    Cell: ({row}) => row._original.customer !== null ? <Link to={"/clients/" + row._original.customer.id + "/modifier"}>{row._original.customer.code}</Link> : ''
                },
                {
                    Header: 'Client',
                    accessor: 'customer.name',
                    width: getColumnsWidth(this.localNames5[6], 200),
                    Cell: ({row}) => row._original.customer !== null ? <Link to={"/clients/" + row._original.customer.id + "/modifier"}>{row._original.customer.name}</Link> : ''
                },
                {
                    Header: 'Contacts',
                    accessor: 'contacts',
                    width: getColumnsWidth(this.localNames5[7], 150),
                    Cell: ({row}) => <div>{row._original.contacts.map((contact, i) => {
                        if(row._original.contacts.length > 1)
                            if(i < row._original.contacts.length - 1)
                                return contact.lastname + ', '
                            else
                                return contact.lastname
                        else
                            return contact.lastname
                    })}</div>
                },
                {
                    Header: 'Intitulé',
                    accessor: 'name',
                    width: getColumnsWidth(this.localNames5[8], 300),
                },
                {
                    Header: 'Etapes',
                    accessor: 'steps',
                    width: getColumnsWidth(this.localNames5[9], 230),
                    Filter: ({filter, onChange}) =>
                        <AutoSuggestFilter
                            localName={this.localNames5[9]}
                            filter={filter}
                            onChange={onChange}
                            items={this.props.stepSettings.map(step => step.name)}
                        />,
                    Cell: ({row}) => {
                        const steps = row._original.steps.filter(step => step.finished === null && step.waiting === false && step.made === null);
                        return (sortDate(steps, "planned").map((step, i) => (
                            <li key={i}>
                                {step.name}
                            </li>
                        )))
                    }
                },
                {
                    Header: 'Par',
                    accessor: 'steps.users',
                    width: getColumnsWidth(this.localNames5[10], 150),
                    Filter: ({filter, onChange}) => <SelectFilter localName={this.localNames5[10]} filter={filter} onChange={onChange} options={this.props.users.map(user => user.firstname)} default={"Tous"}/>,
                    Cell: ({row}) => {
                        const steps = row._original.steps.filter(step => step.finished === null && step.waiting === false && step.made === null);
                        return (sortDate(steps, "planned").map((step, j) => {
                            const nbUsers = step.users.length;
                            return <li key={j}>
                                {step.users.map((user, i) => (isDefined(user.firstname) ? user.firstname : '') + (nbUsers > 1 ? i + 1 === nbUsers ? '' : ', ' : ''))}
                                {step.hours !== null ? ' (' + step.hours + ' h)' : ''}
                            </li>
                        }))
                    }
                },
                {
                    Header: 'Plannifié',
                    accessor: 'steps.planned',
                    width: getColumnsWidth(this.localNames5[11], 90),
                    filterable: false,
                    sortable: false,
                    Cell: ({row}) => {
                        const steps = row._original.steps.filter(step => step.finished === null && step.waiting === false && step.made === null);
                        return (sortDate(steps, "planned").map((step, i) => (
                            <div key={i}>
                                {isDefined(step.planned) ? formatDate(step.planned) : 'N.P'}
                            </div>
                        )))
                    }
                },
                {
                    Header: 'Actions',
                    accessor: 'actions',
                    sortable: false,
                    resizable: false,
                    width: 200,
                    Cell: ({ row }) => (
                        <div className={"btn-flex"}>
                            <div className="btn-circle btn-color" title={"Mettre en couleur"} onClick={() => this.handleColor(row._original.id, 'ToBill')}>
                                <i className="fa fa-tint"/>
                            </div>
                            <Link to={"/projets/" + row._original.id}>
                                <div className="btn-circle btn-primary" title={"Voir"}>
                                    <i className="fa fa-eye"/>
                                </div>
                            </Link>
                            <Link to={"/projets/" + row._original.id + "/modifier"}>
                                <div className="btn-circle btn-warning" title={"Modifier"}>
                                    <i className="fa fa-edit"/>
                                </div>
                            </Link>
                            <div className="btn-circle btn-danger" title={"Supprimer"}
                                 onClick={() => this.handleDelete(row._original.id)}>
                                <i className="fa fa-trash"/>
                            </div>
                        </div>
                    )
                },
            ],
        }
    }

    componentDidMount() {
        this.props.dispatch(getResources('users', this.props.rawToken))
        this.props.dispatch(getResources('colors', this.props.rawToken))
        this.props.dispatch(getResources('stepSettings', this.props.rawToken))

        this.state.columns.forEach((column, i) => {
            if(i < this.state.columns.length && i !== 9 && i !== 10)
                column.Filter = ({filter, onChange}) => <TextFilter localName={this.localNames[i]} filter={filter} onChange={onChange}/>;
        })
        this.state.columns2.forEach((column, i) => {
            if(i < this.state.columns2.length && i !== 9 && i !== 10)
                column.Filter = ({filter, onChange}) => <TextFilter localName={this.localNames2[i]} filter={filter} onChange={onChange}/>;
        })
        this.state.columns3.forEach((column, i) => {
            if(i < this.state.columns3.length && i !== 9 && i !== 10)
                column.Filter = ({filter, onChange}) => <TextFilter localName={this.localNames3[i]} filter={filter} onChange={onChange}/>;
        })
        this.state.columns4.forEach((column, i) => {
            if(i < this.state.columns4.length && i !== 9 && i !== 10)
                column.Filter = ({filter, onChange}) => <TextFilter localName={this.localNames4[i]} filter={filter} onChange={onChange}/>;
        })
        this.state.columns5.forEach((column, i) => {
            if(i < this.state.columns5.length && i !== 9 && i !== 10)
                column.Filter = ({filter, onChange}) => <TextFilter localName={this.localNames5[i]} filter={filter} onChange={onChange}/>;
        })

        this.goToProject();
    }

    goToProject = () => {
        const location  = window.location.href
        const url       = new URL(location)
        const number    = url.searchParams.get('num')

        if(number !== null) {
            const numbers = document.getElementsByClassName("project-number")
            let elProject = null

            for(let num of numbers) {
                if(num.innerText === number) {
                    elProject = num
                    break
                }
            }

            if(isDefined(elProject)) {
                const el = document.querySelector('.rt-tbody');
                if(isDefined(el)) {
                    localStorage.removeItem('project-list')
                    localStorage.setItem('project-rowSelected', elProject.innerText)
                    el.scrollTop = elProject.offsetTop - 100
                }
            }
        }
    }

    handleColor = (id = null, type = '') => {
        this.setState(state => {
            return {
                rowOpenColor: id,
                displayColorPicker: !state.displayColorPicker,
                type: type,
            }
        })
    };

    selectColor = (color = null) => {
        const colorApi = this.props.colors.find(item => item.hexa === color.hex);
        if(typeof colorApi !== "undefined")
            this.props.dispatch(addChildToParent(this.state.rowOpenColor, colorApi.id, 'projects' + this.state.type, 'colors', this.props.rawToken))
        this.handleColor(null, this.state.type)
    };

    onChangeFree = (e, id, number = 1, type = "") => {
        e.preventDefault();

        const value = e.target.value;
        let body    = {free: value}

        if(number === 2)
            body = {free2: value}
        else if(number === 3)
            body = {free3: value}

        this.props.dispatch(updateResource('projects' + type, id, body, this.props.rawToken))
    };

    handleDelete = (id) => onDelete('', 'Etes-vous sûr de vouloir supprimer ce projet ?', () => this.props.dispatch(deleteResource('projects', id, this.props.rawToken)));

    selectTab = index => {
        this.setState({ tabIndex : index });
        localStorage.setItem(this.localName + '-tab', index);
    };

    buttonsRight = () => (
        <Link to={"/projets/creer"}>
            <button className={"btn-circle add-resource"} title={"Ajouter"}>
                <i className={"fas fa-plus"} />
            </button>
        </Link>
    )

    sortColor = () => this.props.colors.sort((c, c2) => {
        if(c.name > c2.name)
            return 1
        else if(c.name < c2.name)
            return -1
        else
            return 0
    })

    render() {
        return (
            <Page siteTitle={"Projets"} title={"Gestion des projets"} headerButtons={this.buttonsRight()} className={"projects"}>
                <ColorPickerList
                    display={this.state.displayColorPicker}
                    handleClose={() => this.handleColor()}
                    colors={this.sortColor().map(color => color.hexa)}
                    handleChange={(color) => this.selectColor(color)}
                />

                <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.selectTab(tabIndex)}>
                    <TabList>
                        <Tab>Projets en cours</Tab>
                        <Tab>Projets cloturés</Tab>
                        <Tab>Dossiers en attente</Tab>
                        <Tab>Devis à relancer</Tab>
                        <Tab>A facturer</Tab>
                    </TabList>

                    <TabPanel>
                        <Table
                            name={this.localName + "-list"}
                            data={this.props.projects}
                            columns={this.state.columns}
                            frenchName={this.frenchName}
                            localName={this.localName}
                            apiName={this.apiName}
                            defaultFiltered={getDefaultFiltered(this.localName, this.state.columns)}
                            defaultSorted={[
                                {
                                    id: "number",
                                    desc: true
                                }
                            ]}
                        />
                    </TabPanel>
                    <TabPanel>
                        <Table
                            name={"projectsFinished-list"}
                            data={this.props.projectsDelivered}
                            columns={this.state.columns2}
                            frenchName={this.frenchName}
                            localName={this.localName2}
                            apiName={this.apiName2}
                            defaultFiltered={getDefaultFiltered(this.localName2, this.state.columns2)}
                            defaultSorted={[
                                {
                                    id: "number",
                                    desc: true
                                }
                            ]}
                        />
                    </TabPanel>
                    <TabPanel>
                        <Table
                            name={"projectsWaiting-list"}
                            data={this.props.projectsWaiting}
                            columns={this.state.columns3}
                            frenchName={this.frenchName}
                            localName={this.localName3}
                            apiName={this.apiName3}
                            defaultFiltered={getDefaultFiltered(this.localName3, this.state.columns3)}
                            defaultSorted={[
                                {
                                    id: "number",
                                    desc: true
                                }
                            ]}
                        />
                    </TabPanel>
                    <TabPanel>
                        <Table
                            name={"projectsQuotations-list"}
                            data={this.props.projectsRevived}
                            columns={this.state.columns4}
                            frenchName={this.frenchName}
                            localName={this.localName4}
                            apiName={this.apiName4}
                            defaultFiltered={getDefaultFiltered(this.localName4, this.state.columns4)}
                            defaultSorted={[
                                {
                                    id: "number",
                                    desc: true
                                }
                            ]}
                        />
                    </TabPanel>
                    <TabPanel>
                        <Table
                            name={"projectsToBill-list"}
                            data={this.props.projectsToBill}
                            columns={this.state.columns5}
                            frenchName={this.frenchName}
                            localName={this.localName5}
                            apiName={this.apiName5}
                            defaultFiltered={getDefaultFiltered(this.localName5, this.state.columns5)}
                            defaultSorted={[
                                {
                                    id: "number",
                                    desc: true
                                }
                            ]}
                        />
                    </TabPanel>
                </Tabs>
            </Page>
        )
    }
}

const mapStateToProps = ({apiReducer}) => {
    return {
        rawToken:           apiReducer.rawToken,
        projects:           apiReducer.projects,
        projectsInProgress: apiReducer.projectsInProgress,
        projectsDelivered:  apiReducer.projectsDelivered,
        projectsWaiting:    apiReducer.projectsWaiting,
        projectsRevived:    apiReducer.projectsRevived,
        projectsToBill:     apiReducer.projectsToBill,
        colors:             apiReducer.colors,
        users:              apiReducer.users,
        stepSettings:       apiReducer.stepSettings,
    }
};

export default connect(mapStateToProps)(Projects)
