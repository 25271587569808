import {ERROR} from "./action";

export const
    ADD_ROW = 'ADD_ROW',
    CHANGE_ROW = 'CHANGE_ROW',
    DELETE_ROW = 'DELETE_ROW',
    RESET_WRITE_QUOTATION_FORM = 'RESET_WRITE_QUOTATION_FORM',
    CHANGE_CITY = 'CHANGE_CITY',
    REORDER     = 'REORDER'
;

export const addRow = (form) => async dispatch => {
    try {
        dispatch({
            type: ADD_ROW,
            form: form
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const deleteRow = (id) => async dispatch => {
    try {
        dispatch({
            type: DELETE_ROW,
            id: id
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const changeRow = (event, id, newName, newValue) => async dispatch => {
    try {
        const name  = newName !== undefined ? newName : event.target.name;
        const value = newValue !== undefined ? newValue : event.target.value;

        dispatch({
            type: CHANGE_ROW,
            name: name,
            value: value,
            id: id
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const changeCity = value => async dispatch => {
    try {
        dispatch({
            type: CHANGE_CITY,
            value: value,
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const resetWriteQuotationForm = () => async dispatch => {
    try {
        dispatch({
            type: RESET_WRITE_QUOTATION_FORM,
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const reorder = () => dispatch => dispatch({ type: REORDER, });
