import React from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'

import "../css/components/modal.scss"

const MyModal = props => (
    <Modal
        isOpen={props.isOpen}
        onRequestClose={() => props.handleClose()}
        contentLabel="Boite de dialogue"
        className={'Modal ' + props.className}
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={true}
    >
        <div className="modal-header">
            <div className="modal-title">{props.title}</div>

            <div className="btn-circle btn-secondary modal-close" title={"Fermer"} onClick={() => props.handleClose()}>
                <i className="fas fa-times"/>
            </div>
        </div>

        {props.contentIsHtml === true ? <div className="modal-body" dangerouslySetInnerHTML={{__html: props.content}}/> : <div className="modal-body">{props.content}</div>}
    </Modal>
)

MyModal.propTypes = {
    isOpen:         PropTypes.bool.isRequired,
    handleClose:    PropTypes.func.isRequired,
    className:      PropTypes.string,
    title:          PropTypes.string,
    content:        PropTypes.object,
    contentIsHtml:  PropTypes.bool,
}

export default MyModal
