import React, {useState, useEffect} from "react"
import PropTypes from 'prop-types'

import "./index.scss"

const Note = ({onChangeNote, note, className}) => {
    const [value, setValue] = useState(note)

    useEffect(() => {
        setValue(note)
    }, [note])

    const onChange = e => {
        setValue(e.target.value)
        onChangeNote(e.target.value)
    }

    return (
        <div className={"sr-form-note " + className}>
            <textarea onChange={onChange} value={value} placeholder={"Cliquez-ici pour écrire une note"}/>
        </div>
    )
}

Note.propTypes = {
    onChangeNote:   PropTypes.func.isRequired,
    note:           PropTypes.string.isRequired,
    className:      PropTypes.string,
};

Note.defaultProps = {
    className: "",
}

export default Note
