import {createResource, ERROR, resetResource, updateResource} from "../../action";

export const
    CHANGE_PROPERTY     = 'CHANGE_PROPERTY',
    RESET               = 'RESET'
;

export const changeProperty = (name, value) => dispatch => {
    dispatch({
        type: CHANGE_PROPERTY,
        name: name,
        value: value,
    });
};

export const resourceToForm = resource => dispatch => Object.keys(resource).forEach(property => dispatch(changeProperty(property, resource[property])));

export const submitAddress = (id, data, rawToken, callback = null) => dispatch => {
    if(data.address.id !== undefined && data.address.id !== null)
        dispatch(updateResource('adresses', data.address.id, data.address, rawToken)).then(address => callback(address))
    else {
        dispatch(createResource('adresses', data.address, rawToken)).then(address => callback(address))
    }
}

export const submit = (id, data, rawToken, callback = null) => dispatch => {
    try {
        if(id === 'creer') {
            dispatch(submitAddress(id, data, rawToken, address => {
                data.address = address !== undefined ? data.address = address.id : null
                dispatch(submitCreate(id, data, rawToken, callback))
            }))
        }
        else {
            dispatch(submitAddress(id, data, rawToken, address => {
                data.address = address !== undefined ? data.address = address.id : null
                dispatch(submitUpdate(id, data, rawToken, callback))
            }))
        }
    }
    catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const submitCreate = (id, data, rawToken, callback = null) => dispatch => {
    dispatch(createResource('repertoireContacts', data, rawToken))
        .then(() => {
            if(callback !== null)
                callback()
        })
};

export const submitUpdate = (id, data, rawToken, callback = null) => dispatch => {
    dispatch(updateResource('repertoireContacts', id, data, rawToken))
        .then(() => {
            if(callback !== null)
                callback()
        })
}

export const reset = () => dispatch => {
    dispatch({ type: RESET });
    dispatch(resetResource())
}
