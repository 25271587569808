import React from "react"

import MyModal from "../../../../components/MyModal"
import AutoSuggest from "../../../../components/Form/AutoSuggest/index"

const StepsForm = ({modalIsOpen, handleCloseModal, changeHandler, stepUpdated, handleSubmit, stepSettings, users, form}) => (
    <MyModal
        className={"stepsForm"}
        isOpen={modalIsOpen}
        handleClose={handleCloseModal}
        title={(stepUpdated === null ? 'Créer' : 'Modifier') + " une tâche"}
        content={
            <form onSubmit={handleSubmit} className={"form-block"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>Intitulé de la tâche</label>
                        <AutoSuggest items={stepSettings.map(step => step.name)} name={"name"} value={form.name.value} onChange={changeHandler} placeholder={"Intitulé de la tâche"}/>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>Utilisateurs</label>
                        {
                            users !== null ?
                                <select name={"users"} value={form.users.value} size={users.length} onChange={changeHandler} multiple>
                                    {users.map((user, i) => (
                                        <option key={i} value={user.id}>{user.firstname} {user.lastname}</option>
                                    ))}
                                </select>
                                :
                                'Chargement ...'
                        }
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-12 col-lg-3"}>
                        <label>A faire le</label>
                        <input type={"date"} name={"planned"} placeholder={"A faire le"} value={form.planned.value} onChange={changeHandler}/>
                    </div>
                    <div className={"col-12 col-lg-2"}>
                        <label>Nombre d'heures</label>
                        <input type={"text"} name={"hours"} placeholder={"Nombre d'heures"} value={form.hours.value} onChange={changeHandler}/>
                    </div>
                    <div className={"col-12 col-lg-1"}>
                        <label>Priorité</label>
                        <select name={"priority"} value={form.priority.value} onChange={changeHandler}>
                            <option value={1}>!</option>
                            <option value={2}>!!</option>
                            <option value={3}>!!!</option>
                            <option value={4}>Point dossier</option>
                            <option value={0}>Relance</option>
                        </select>
                    </div>
                    <div className={"col-12 col-lg-6"}>
                        <label>En cours ?</label>
                        <select name={"waiting"} value={form.waiting.value} onChange={changeHandler}>
                            <option value={false}>En cours</option>
                            <option value={true}>Prévisionnelle</option>
                        </select>
                    </div>
                </div>
                <button title={stepUpdated === null ? 'Créer' : 'Modifier'}>{stepUpdated === null ? 'Créer' : 'Modifier'}</button>
            </form>
        }
    />
)

export default StepsForm
