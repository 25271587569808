import React from 'react'
import {useDispatch} from "react-redux"
import {NavLink} from "react-router-dom"

import {toggleSidenav} from "../../redux/designAction"

const MenuItem = ({link, title, icon, mobile}) => {
    const dispatch = useDispatch()

    const split = window.location.pathname.split('/')
    const path  = '/' + split[1]

    const activeClass = (path, link) => path === link

    const closeSidenav = () => {
        if(mobile)
            dispatch(toggleSidenav(false))
    }

    return (
        <li className={"sr-menu-link " + (activeClass(path, link) ? "active" : '')} onClick={() => closeSidenav()}>
            <NavLink exact to={link}
                     activeClassName={"active"}>
                <i className={icon}/> <span>{title}</span>
            </NavLink>
        </li>
    )
}

export default MenuItem
