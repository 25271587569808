import React from "react"
import {useDispatch, useSelector} from "react-redux"

import Note from "../../components/Form/Note/index"
import {changeQuotation} from "../../redux/QuotationFormAction"

const AccountingNote = () => {
    const dispatch  = useDispatch()
    const {form}    = useSelector(({quotationFormReducer}) => quotationFormReducer)

    const onChange = value => {
        dispatch(changeQuotation(null, "note", value))
    }

    return (
        <Note note={form.note.value} onChangeNote={onChange} className={"accounting-note form-block"}/>
    )
}

export default AccountingNote
