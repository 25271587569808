import React, { Component } from 'react'
import {applyMiddleware, createStore} from "redux"
import {createLogger} from "redux-logger"
import thunk from "redux-thunk"
import {Provider} from "react-redux"
import {BrowserRouter} from "react-router-dom"
import DocumentTitle from 'react-document-title'


import reducer from "./redux/reducer"
import Router from "./security/Router"
import {GET_TOKEN} from "./redux/action"
import {isDefined} from "sr-dates";

const middleware = [thunk];
if (process.env.NODE_ENV !== 'production') {
    middleware.push(createLogger());
}

const store = createStore(reducer, applyMiddleware(...middleware));

if(isDefined(localStorage.getItem('jwt'))) {
    const token = JSON.parse(localStorage.getItem('jwt'));
    const tokenExpiresAt = token.payload.exp;
    const currentTimestamp = Date.now() / 1000;
    const threshold = 300;

    if(currentTimestamp + threshold < tokenExpiresAt) {
        store.dispatch({
            type: GET_TOKEN,
            payload: token.payload,
            rawToken: token.rawToken
        })
    }
}

class App extends Component {
    render() {
        return (
            <DocumentTitle title={"Gescom 2.0 - Marne Enseignes"}>
                <Provider store={store}>
                    <BrowserRouter>
                        <Router/>
                    </BrowserRouter>
                </Provider>
            </DocumentTitle>
        );
    }
}

export default App;
