import React, {Component, createRef} from 'react'
import DocumentTitle from 'react-document-title'

import "../../../css/pages/quotations/new-print.scss"
import TopPrint from "./TopPrint"
import TablePrint from "./TablePrint"
import DeliverySettlementPrint from "./DeliverySettlementPrint"
import AmountsPrint from "./AmountsPrint"
import CGV from "./CGV"
import {isDefined} from "../../../utils/Data";

class PrintQuotation extends Component {
    constructor(props) {
        super(props);
        this.page = createRef();
    }

    getTitle = resource => isDefined(resource) ?
        resource.number + ' ' + (resource.customerName !== null ? resource.customerName : resource.project.customer.name)
        : 'Gescom 2.1 - Marne Enseignes'

    render() {
        const resource = this.props.resource;

        return (
            <DocumentTitle title={this.getTitle(resource)}>
            <div className={"print"} ref={this.page}>
                <div className={"container-fluid"}>
                    <div className={"row-fluid"}>
                        <div className={"col-12"}>
                            {resource !== null ?
                                <>
                                    <div className="container">
                                        <TopPrint resource={resource} type={this.props.type}/>
                                        <TablePrint resource={resource} type={this.props.type}/>
                                        <DeliverySettlementPrint resource={resource} type={this.props.type}/>
                                        <AmountsPrint resource={resource}/>
                                    </div>
                                    <p className={"footer"}>
                                        Clauses de propriété : La marchandise reste notre propriété jusqu'au paiement intégral de la facture correspondante - Loi du 12/05/1980
                                    </p>
                                    <CGV/>
                                </>
                            : 'Chargement ...'
                            }
                        </div>
                    </div>
                </div>
            </div>
            </DocumentTitle>
        )
    }
}

export default PrintQuotation
