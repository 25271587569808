import React from 'react';
import {Link} from "react-router-dom";

import Page from "../../components/Page/Page";
import ArticlesList from "./ArticlesList";

const Articles = () => {
    const buttonsRight = () => (
        <Link to={"/articles/creer"}>
            <button className={"btn-circle add-resource"} title={"Ajouter"}>
                <i className={"fas fa-plus"} />
            </button>
        </Link>
    )

    return (
        <Page siteTitle={"Articles"} title={"Gestion des articles"} headerButtons={buttonsRight()}>
            <ArticlesList/>
        </Page>
    )
}

export default Articles
