import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Link} from "react-router-dom"

import "../../css/pages/customers/customer.scss"
import {getResource} from "../../redux/action"
import {getParams, transpose} from "../../utils/Data"
import Page from "../../components/Page/Page"
import Table from "../../components/Table/Table"

const Customer = () => {
    const {id}      = getParams()
    const dispatch  = useDispatch()
    const rawToken  = useSelector(({apiReducer}) => apiReducer.rawToken)
    const customer  = useSelector(({apiReducer}) => apiReducer.item)

    useEffect(() => {
        dispatch(getResource('customers', id, rawToken))
    }, [])

    const head = () => (
        <tr>
            <th>Projets</th>
            <th>Devis</th>
            <th>Factures</th>
        </tr>
    )

    const getData = () => {
        const projects          = customer.projects;
        const arrayQuotations   = projects.map(project => project.quotations)
        const arrayBills        = projects.map(project => project.bills)

        let quotations = []
        arrayQuotations.forEach(project => {
            project.forEach(quotation => quotations.push(quotation))
        })

        let bills = []
        arrayBills.forEach(project => {
            project.forEach(quotation => bills.push(quotation))
        })


        return [projects, quotations, bills]
    }

    const body = () => {
        let content = '';

        if(customer !== null)
            content =  transpose(getData()).map((column, i) => {
                    const row = column.map((item, j) => {
                        if(item !== null && item !== undefined)
                            if(j === 0)
                                return <td key={j} style={{ width: '30%' }}><Link to={"/projets/" + item.id}><b>{item.number}</b> - {item.name}</Link></td>
                            else if(j === 1)
                                return <td key={j} style={{ width: '35%' }}><Link to={"/devis/" + item.id + "/modifier"}>
                                    <b>{item.number}</b> -  {item.name} ({item.drafted !== null ? 'devis' : 'brouillon'})
                                </Link></td>
                            else
                                return <td key={j} style={{ width: '35%' }}><Link to={"/factures/" + item.id + "/modifier"}><b>{item.number}</b> - {item.name}</Link></td>
                        else
                            return <td/>
                    })
                    return <tr key={i}>{row}</tr>
                })
        return content;
    }

    const headerButtons = () => (
        customer !== null ?
            <Link to={"/clients/" + id + "/modifier"}>
                <button className={"btn-circle btn-warning"} title={"Modifier"}>
                    <i className={"fas fa-pencil-alt"} />
                </button>
            </Link>
        : ''
    )

    return (
        <Page
            title={customer !== null ? 'Fiche du client n°' + customer.code  + ' - ' + customer.name : ''}
            className={"customer"}
            headerButtons={headerButtons()}>

            {customer !== null ? <Table head={head()} body={body()}/> : 'Chargement ...'}
        </Page>
    )
}

export default Customer
