import {TOGGLE_SIDENAV} from "./designAction";
import {isDefined} from "../utils/Data";

const initialState = {
    sidenavOpened: isDefined(localStorage.getItem('navbar')) ? localStorage.getItem('navbar') === 'true' : false,
    menu: {
        accounting: [
           {
                "title": "Devis",
                "icon": "fas fa-sticky-note",
                "link": "/devis"
            },
            {
                "title": "Factures",
                "icon": "far fa-sticky-note",
                "link": "/factures"
            },
            {
                "title": "Catégories d\'articles",
                "icon": "fas fa-cube",
                "link": "/categories-articles"
            },
            {
                "title": "Articles",
                "icon": "fas fa-cube",
                "link": "/articles"
            },
            {
                "title": "Suivi",
                "icon": "fas fa-chart-line",
                "link": "/suivi-activite"
            },
        ],
        catalogue: [
            {
                "title": "Fournisseurs",
                "icon": "fas fa-sticky-note",
                "link": "/fournisseurs"
            },
            {
                "title": "Types de produits",
                "icon": "far fa-sticky-note",
                "link": "/types-produits"
            },
            {
                "title": "Catalogue",
                "icon": "far fa-sticky-note",
                "link": "/produits"
            },
            {
                "title": "Commandes",
                "icon": "fas fa-sticky-note",
                "link": "/commandes"
            },
        ],
        parameters: [
            {
                "title": "Utilisateurs",
                "icon": "fas fa-users",
                "link": "/utilisateurs"
            },
            {
                "title": "Paramètres",
                "icon": "fas fa-wrench",
                "link": "/parametres"
            },
            {
                "title": "Couleurs",
                "icon": "fas fa-tint",
                "link": "/couleurs"
            },
            {
                "title": "Permissions",
                "icon": "fas fa-wrench",
                "link": "/permissions"
            },
        ]
    }
};

const designReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_SIDENAV:
            localStorage.setItem('navbar', action.open !== null ? action.open : !state.sidenavOpened);
            return {
                ...state,
                sidenavOpened: action.open !== null ? action.open : !state.sidenavOpened,
            };
        default:
            return state;
    }
};

export default designReducer;
