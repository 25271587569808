import React, {Component} from 'react';
import { Link } from "react-router-dom"
import {connect} from "react-redux";

import "../../css/pages/articles/list.scss"
import {deleteResource, getResources, updateResource} from "../../redux/action";
import Table from "../../components/Table";
import {TextFilter, SelectFilter, FreeTextInput} from "../../components/List/index";
import {getColumnsWidth, getDefaultFiltered} from "../../utils/List";
import {renderIfDefined} from "../../utils/Data";
import {isDefined} from "sr-dates"
import {onDelete} from "../../utils/Alert";

const frenchName = "articles";
const localName  = "articles";
const apiName    = 'articles';

let localNames   = ['code', 'free', 'category.name', 'text'];
localNames       = localNames.map(name => localName + '-' + name);

class ArticlesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    Header: 'Code',
                    accessor: 'code',
                    width: getColumnsWidth(localNames[0], 100),
                },
                {
                    Header: 'Sem',
                    accessor: 'free',
                    width: getColumnsWidth(localNames[1], 60),
                    Cell: ({row}) => {
                        const id = row._original.id;
                        return (
                            <FreeTextInput
                                value={renderIfDefined(row._original.free)}
                                id={id}
                                update={(type, body) => {this.props.dispatch(updateResource(apiName + type, id, body, this.props.rawToken))}}
                            />
                            /*<input
                                key={id}
                                type={"text"}
                                name={"free-" + id}
                                value={renderIfDefined(row._original.free)}
                                onChange={(e) => this.onChangeFree(e, id)}
                                className={"articles-free-input"}
                            />*/)
                    }
                },
                {
                    Header: 'Catégorie',
                    accessor: 'category.name',
                    width: getColumnsWidth(localNames[2], 200),
                    Filter: ({filter, onChange}) => <SelectFilter filter={filter} localName={localNames[2]} onChange={onChange} options={this.props.articleCategories.map(category => category.name)}/>,
                    Cell: ({row}) => isDefined( row._original.category) ? <Link to={"/categories-articles/" + row._original.category.id + "/modifier"}>{row._original.category.name}</Link> : ''
                },
                {
                    Header: 'Désignation',
                    accessor: 'text',
                    width: getColumnsWidth(localNames[3], 1300),
                    Cell: ({row}) => <div className={"text"} dangerouslySetInnerHTML={{__html: row._original.text.substring(0, 1500)}}/>
                },
                {
                    Header: 'Actions',
                    accessor: 'actions',
                    sortable: false,
                    resizable: false,
                    width: this.props.addButton === true ? 150 : 100,
                    Cell: ({ row }) => (
                        <div className={"btn-flex"}>
                            {this.props.addButton === true ?
                                <div className="btn-circle btn-primary" title={"Ajouter"}
                                     onClick={() => this.handleAdd(row._original.id)}>
                                    <i className="fas fa-upload"/>
                                </div>
                                : ''}
                            <Link to={"/articles/" + row._original.id + "/modifier"}>
                                <div className="btn-circle btn-warning" title={"Modifier"}>
                                    <i className="fa fa-edit"/>
                                </div>
                            </Link>
                            <div className="btn-circle btn-danger" title={"Supprimer"}
                                 onClick={() => this.handleDelete(row._original.id)}>
                                <i className="fa fa-trash"/>
                            </div>
                        </div>
                    )
                },
            ]
        }
    }

    componentDidMount() {
        this.props.dispatch(getResources('articleCategories', this.props.rawToken));
        this.state.columns.forEach((column, i) => {
            if (i < this.state.columns.length && i !== 2)
                column.Filter = ({filter, onChange}) => <TextFilter localName={localNames[i]} filter={filter} onChange={onChange}/>
        })
    }


    handleDelete = id => onDelete('', 'Etes-vous sûr de vouloir supprimer cet article ?', () => this.props.dispatch(deleteResource(apiName, id, this.props.rawToken)));

    handleAdd = id => this.props.handleAdd(id);

    onChangeFree = (e, id) => {
        e.preventDefault();

        const value = e.target.value;
        const body  = {free: value}

        this.props.dispatch(updateResource('articles', id, body, this.props.rawToken))
    };

    render() {
        return (
            <Table
                name={localName + "-list"}
                data={this.props[apiName]}
                columns={this.state.columns}
                frenchName={frenchName}
                localName={localName}
                apiName={apiName}
                className={"articles-list"}
                defaultFiltered={getDefaultFiltered(localName, this.state.columns)}
                defaultSorted={[
                    {
                        id: "code",
                        desc: false
                    }
                ]}
            />
        )
    }
}

const mapStateToProps = ({apiReducer}) => {
    return {
        rawToken: apiReducer.rawToken,
        [apiName]: apiReducer[apiName],
        articleCategories: apiReducer.articleCategories,
    }
};

export default connect(mapStateToProps)(ArticlesList)
