import React from "react"
import {useDispatch, useSelector} from "react-redux"
import CreatableSelect  from 'react-select/creatable'

import {changeQuotation} from "../../../redux/QuotationFormAction"

const Settlement = () => {
    const dispatch      = useDispatch()
    const {settlements} = useSelector(({apiReducer}) => apiReducer)
    const form          = useSelector(({quotationFormReducer}) => quotationFormReducer.form)

    const items = settlements.sort((a, b) => {
        if(a.name < b.name) { return -1; }
        if(a.name > b.name) { return 1; }
        return 0;
    })

    const options = items.map(item => {
        return {
            value: item.id,
            label: item.name
        }
    })

    const onChange = obj => {
        dispatch(changeQuotation(null, "settlement", obj.value))
    }

    return (
        <>
            <label>Mode de règlement</label>
            <CreatableSelect
                disabled={!settlements.length > 0}
                options={options}
                name={"settlement"}
                placeholder={settlements.length > 0 ? "Mode de règlement" : "Chargement ..."}
                value={options.find(option => option.value === form.settlement.value)}
                onChange={onChange}
            />
        </>
    )
}

export default Settlement


