import React, {Component} from 'react';

import {formatDate} from "../../../utils/Date";
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import HeaderBlock from "../../../components/HeaderBlock";
import {Link} from "react-router-dom";

class GeneralInfos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: localStorage.getItem('fiche-project-general-show') !== null ? JSON.parse(localStorage.getItem('fiche-project-general-show')) : true,
        }
    }

    handleVisibility = () => {
        this.setState(prevState => {
            return {
                show: !prevState.show
            }
        });
        localStorage.setItem('fiche-project-general-show', this.state.show === true ? 'false' : 'true');
    };

    render() {
        const columns = [
            {
                Header: 'Dossier',
                accessor: 'number',
                width: localStorage.getItem('fiche-project-general-number') !== null ? localStorage.getItem('fiche-project-general-number') : 80,
                Cell: ({row}) => <Link to={"/projets?num=" + row._original.number}>{row._original.number}</Link>
            },
            {
                Header: 'N° Client',
                accessor: 'customer.code',
                width: localStorage.getItem('fiche-project-general-customer.code') !== null ? localStorage.getItem('fiche-project-general-customer.code') : 80,
                Cell: ({row}) => <Link to={"/clients/" + row._original.customer.id + "/modifier"}>{row._original.customer.code}</Link>
            },
            {
                Header: 'Client',
                accessor: 'customer.name',
                width: localStorage.getItem('fiche-project-general-customer.name') !== null ? localStorage.getItem('fiche-project-general-customer.name') : 430,
                Cell: ({row}) => <Link to={"/clients/" + row._original.customer.id + "/modifier"}>{row._original.customer.name}</Link>
            },
            {
                Header: 'Intitulé',
                accessor: 'name',
                width: localStorage.getItem('fiche-project-general-name') !== null ? localStorage.getItem('fiche-project-general-name') : 400
            },
            {
                Header: 'Création',
                accessor: 'created',
                width: localStorage.getItem('fiche-project-general-created') !== null ? localStorage.getItem('fiche-project-general-created') : 100,
                Cell: ({row}) => formatDate(row._original.created)
            },
            {
                Header: 'Livraison',
                accessor: 'deliveryDate',
                width: localStorage.getItem('fiche-project-general-deliveryDate') !== null ? localStorage.getItem('fiche-project-general-deliveryDate') : 600,
                Cell: ({row}) => (
                    <div>
                        {row._original.address !== null ? <div>{row._original.address.number + " " + row._original.address.street + (row._original.address.postalCode !== "" || row._original.address.city !== "" ?(", " + row._original.address.postalCode + " " + row._original.address.city) : "")}</div> : ''
                        }
                        <p>{formatDate(row._original.deliveryDate)}</p>
                    </div>
                )
            },
        ];

        const project = this.props.project;

        return (
            <div className={"block"}>
                <HeaderBlock title={"Informations"} handleVisibility={this.handleVisibility} show={this.state.show}/>

                <ReactTable
                    style={this.state.show === true ? {display: 'block'} : {display: 'none'}}
                    data={[project]}
                    columns={columns}
                    keyField={"id"}
                    showPagination={false}
                    showPageJump={false}
                    sortable={false}
                    multiSort={false}
                    defaultPageSize={1}
                    onResizedChange={(newResized, event) => {
                        newResized.map((newResize) =>
                            localStorage.setItem('fiche-project-general-' + newResize.id, newResize.value)
                        )
                    }}
                />
            </div>
        )
    }
}

export default GeneralInfos
