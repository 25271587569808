import {getParams} from "../utils/Data";

export const havePermission = (user, permission) => {
    const permissions = user.permissions.concat(user.role.permissions)
    let bool = user.role.name === 'SUPER_ADMIN'

    for(let i = 0; i < permissions.length; i++) {
        if(permissions[i].id === permission.id)
            bool = true
    }

    return bool
}

export const havePermissionName = (user, permission) => {
    const permissions = user.permissions.concat(user.role.permissions)
    let bool = user.role.name === 'SUPER_ADMIN'

    for(let i = 0; i < permissions.length; i++) {
        if(permissions[i].name === permission)
            bool = true
    }

    return bool
}

export const checkPermissions = (pathParams, user) => {
    if(user !== null) {
        const {path}        = getParams()
        const pathSwitch    = pathParams !== null ? pathParams : path

        switch (pathSwitch) {
            case 'devis':
                return havePermissionName(user, 'LIST_QUOTATIONS')
            case 'factures':
                return havePermissionName(user, 'LIST_BILLS')
            case 'articles':
                return havePermissionName(user, 'LIST_ARTICLES')
            case 'categories-articles':
                return havePermissionName(user, 'LIST_ARTICLE_CATEGORY')
            case 'suivi-activite':
                return havePermissionName(user, 'LIST_BILLS')
            case 'fournisseurs':
                return havePermissionName(user, 'LIST_PROVIDERS')
            case 'types-produits':
                return havePermissionName(user, 'LIST_PRODUCT_TYPES')
            case 'catalogue':
                return havePermissionName(user, 'LIST_PRODUCTS')
            case 'clients':
                return havePermissionName(user, 'LIST_CUSTOMER')
            case 'utilisateurs':
                return havePermissionName(user, 'LIST_USERS')
            case 'couleurs':
                return havePermissionName(user, 'LIST_COLORS')
            case 'permissions':
                return havePermissionName(user, 'LIST_PERMISSIONS')
            default:
                return true;
        }
    }
    return false
}
