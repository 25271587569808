import {CHANGE_FILTERED, RESET_FILTERED} from "./tableAction";

const initialState = {
    filtered: [],
};

const tableReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_FILTERED: {
            return {
                ...state,
                filtered: state.filtered.map(filter => filter.id === action.id ? {...filter, value: action.value} : filter)
            }
        }
        case RESET_FILTERED: {
            return {
                ...state,
                filtered: []
            }
        }
        default:
            return state;
    }
};

export default tableReducer;
