import React, {Component} from 'react'
import 'react-table/react-table.css'
import {Tab, TabList, TabPanel, Tabs} from "react-tabs"
import Modal from 'react-modal'
import {connect} from "react-redux"

import "../../../css/pages/projects/stepsForm.scss"
import {formatDate} from "../../../utils/Date"
import {
    createResource,
    deleteResource,
    getResources,
    specialPut,
    updateResource
} from "../../../redux/action"
import HeaderBlock from "../../../components/HeaderBlock"
import SimpleTable from "../../../components/SimpleTable"
import CommentModal from "./Steps/CommentModal"
import {onDelete} from "../../../utils/Alert"
import StepsForm from "./Steps/StepsForm"
import {isDefined} from "sr-dates";

Modal.setAppElement('#root');

class Steps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: localStorage.getItem('fiche-project-steps-show') !== null ? JSON.parse(localStorage.getItem('fiche-project-steps-show')) : true,
            users: null,
            modalIsOpen: false,
            showModalComment: {
                show: false,
                id: null,
            },
            stepUpdated: null,
            selected: -1,
            selectedFinished: -1,
            selectedWaiting: -1,
            form: {
                name: {
                    value: ''
                },
                users: {
                    value: []
                },
                planned: {
                    value: null
                },
                hours: {
                    value: null
                },
                priority: {
                    value: 1
                },
                waiting: {
                    value: false,
                }
            }
        };
    }

    componentDidMount() {
        this.props.dispatch(getResources('stepSettings', this.props.rawToken));
        this.props.dispatch(getResources('steps', this.props.rawToken));
        this.props.dispatch(getResources('users', this.props.rawToken));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.project.steps !== this.props.project.steps) {
            this.setState({
                steps: this.props.project.steps,
            })
        }
    }

    handleVisibility = () => {
        this.setState(prevState => {
            return {
                show: !prevState.show
            }
        });
        localStorage.setItem('fiche-project-steps-show', this.state.show === true ? 'false' : 'true');
    };

    handleDelete = id => onDelete('', 'Etes-vous sûr de vouloir supprimer cette tâche ?', () => {
        this.props.removeStep("steps",id)
        this.props.dispatch(deleteResource('steps', id, this.props.rawToken))
    });

    handleMade = (id) => {
        this.props.dispatch(specialPut(id, this.props.rawToken, 'steps', 'made')).then(step => this.props.updateStep("steps", step))
        this.handleModalComment(id);
    };

    handleFinish = id => this.props.dispatch(specialPut(id, this.props.rawToken, 'steps', 'finish')).then(step => this.props.updateStep("steps", step));

    handleWait = id => {
        this.props.dispatch(specialPut(id, this.props.rawToken, 'steps', 'wait')).then(step => this.props.updateStep("steps", step));
    }

    handleModalComment = (id = null) => {
        this.setState(prevState => {
            return {
                showModalComment: {
                    show: !prevState.showModalComment.show,
                    id: id,
                }
            }
        });
    }

    handleOpenModal = (id = null) => {
        this.setState( {
             modalIsOpen: true,
        });

        if(id !== null) {
            this.setState( {
                stepUpdated: this.props.project.steps.find(step => step.id === parseInt(id, 10))
            }, () => {
                if(isDefined(this.state.stepUpdated)) {
                    let split = isDefined(this.state.stepUpdated.planned) ? this.state.stepUpdated.planned.split('T') : null

                    this.setState({
                        form: {
                            ...this.state.form,
                            'name': {
                                ...this.state.form.name,
                                value: this.state.stepUpdated.name !== null ? this.state.stepUpdated.name : ''
                            },
                            'users': {
                                ...this.state.form.name,
                                value: this.state.stepUpdated.users !== null ? this.state.stepUpdated.users.map((user) => {return user.id}) : []
                            },
                            'planned': {
                                ...this.state.form.planned,
                                value: this.state.stepUpdated.planned !== null ? split[0] : null
                            },
                            'hours': {
                                ...this.state.form.hours,
                                value: this.state.stepUpdated.hours !== null ? this.state.stepUpdated.hours : null
                            },
                            'priority': {
                                ...this.state.form.priority,
                                value: this.state.stepUpdated.priority !== null ? this.state.stepUpdated.priority : 1
                            },
                            'waiting': {
                                ...this.state.form.waiting,
                                value: this.state.stepUpdated.waiting !== null ? this.state.stepUpdated.waiting : false
                            },
                        }
                    })
                }
            })
        }
        else {
            this.setState({
                stepUpdated: null,
                form: {
                    ...this.state.form,
                    'name': {
                        ...this.state.form.name,
                        value: ''
                    },
                    'users': {
                        ...this.state.form.name,
                        value: []
                    },
                    'planned': {
                        ...this.state.form.planned,
                        value: null
                    },
                    'hours': {
                        ...this.state.form.hours,
                        value: null
                    },
                    'priority': {
                        ...this.state.form.priority,
                        value: 1
                    },
                    'waiting': {
                        ...this.state.form.waiting,
                        value: false
                    },
                }
            })
        }
    };

    handleCloseModal = () => {
        this.setState({modalIsOpen: false});
    };


    changeHandler = (e, newName, newValue) => {
        const name  = newName !== undefined ? newName : e.target.name;
        let value   = newValue !== undefined ? newValue : e.target.value;

        if(name === 'priority')
            value = parseInt(value, 10);
        else if(name === 'waiting')
            value = value === 'true'
        else if(name === 'users') {
            let options = e.target.options;
            value = [];
            for (let i = 0, l = options.length; i < l; i++) {
                if (options[i].selected) {
                    value.push(options[i].value);
                }
            }
        }

        this.setState({
            form: {
                ...this.state.form,
                [name]: {
                    ...this.state.form[name],
                    value
                }
            }
        })
    };

    handleSubmit = e => {
        e.preventDefault();

        let body = {};
        Object.keys(this.state.form).map((input) =>
            body[input] = this.state.form[input].value
        );

        //body.hours = parseInt(body.hours, 10) || null;
        body.planned = body.planned === '' ? null : body.planned

        if(this.state.stepUpdated === null) {
            body.project = this.props.project.id;

            this.props.dispatch(createResource('steps', body, this.props.rawToken))
                .then(step => {
                    this.props.addStep("steps", step)
                    this.setState( {
                        modalIsOpen: false
                    })
                })
        }
        else {
            this.props.dispatch(updateResource('steps', this.state.stepUpdated.id, body, this.props.rawToken))
                .then(step => {
                    this.props.updateStep("steps", step)
                    this.setState({
                            modalIsOpen: false
                    })
                })
        }
    };

    selectRow = (id) => {
        this.setState({
            selected: id
        })
    };

    selectRowFinished = (id) => {
        this.setState({
            selectedFinished: id
        })
    };

    selectRowWaiting = (id) => {
        this.setState({
            selectedWaiting: id
        })
    };

    render() {
        const columnsTodo = [
            {
                Header: 'Intitulé',
                accessor: 'name',
                width: localStorage.getItem('fiche-project-stepsDo-name') !== null ? localStorage.getItem('fiche-project-stepsDo-name') : 500,
            },
            {
                Header: 'Utilisateur',
                accessor: 'users',
                width: localStorage.getItem('fiche-project-stepsDo-users') !== null ? localStorage.getItem('fiche-project-stepsDo-users') : 200,
                Cell: ({row}) => {
                    return (row._original.users.map((user, i) => (
                            <li key={i}>{user.firstname} {user.lastname}</li>
                        ))
                    )
                }
            },
            {
                Header: 'Durée',
                accessor: 'hours',
                width: localStorage.getItem('fiche-project-stepsDo-hours') !== null ? localStorage.getItem('fiche-project-stepsDo-hours') : 80,
                Cell: ({row}) => row._original.hours !== null ? row._original.hours + ' h' : ''
            },
            {
                Header: 'Etat',
                accessor: 'state',
                width: localStorage.getItem('fiche-project-stepsDo-state') !== null ? localStorage.getItem('fiche-project-stepsDo-state') : 100,
                filterable: false,
                sortable: false,
                Cell: ({row}) => row._original.made !== null ? <span style={{color: 'green'}}>Fait</span> : <span style={{ color:'red' }}>A faire</span>
            },
            {
                Header: 'Priorité',
                accessor: 'priority',
                width: localStorage.getItem('fiche-project-stepsDo-priority') !== null ? localStorage.getItem('fiche-project-stepsDo-priority') : 80,
                Cell: ({row}) => {
                    switch(row._original.priority) {
                        case 2:
                            return '!!';
                        case 3:
                            return '!!!';
                        case 4:
                            return 'P';
                        case 0:
                            return 'R';
                        default:
                            return '!';
                    }
                }
            },
            {
                Header: 'Planifié le',
                accessor: 'planned',
                width: localStorage.getItem('fiche-project-stepsDo-planned') !== null ? localStorage.getItem('fiche-project-stepsDo-planned') : 95,
                Cell: ({row}) => (row._original.planned !== null ? formatDate(row._original.planned) : 'A plannifier')
            },
            {
                Header: 'Commentaire',
                accessor: 'comment',
                width: localStorage.getItem('fiche-project-stepsDo-comment') !== null ? localStorage.getItem('fiche-project-stepsDo-comment') : 500,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                width: 225,
                resizable: false,
                filterable: false,
                sortable: false,
                Cell: ({ row }) => {
                    return (
                        <div className={"btn-flex"}>
                            <div className="btn-circle btn-warning" title={"Modifier"} onClick={() => this.handleOpenModal(row._original.id)}>
                                <i className="fa fa-edit"/>
                            </div>
                            <div className="btn-circle btn-secondary" title={"Déplacer dans les tâches prévisionnelles"} onClick={() => this.handleWait(row._original.id)}>
                                <i className="fa fa-pause"/>
                            </div>
                            {row._original.made === null ?
                                <div className="btn-circle btn-success" title={"Réaliser"} onClick={() => this.handleMade(row._original.id)}>
                                    <i className="fa fa-check"/>
                                </div>
                                :
                                <div className="btn-circle btn-danger" title={"Marquer comme non réalisée"} onClick={() => this.handleMade(row._original.id)}>
                                    <i className="fa fa-check"/>
                                </div>
                            }
                            <div className="btn-circle btn-primary" title={"Terminer"} onClick={() => this.handleFinish(row._original.id)}>
                                <i className="fa fa-arrow-right"/>
                            </div>
                            <div className="btn-circle btn-danger" title={"Supprimer"} onClick={() => this.handleDelete(row._original.id)}>
                                <i className="fa fa-trash"/>
                            </div>
                        </div>
                    )
                }
            }
        ];

        const columnsDo = [
            {
                Header: 'Intitulé',
                accessor: 'name',
                width: localStorage.getItem('fiche-project-steps-name') !== null ? localStorage.getItem('fiche-project-steps-name') : 500,
            },
            {
                Header: 'Utilisateur',
                accessor: 'users',
                width: localStorage.getItem('fiche-project-steps-users') !== null ? localStorage.getItem('fiche-project-steps-users') : 200,
                Cell: ({row}) => {
                    return (row._original.users.map((user, i) => (
                            <li key={i}>{user.firstname} {user.lastname}</li>
                        ))
                    )
                }
            },
            {
                Header: 'Durée',
                accessor: 'hours',
                width: localStorage.getItem('fiche-project-steps-hours') !== null ? localStorage.getItem('fiche-project-steps-hours') : 80,
                Cell: ({row}) => row._original.hours !== null ? row._original.hours + ' h' : ''
            },
            {
                Header: 'Etat',
                accessor: 'state',
                width: localStorage.getItem('fiche-project-steps-state') !== null ? localStorage.getItem('fiche-project-steps-state') : 100,
                filterable: false,
                sortable: false,
                Cell: ({row}) => row._original.made !== null ? <span style={{color: 'green'}}>Fait</span> : <span style={{ color:'red' }}>A faire</span>
            },
            {
                Header: 'Priorité',
                accessor: 'priority',
                width: localStorage.getItem('fiche-project-steps-priority') !== null ? localStorage.getItem('fiche-project-steps-priority') : 80,
                Cell: ({row}) => {
                    switch(row._original.priority) {
                        case 2:
                            return '!!';
                        case 3:
                            return '!!!';
                        case 4:
                            return 'P';
                        case 0:
                            return 'R';
                        default:
                            return '!';
                    }
                }
            },
            {
                Header: 'Planifié le',
                accessor: 'planned',
                width: localStorage.getItem('fiche-project-steps-planned') !== null ? localStorage.getItem('fiche-project-steps-planned') : 95,
                Cell: ({row}) => {
                    return (row._original.planned !== null ? formatDate(row._original.planned) : 'A plannifier')
                }
            },
            {
                Header: 'Réalisé le',
                accessor: 'made',
                width: localStorage.getItem('fiche-project-steps-made') !== null ? localStorage.getItem('fiche-project-steps-made') : 95,
                Cell: ({row}) => {
                    return (row._original.made !== null ? formatDate(row._original.made) : '')
                }
            },
            {
                Header: 'Commentaire',
                accessor: 'comment',
                width: localStorage.getItem('fiche-project-steps-comment') !== null ? localStorage.getItem('fiche-project-steps-comment') : 450,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                width: 225,
                resizable: false,
                filterable: false,
                sortable: false,
                Cell: ({ row }) => {
                    return (
                        <div className={"btn-flex"}>
                            <div className="btn-circle btn-warning" title={"Modifier"} onClick={() => this.handleOpenModal(row._original.id)}>
                                <i className="fa fa-edit"/>
                            </div>
                            {row._original.made === null ?
                                <div className="btn-circle btn-success" title={"Réaliser"} onClick={() => this.handleMade(row._original.id)}>
                                    <i className="fa fa-check"/>
                                </div>
                            :
                                <div className="btn-circle btn-danger" title={"Marquer comme non réalisée"} onClick={() => this.handleMade(row._original.id)}>
                                    <i className="fa fa-check"/>
                                </div>
                            }
                            <div className="btn-circle btn-primary" title={"Déplacer dans les tâches en cours"} onClick={() => this.handleFinish(row._original.id)}>
                                <i className="fa fa-arrow-left"/>
                            </div>
                            <div className="btn-circle btn-danger" title={"Supprimer"} onClick={() => this.handleDelete(row._original.id)}>
                                <i className="fa fa-trash"/>
                            </div>
                        </div>
                    )
                }
            }
        ];

        const columnsWait = [
            {
                Header: 'Intitulé',
                accessor: 'name',
                width: localStorage.getItem('fiche-project-steps-name') !== null ? localStorage.getItem('fiche-project-steps-name') : 750,
            },
            {
                Header: 'Utilisateur',
                accessor: 'users',
                width: localStorage.getItem('fiche-project-steps-users') !== null ? localStorage.getItem('fiche-project-steps-users') : 200,
                Cell: ({row}) => {
                    return (row._original.users.map((user, i) => (
                            <li key={i}>{user.firstname} {user.lastname}</li>
                        ))
                    )
                }
            },
            {
                Header: 'Durée',
                accessor: 'hours',
                width: localStorage.getItem('fiche-project-steps-hours') !== null ? localStorage.getItem('fiche-project-steps-hours') : 80,
                Cell: ({row}) => row._original.hours !== null ? row._original.hours + ' h' : ''
            },
            {
                Header: 'Priorité',
                accessor: 'priority',
                width: localStorage.getItem('fiche-project-steps-priority') !== null ? localStorage.getItem('fiche-project-steps-priority') : 80,
                Cell: ({row}) => {
                    switch(row._original.priority) {
                        case 2:
                            return '!!';
                        case 3:
                            return '!!!';
                        case 4:
                            return 'P';
                        case 0:
                            return 'R';
                        default:
                            return '!';
                    }
                }
            },
            {
                Header: 'Commentaire',
                accessor: 'comment',
                width: localStorage.getItem('fiche-project-steps-comment') !== null ? localStorage.getItem('fiche-project-steps-comment') : 450,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                width: 225,
                resizable: false,
                filterable: false,
                sortable: false,
                Cell: ({ row }) => {
                    return (
                        <div className={"btn-flex"}>
                            <div className="btn-circle btn-warning" title={"Modifier"} onClick={() => this.handleOpenModal(row._original.id)}>
                                <i className="fa fa-edit"/>
                            </div>
                            <div className="btn-circle btn-secondary" title={"Déplacer dans les tâches en cours"} onClick={() => this.handleWait(row._original.id)}>
                                <i className="fa fa-pause"/>
                            </div>
                            {row._original.made === null ?
                                <div className="btn-circle btn-success" title={"Réaliser"} onClick={() => this.handleMade(row._original.id)}>
                                    <i className="fa fa-check"/>
                                </div>
                                :
                                <div className="btn-circle btn-danger" title={"Marquer comme non réalisée"} onClick={() => this.handleMade(row._original.id)}>
                                    <i className="fa fa-check"/>
                                </div>
                            }
                            <div className="btn-circle btn-primary" title={"Déplacer dans les tâches en cours"} onClick={() => this.handleFinish(row._original.id)}>
                                <i className="fa fa-arrow-left"/>
                            </div>
                            <div className="btn-circle btn-danger" title={"Supprimer"} onClick={() => this.handleDelete(row._original.id)}>
                                <i className="fa fa-trash"/>
                            </div>
                        </div>
                    )
                }
            }
        ];

        const steps = this.props.project.steps//this.props.steps.filter(step => step.project !== null && step.project.id === this.props.project.id);

        return (
            <div className={"block"}>
                <HeaderBlock title={"Tâches"}
                             handleVisibility={this.handleVisibility}
                             handleOpenModal={this.handleOpenModal}
                             show={this.state.show}
                             addButtonActive={true}
                             addButtonTitle={"Ajouter une tâche"}/>

                <Tabs>
                    <TabList>
                        <Tab>Tâches en cours</Tab>
                        <Tab>Tâches terminées</Tab>
                        <Tab>Tâches prévisionnelles</Tab>
                    </TabList>

                    <TabPanel>
                        <SimpleTable
                            style={this.state.show === true ? {display: 'block'} : {display: 'none'}}
                            data={steps.filter((step) => step.finished === null &&  step.waiting === false)}
                            columns={columnsTodo}
                            filterable={false}
                            sortable={false}
                            multiSort={false}
                            defaultPageSize={25}
                            frenchName={"tâches"}
                            localName={"fiche-project-stepsDo"}
                            apiName={"steps"}
                            selected={this.state.selected}
                            selectRow={id => this.selectRow(id)}
                            defaultSorted={[{id: "planned", desc: false}]}
                        />
                    </TabPanel>
                    <TabPanel>
                        <SimpleTable
                            style={this.state.show === true ? {display: 'block'} : {display: 'none'}}
                            data={steps.filter((step) => step.finished !== null &&  step.waiting === false)}
                            columns={columnsDo}
                            filterable={false}
                            sortable={false}
                            multiSort={false}
                            defaultPageSize={25}
                            frenchName={"tâches"}
                            localName={"steps"}
                            apiName={"steps"}
                            selected={this.state.selectedFinished}
                            selectRow={id => this.selectRowFinished(id)}
                            defaultSorted={[{id: "planned", desc: false}]}
                        />
                    </TabPanel>
                    <TabPanel>
                        <SimpleTable
                            style={this.state.show === true ? {display: 'block'} : {display: 'none'}}
                            data={steps.filter(step => step.waiting === true)}
                            columns={columnsWait}
                            filterable={false}
                            sortable={false}
                            multiSort={false}
                            defaultPageSize={25}
                            frenchName={"tâches"}
                            localName={"steps"}
                            apiName={"steps"}
                            selected={this.state.selectedWaiting}
                            selectRow={id => this.selectRowWaiting(id)}
                            defaultSorted={[{id: "planned", desc: false}]}
                        />
                    </TabPanel>
                </Tabs>

                <CommentModal id={this.state.showModalComment.id} show={this.state.showModalComment.show} setShow={id => this.handleModalComment(id)}/>

                <StepsForm
                    modalIsOpen={this.state.modalIsOpen}
                    handleCloseModal={this.handleCloseModal}
                    changeHandler={this.changeHandler}
                    handleSubmit={this.handleSubmit}
                    stepUpdated={this.state.stepUpdated}
                    stepSettings={this.props.stepSettings}
                    users={this.props.users}
                    form={this.state.form}
                />
            </div>
        )
    }
}

const mapStateToProps = ({apiReducer}) => {
    return {
        rawToken: apiReducer.rawToken,
        users: apiReducer.users,
        steps: apiReducer.steps,
        stepSettings: apiReducer.stepSettings,
    }
};

export default connect(mapStateToProps)(Steps)
