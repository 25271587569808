import React, {Component} from 'react';
import {connect} from "react-redux";
import { withRouter } from "react-router";

import "../../css/form.scss"
import "../../css/pages/accounting/accounting.scss"
import {
    createResource,
    deleteResource,
    getNewNum, getNewNumWrited,
    getResource,
    getResources,
    updateResource
} from "../../redux/action"

import {
    addGroupForm,
    addMeasure,
    addMeasureForm, addProduct, addProductForm,
    changeQuotation, changeTotalMeasure, checkFormValidity, deleteGroupForm,
    deleteMeasure,
    deleteMeasureForm, deleteProduct, deleteProductForm, resetQuotationForm
} from "../../redux/QuotationFormAction"
import QuotationsFormModel from "../quotations/QuotationsFormModel";
import FormTitle from "../accounting/FormTitle";
import MainForm from "../accounting/MainForm";
import GroupInputForm from "../accounting/Products/GroupInputForm";
import QuotationMeasuresForm from "../accounting/Measures/QuotationMeasuresForm";
import MeasuresForm from "../accounting/Measures/MeasuresForm";
import ProductForm from "../accounting/Products/ProductForm";
import SubcontractingForm from "../accounting/Products/SubcontractingForm";
import {onSaved} from "../../utils/Alert";
import AllowanceForm from "./Products/GroupInputForm";
import ShapingForm from "./Products/ShapingForm";
import AdhesiveForm from "./Products/AdhesiveForm";
import TechnicalCostsForm from "./Products/TechnicalCostsForm";
import MovementForm from "./Products/MovementForm";
import SuppliesForm from "./Products/SuppliesForm";
import PoseForm from "./Products/PoseForm";
import SavedButtons from "./SavedButtons";
import {isDefined, sortGroupProduct} from "../../utils/Data";
import {datimeToDate, getDateFormatedEN} from "../../utils/Date";
import AccountingNote from "./AccountingNote"

class GeneralForm extends Component {
    constructor(props) {
        super(props);

        const pathname  = window.location.pathname;
        const split     = pathname.split('/');
        const id        = split[2];

        this.state = {
            id: id,
            action: split[3],
            resource: null,
            modelSelected: false,
            show: localStorage.getItem(props.name + 'Form-' + id + '-show') !== null ? JSON.parse(localStorage.getItem(props.name + 'Form-' + id + '-show')) : true,
            showModalSaved: false,
        }
    }

    componentDidMount() {
        const name      = this.props.name;
        const dispatch  = this.props.dispatch;
        const action    = this.state.action;

        dispatch(resetQuotationForm()).then(() => {
            dispatch(getResources(name, this.props.rawToken));
            dispatch(getResources('productGroups', this.props.rawToken));
            dispatch(getResources('book_products', this.props.rawToken));

            if(action === 'dupliquer') {
                dispatch(getResource(name, this.state.id, this.props.rawToken))
                    .then((resource) => {
                        this.setState({
                            resource: resource,
                        }, () => {
                            Object.keys(this.props.data).map((input) => input !== 'number' ? this.resourceToInput(input) : null);
                            if(name === 'quotations')
                                if(this.state.resource.drafted === null) //SI LE DEVIS INITIAL N'EST PAS REDIGE
                                    dispatch(getNewNum(name, this.props.rawToken))
                                        .then(() => this.itemToInput('number', this.props.newNum));
                                else //SI LE DEVIS INITIAL EST REDIGE
                                    dispatch(getNewNumWrited(name, this.props.rawToken))
                                        .then(() => this.itemToInput('number', this.props.newNum));
                            else
                                dispatch(getNewNumWrited(name, this.props.rawToken))
                                    .then(() => this.itemToInput('number', this.props.newNum));
                        });
                    });
            }

            if((name === 'quotations' && this.state.id === 'creer') || (name === 'bills' && action !== 'modifier'))
                dispatch(getNewNum(name, this.props.rawToken))
                    .then(() => this.itemToInput('number', this.props.newNum));

            if(this.state.id !== 'creer' && action !== 'devis' && action !== 'dupliquer') {
                dispatch(getResource(name, this.state.id, this.props.rawToken))
                    .then((resource) => {
                        this.setState({
                            resource: resource,
                        }, () => {
                            Object.keys(this.props.data).map((input) => this.resourceToInput(input))
                        });
                    });
            }

            if(action === 'devis') {
                dispatch(getNewNum('bills', this.props.rawToken))
                    .then(() => this.itemToInput('number', this.props.newNum));

                dispatch(getResource('quotations', this.state.id, this.props.rawToken))
                    .then((resource) => {
                        this.setState({
                            resource: resource,
                        }, () => {
                            Object.keys(this.props.data).map((input) => input !== 'number' ? this.resourceToInput(input) : null)
                        });
                    });
            }
        });
    }

    componentWillUnmount() {
        this.props.dispatch(resetQuotationForm())
    }

    resourceToInput = (input) => {
        const resource = this.state.resource;

        if(input === 'project') {
            this.itemToInput('project', resource.project !== null ? resource.project.number : '');
            this.itemToInput('projectId', resource.project !== null ? resource.project.id : null);
        }
        else if(input === 'customer') {
            this.itemToInput( 'customer', resource.project !== null ? resource.project.customer !== null ? resource.project.customer.code : '' : '')
            this.itemToInput('customerId', resource.project !== null ? resource.project.customer !== null ? resource.project.customer.id : null : null)
        }
        else if(input === 'customerName') {
            this.itemToInput( 'customerName', resource.customerName !== null ? resource.customerName : resource.project.customer !== null ? resource.project.customer.name : '')
        }
        else if(input === 'deliveryDelay') {
            this.itemToInput( 'deliveryDelay', resource.deliveryDelay !== null ? resource.deliveryDelay.id : '')
        }
        else if(input === 'settlement') {
            this.itemToInput( 'settlement', resource.settlement !== null ? resource.settlement.id : '')
        }
        else if(input === 'measures') {
            this.addMeasureFormWithInput()
        }
        else if(input === 'products') {
            this.addProductFormWithInput()
        }
        else if(input === 'drafted') {
            this.itemToInput( 'drafted', resource.drafted)
        }
        else if(input === 'created' || input === 'sent') {
            if(this.state.action === "devis" || this.state.action === 'dupliquer')
                this.itemToInput( input, getDateFormatedEN(new Date()))
            else
                this.itemToInput( input, datimeToDate(resource[input]))
        }
        else {
            this.itemToInput(input, resource[input] !== null ? resource[input] : '')
        }
    };

    itemToInput = (input, item) => this.props.dispatch(changeQuotation(null, input, item));

    addMeasureFormWithInput = () => {
        if(this.state.resource.measures.length > 0)
            this.state.resource.measures.map((measure, i) => this.addMeasureForm(null, measure, i));
    };

    addMeasureForm = (e, measure = null, i = null) => {
        if(e !== null) {
            e.preventDefault();
            e.persist();
        }

        const dispatch  = this.props.dispatch;
        const id        = i !== null ? i : this.props.measureFormsId;
        let idBase      = null;

        if(measure !== null) {
            idBase      = measure.id;
            measure.id  = id;
        }

        dispatch(addMeasure(id, measure, idBase)).then(() => {
            dispatch(addMeasureForm(
                <QuotationMeasuresForm
                    key={id}
                    id={id}
                    measure={measure}
                    onDelete={id => this.deleteMeasureForm(id, idBase)}
                    changeTotalMeasures={name => this.changeTotalMeasures(name)}
                />
            ))
        });
    };

    deleteMeasureForm = (index, idBase) => {
        this.deleteMeasure(index);
        this.props.dispatch(deleteMeasureForm(index));
        if(idBase !== null && this.state.action !== 'dupliquer' && this.state.action !== 'devis')
            this.props.dispatch(deleteResource('quotationMeasures', idBase, this.props.rawToken))
    };

    deleteMeasure = id => {
        this.props.dispatch(deleteMeasure(id)).then(() => {
            this.changeTotalMeasures(null)
        });
    };

    changeTotalMeasures = name => this.props.dispatch(changeTotalMeasure(name));

    addProductFormWithInput = () => {
        const products          = this.state.resource.products;
        const groupsOfProducts  = products.map(product => product.productGroup);
        const groups            = [...new Set(groupsOfProducts.map(group => group.id))];

        groups.map(group => {
            const grp = groupsOfProducts.find(grp => grp.id === group);
            this.addGroupInputForm2(grp);
        })
    };

    addGroupInputForm2 = (group) => {
        const id = group.id,
            name = group.name;

        this.props.dispatch(addGroupForm(
            id,
            name,
            <GroupInputForm
                id={id}
                key={id}
                onDelete={() => this.deleteGroupInputForm(id, null)}
                products={this.state.resource.products}
                title={name}
                handleVisibility={this.handleVisibility}
                show={this.state.show}
                changeTotalPrice={() => this.changeTotalPrice()}
                canDeleteResource={this.state.action !== 'dupliquer' && this.state.action !== 'devis'}
            />
        ));
    };

    addGroupInputForm = (e, product = null) => {
        let title   = '';
        let id      = null;
        if(e !== null) {
            e.persist();
            id          = parseInt(e.target.value);
            const group = this.props.productGroups.find(group => group.id === id);
            title       = group.name;
        }
        else {
            id      = product.productGroup.id;
            title   = product.productGroup.name;
        }

        if(title !== 'default') {
            const group = this.props.groupInputForms.find(group => group.id === id);
            if(group !== undefined) {
                this.addProductForm(null, group, product);
            }
            else {
                this.props.dispatch(addGroupForm(
                    id,
                    title,
                    <GroupInputForm
                        id={id}
                        key={id}
                        onDelete={() => this.deleteGroupInputForm(id)}
                        products={null}
                        title={title}
                        handleVisibility={this.handleVisibility}
                        show={this.state.show}
                        changeTotalPrice={() => this.changeTotalPrice()}
                    />
                ));
            }
        }

        if(e !== null)
            e.target.value = 'default';
    };

    deleteGroupInputForm = (index) => {
        this.props.dispatch(deleteGroupForm(index));
        const products = this.props.data.products.value.filter(product => product.idGroup === index);
        products.map(product => {
            this.props.dispatch(deleteProduct(product.id));
            if(product !== null && this.state.action !== 'dupliquer' && this.state.action !== 'devis')
                this.props.dispatch(deleteResource('products', product.idBase, this.props.rawToken))
        });
    };

    addProductForm = (e = null, group, product = null) => {
        const id        = isDefined(product) ? product.id * 99 : this.props.productFormsId;
        const idGroup   = group.id;
        const idBase    = isDefined(product) ? product.id : null;

        this.props.dispatch(addProduct(id, idGroup, idBase))
            .then(this.props.dispatch(addProductForm(idGroup, this.renderWithType(group, id, product))));
    };

    deleteProductForm = (index, product) => {
        this.deleteProduct(index, product);
        this.props.dispatch(deleteProductForm(this.props.id, index));
    };

    deleteProduct = (id, product) => {
        this.props.dispatch(deleteProduct(id)).then(this.changeTotalPrice);
        if(product !== null)
            this.props.dispatch(deleteResource('products', product.id, this.props.rawToken))
    };

    renderWithType = (group, id, product) => {
        const idGroup = group.id;

        switch (group.name) {
            case 'Produits':
                return <ProductForm
                    key={id}
                    idGroup={idGroup}
                    id={id}
                    product={product}
                    onDelete={() => this.deleteProductForm(id, product)}
                    changeTotalPrice={() => this.changeTotalPrice()}/>;
            case 'Sous traitance':
                return  <SubcontractingForm
                    key={id}
                    idGroup={idGroup}
                    id={id}
                    product={product}
                    onDelete={() => this.deleteProductForm(id, product)}
                    changeTotalPrice={() => this.changeTotalPrice()}/>;
            case 'Prestation':
                return  <AllowanceForm
                    key={id}
                    idGroup={idGroup}
                    id={id}
                    product={product}
                    onDelete={() => this.deleteProductForm(id, product)}
                    changeTotalPrice={() => this.changeTotalPrice()}/>;
            case 'Façonnage':
                return  <ShapingForm
                    key={id}
                    idGroup={idGroup}
                    id={id}
                    product={product}
                    onDelete={() => this.deleteProductForm(id, product)}
                    changeTotalPrice={() => this.changeTotalPrice()}/>;
            case 'Adhésif':
                return <AdhesiveForm
                    key={id}
                    idGroup={idGroup}
                    id={id}
                    product={product}
                    onDelete={() => this.deleteProductForm(id, product)}
                    changeTotalPrice={() => this.changeTotalPrice()}/>;
            case 'Frais techniques':
                return <TechnicalCostsForm
                    key={id}
                    idGroup={idGroup}
                    id={id}
                    product={product}
                    onDelete={() => this.deleteProductForm(id, product)}
                    changeTotalPrice={() => this.changeTotalPrice()}/>;
            case 'Déplacement':
                return <MovementForm
                    key={id}
                    idGroup={idGroup}
                    id={id}
                    product={product}
                    onDelete={() => this.deleteProductForm(id, product)}
                    changeTotalPrice={() => this.changeTotalPrice()}/>;
            case 'Fournitures':
                return <SuppliesForm
                    key={id}
                    idGroup={idGroup}
                    id={id}
                    product={product}
                    onDelete={() => this.deleteProductForm(id, product)}
                    changeTotalPrice={() => this.changeTotalPrice()}/>;
            case 'Pose':
                return <PoseForm
                    key={id}
                    idGroup={idGroup}
                    id={id}
                    product={product}
                    onDelete={() => this.deleteProductForm(id, product)}
                    changeTotalPrice={() => this.changeTotalPrice()}/>;
            default: return;
        }
    };

    changeHandler = (e, newName, newValue, callback) => {
        this.props.dispatch(changeQuotation(e, newName, newValue)).then(() => {
            this.changeTotalPrice();
            if(callback !== undefined)
                callback()
        })
    };

    selectModel = (selected = true) => this.setState({ modelSelected: selected });

    changeTotalPrice = () => {
        let totalPrice = 0;
        this.props.data.products.value.map(product => {
            totalPrice += parseFloat(product.sellingPrice) || 0
        });
        this.props.dispatch(changeQuotation(null, 'totalPrice', totalPrice.toFixed(2))).then(this.changeTotalPriceDiscount());
    };

    changeTotalPriceDiscount = () => {
        const data                  = this.props.data;
        let discountEuros           = data.discountEuros.value;
        let discountPerCentage      = data.discountPerCentage.value;
        const totalPrice            = data.totalPrice.value;
        let totalDiscount           = totalPrice;

        if(discountEuros !== '') {
            //discountPerCentage = (discountEuros * 100) / totalPrice;
            totalDiscount = totalPrice - discountEuros;
        }
        else if(discountPerCentage !== '') {
            discountEuros = totalPrice * (discountPerCentage / 100);
            totalDiscount = totalPrice - discountEuros;
        }

        this.props.dispatch(changeQuotation(null, 'totalPriceDiscount', parseFloat(totalDiscount).toFixed(2)));
    };

    changeState = (value, callback) => {
        this.setState({
            resource: value,
        }, callback);
    };

    addMeasureToQuotation = (resource) => {
        this.props.data.measures.value.map(measure => {
            delete measure.id;
            Object.keys(measure).map(property => {
                if(property !== 'idBase')
                    measure[property] = parseFloat(measure[property])
            });
            const name      = this.props.name === 'quotations' ? 'quotation' : 'bill';
            measure[name]   = resource.id;

            if(this.state.action === 'dupliquer' || this.state.action === 'devis' || this.state.modelSelected === true)
                this.props.dispatch(createResource('quotationMeasures', measure, this.props.rawToken));
            else
            if(measure.idBase === null )
                this.props.dispatch(createResource('quotationMeasures', measure, this.props.rawToken));
            else
                this.props.dispatch(updateResource('quotationMeasures', measure.idBase, measure, this.props.rawToken));
        })
    };

    addProductsToQuotation = (resource) => {
        this.props.data.products.value.map(product => {
            delete product.id;
            const name      = this.props.name === 'quotations' ? 'quotation' : 'bill';
            product[name]   = resource.id;
            const action = this.state.action;

            if(action === 'dupliquer' || action === 'devis' || this.state.modelSelected === true)
                this.props.dispatch(createResource('products', product, this.props.rawToken));
            else
            if(product.idBase === null)
                this.props.dispatch(createResource('products', product, this.props.rawToken));
            else
                this.props.dispatch(updateResource('products', product.idBase, product, this.props.rawToken));
        })
    };

    addArticleToQuotation = (resource) => {
        if(this.state.resource !== null)
            if(this.state.resource.articles.length > 0) {
                this.state.resource.articles.map(article => {
                    delete article.id;
                    const name = this.props.name === 'quotations' ? 'quotation' : 'bill';
                    article[name] = resource.id;
                    this.props.dispatch(createResource('articles', article, this.props.rawToken));
                })
            }
    };

    prepareSubmit = () => {
        const data = this.props.data;

        let body = {};
        Object.keys(data).map((input) => body[input] = data[input].value);

        body.project            = body.projectId;

        body.advance            = parseFloat(body.advance);
        body.discountPerCentage = parseFloat(body.discountPerCentage);
        body.discountEuros      = parseFloat(body.discountEuros);
        body.totalPrice         = parseFloat(body.totalPriceDiscount);

        if(body.created === '')
            delete body.created;
        if(body.sent === '')
            delete body.sent;

        if(body.deliveryDelay === '')
            delete body.deliveryDelay;
        if(body.settlement === '')
            delete body.settlement;

        delete body.products;
        delete body.measures;

        let resourceId = this.state.id;

        if(this.state.resource !== null)
            body.drafted = this.state.resource.articles.length > 0 ? new Date() : null;

        if(this.state.action === "devis" || this.state.action === "dupliquer") {
            body.quotation = this.state.id;
        }

        if(this.state.action === "devis" && isDefined(body.created) === false) {
            body.created = new Date()
        }

        if(this.state.action === "dupliquer" && this.props.name === "bills") {
            body.quotation = this.state.resource.quotation !== null ? this.state.resource.quotation.id : null;
        }

        const link = this.props.name === 'quotations' ? "devis" : "factures";

        return {
            resourceId,
            body,
            link,
        }
    };

    afterSubmit = (redirect, write, resourceId, link) => {
        if(redirect === true) {
            this.props.dispatch(resetQuotationForm());
            write === true ? this.props.history.push("/" + link + "/" + resourceId + "/rediger") : this.props.history.goBack();
        }
        else {
            this.props.dispatch(resetQuotationForm());
            onSaved("/" + link + "/" + resourceId + "/modifier")
        }
    };

    addSubResources = (resource, addArticle = false, redirect, write, resourceId, link) => {
        if(addArticle === true)
            Promise.all([
                this.addMeasureToQuotation(resource),
                this.addProductsToQuotation(resource),
                this.addArticleToQuotation(resource)
            ]).then(() => this.afterSubmit(redirect, write, resourceId, link));
        else
            Promise.all([
                this.addMeasureToQuotation(resource),
                this.addProductsToQuotation(resource)
            ]).then(() => this.afterSubmit(redirect, write, resourceId, link));
    };

    handleSubmit = (e, write = false, redirect = true) => {
        e.preventDefault();

        if(this.props.dispatch(checkFormValidity(this.props.data))) {
            const submit        = this.prepareSubmit();
            const body          = submit.body;
            const link          = submit.link;
            let resourceId      = submit.resourceId;

            if(this.state.action !== 'modifier') {
                this.props.dispatch(createResource(this.props.name, body, this.props.rawToken))
                    .then((resource) => {
                        resourceId = resource.id;
                        this.addSubResources(resource, true, redirect, write, resourceId, link)
                    })
            }
            else {
                this.props.dispatch(updateResource(this.props.name, this.state.id, body, this.props.rawToken))
                    .then((resource) =>  this.addSubResources(resource, false, redirect, write, resourceId, link))
            }
        }
    };


    handleVisibility = (stateName, localStorageName) => {
        this.setState({
            [stateName]: !this.state[stateName]
        });
        localStorage.setItem(localStorageName + '-' + this.state.id + '-show', this.state[stateName] === true ? 'false' : 'true');
    };

    render() {
        const data = this.props.data;
        return (
            <div className={"box accounting"}>
                <FormTitle
                    id={this.state.id}
                    name={this.props.name === 'quotations' ? 'un devis' : 'une facture'}
                    localName={'quotationForm'}
                    entity={this.state.resource}
                    action={this.state.action}
                    show={this.state.show}
                    handleVisibility={() => this.handleVisibility('show', this.props.name + 'Form')}
                />
                <div className={"box-body"}>
                    <QuotationsFormModel
                        type={this.props.name}
                        resourceToInput={input =>this.resourceToInput(input)}
                        changeState={(value, callback) => this.changeState(value, callback)}
                        changeHandler={(e, newName, newValue, callback) => this.changeHandler(e, newName, newValue, callback)}
                        selectModel={this.selectModel}
                    />
                    <form onSubmit={this.handleSubmit}>
                        <MainForm
                            name={this.props.singularName}
                            data={data}
                            show={this.state.show}
                            changeHandler={this.changeHandler}
                        />

                        <AccountingNote/>

                        <MeasuresForm
                            id={this.state.id}
                            addMeasureForm={(e, measure, i) => this.addMeasureForm(e, measure, i)}
                            deleteMeasureForm={i => this.deleteMeasureForm(i)}
                            deleteMeasure={i => this.deleteMeasure(i)}
                            changeTotalMeasures={name => this.changeTotalMeasures(name)}
                        />

                        <div className={"form-block"}>
                            <select className={"select-products-groups"} name={"productTypes"} onChange={this.addGroupInputForm}>
                                <option value={"default"}>Ajouter</option>
                                {sortGroupProduct(this.props.productGroups).map((group, i) => <option key={i} value={group.id}>{group.name}</option>)}
                            </select>

                            {sortGroupProduct(this.props.groupInputForms).map(group => {
                                return group.form
                            })}
                        </div>

                        <SavedButtons id={this.state.id} data={data} action={this.state.action} handleSubmit={this.handleSubmit}/>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({apiReducer, quotationFormReducer}) => {
    return {
        rawToken: apiReducer.rawToken,
        newNum: apiReducer.newNum,
        projects: apiReducer.projects,
        productTypes: apiReducer.productTypes,
        productGroups: apiReducer.productGroups,
        data: quotationFormReducer.form,
        measureForms: quotationFormReducer.measureForms,
        measureTotal: quotationFormReducer.measureTotal,
        measureFormsId: quotationFormReducer.measureFormsId,
        groupInputFormsId: quotationFormReducer.groupInputFormsId,
        groupInputForms: quotationFormReducer.groupInputForms,
    }
};

export default withRouter(connect(mapStateToProps)(GeneralForm))
