import React, {useEffect} from "react"
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from "react-redux"
import DocumentTitle from 'react-document-title'

import {title} from "../../security/layout"
import {isDefined} from "../../utils/Data"
import {getMessagesOfUser} from "../../redux/action";


const Page = (props) => {
    const dispatch          = useDispatch()
    const {user, rawToken}  = useSelector(({apiReducer}) => apiReducer)

    useEffect(() => {
        if(user !== null)
            dispatch(getMessagesOfUser(user.id, rawToken))
    }, [])

    return (
        <DocumentTitle title={(isDefined(props.siteTitle) ? props.siteTitle : props.title) + title}>
            <div className={"box " + props.className}>
                <div className={"box-header"}>
                    <div className={"title"}>{props.title}</div>

                    <div className={"btn-header-right"}>
                        {props.headerButtons}
                    </div>
                </div>

                <div className={"box-body"}>
                    {props.children}
                </div>
            </div>
        </DocumentTitle>
    )
}

Page.propTypes = {
    children:   PropTypes.node,
    siteTitle:  PropTypes.string,
    title:      PropTypes.string,
    className:  PropTypes.string,
    headerButtons: PropTypes.node,
}

export default Page
