import React from 'react';

const ProductHeader = ({title, show}) => {
    const renderWithTitle = () => {
        switch (title) {
            case 'Produits':
                return (
                    <>
                        <th>Référence</th>
                        <th>Support</th>
                        <th>Format</th>
                        <th>Surface</th>
                        <th>Quantité</th>
                        <th>Surface Totale</th>
                        <th>H.A m²/mL</th>
                        <th>H.A total</th>
                        <th>Coef. marge</th>
                        <th>Prix vente</th>
                        <td/>
                    </>
                );
            case 'Sous traitance':
                return (
                    <>
                        <th>Référence</th>
                        <th>Sous traitance</th>
                        <th>Quantité</th>
                        <th>H.A m²/mL</th>
                        <th>H.A total</th>
                        <th>Coef. marge</th>
                        <th>Prix vente</th>
                        <td/>
                    </>
                );
            case 'Prestation':
                return (
                    <>
                        <th>Référence</th>
                        <th>Prestation</th>
                        <th>Quantité</th>
                        <th>Tarif</th>
                        <th>Prix vente</th>
                        <th/>
                    </>
                );
            case 'Façonnage':
                return (
                    <>
                        <th>Référence</th>
                        <th>Façonnage</th>
                        <th>Quantité</th>
                        <th>H.A m²/mL</th>
                        <th>H.A total</th>
                        <th>Coef. marge</th>
                        <th>Prix vente</th>
                        <th/>
                    </>
                );
            case 'Adhésif':
                return (
                    <>
                        <th>Référence</th>
                        <th>Adhésifs</th>
                        <th>Surface</th>
                        <th>Quantité</th>
                        <th>Surface Totale</th>
                        <th>H.A m²/mL</th>
                        <th>H.A total</th>
                        <th>Coef. marge</th>
                        <th>Prix vente</th>
                        <th/>
                    </>
                );
            case 'Frais techniques':
                return (
                    <>
                        <th>Référence</th>
                        <th>Frais techniques</th>
                        <th>Heures</th>
                        <th>Minutes</th>
                        <th>Tarif horaire</th>
                        <th>Prix vente</th>
                        <th/>
                    </>
                );
            case 'Déplacement':
                return (
                    <>
                        <th>Référence</th>
                        <th>Déplacement</th>
                        <th>Quantité</th>
                        <th>Tarif unique</th>
                        <th>Coût/jour</th>
                        <th>Nb jours</th>
                        <th>Prix vente</th>
                        <th/>
                    </>
                );
            case 'Fournitures':
                return (
                    <>
                        <th>Référence</th>
                        <th>Fourniture</th>
                        <th>Quantité</th>
                        <th>H.A m²/mL</th>
                        <th>H.A total</th>
                        <th>Coef. marge</th>
                        <th>Prix vente</th>
                        <th/>
                    </>
                );
            case 'Pose':
                return (
                    <>
                        <th>Référence</th>
                        <th>Pose</th>
                        <th>Quantité</th>
                        <th>H.A m²/mL</th>
                        <th>H.A total</th>
                        <th>Coef. marge</th>
                        <th>Prix vente</th>
                        <th/>
                    </>
                );
            default: break;
        }
    };

    return (
        <tr className={"products-row products-row-header"} style={show ? {display: 'table-row'} : {display: 'none'}}>
            {renderWithTitle()}
        </tr>
    )
}

export default ProductHeader
