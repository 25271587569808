import {createResource, deleteResource, ERROR, resetResource, updateResource} from "../../action"
import {isDefined} from "../../../utils/Data"
import {datimeToDate} from "../../../utils/Date"

export const
    CHANGE_PROPERTY     = 'CHANGE_PROPERTY',
    CHANGE_PROVIDER     = "CHANGE_PROVIDER",
    RESET               = 'RESET',
    ADD_PRODUCT_ORDERS_FORM = "ADD_PRODUCT_ORDERS_FORM",
    ADD_PRODUCT_ORDERS_TO_ROW   = "ADD_PRODUCT_ORDERS_TO_ROW",
    DELETE_PRODUCT_ORDERS_FORM  = "DELETE_PRODUCT_ORDERS_FORM",
    CHANGE_PRODUCT_ORDERS_FORM  = "CHANGE_PRODUCT_ORDERS_FORM",
    RESOURCE_TO_FORM    = "RESOURCE_TO_FORM",
    CHANGE_USER         = "CHANGE_USER"


export const changeProperty = (name, value) => dispatch => {
    if(name === "delivery" && typeof value === "string")
        value = value === "true"

    dispatch({
        type: CHANGE_PROPERTY,
        name: name,
        value: value,
    });
}

export const changeProvider = (id) => dispatch => {
    dispatch({
        type: CHANGE_PROVIDER,
        id
    });
}

export const productsOrdersToForm = productsOrders => {
    let array = []
    productsOrders.forEach(product => {
        const newProduct = {
            ...product,
            product: isDefined(product.product) ? product.product.id : null,
        }
        array.push(newProduct)
    })
    return array
}

export const resourceToForm = resource => dispatch => {
    if (isDefined(resource)) {
        try {
            dispatch({
                type: RESOURCE_TO_FORM,
                resource
            })

            Object.keys(resource).forEach(property => {
                if(isDefined(resource[property])) {
                    if(property === "provider") {
                        dispatch(changeProvider(resource.provider.id))
                        dispatch(changeProperty(property, resource.provider.name))
                    }
                    else if(property === "user") {
                        dispatch(changeUser(resource.user))
                    }
                    else if(property === "ordersDate") {
                        dispatch(changeProperty(property, datimeToDate(resource.ordersDate)))
                    }
                    else if(property === "productsOrders") {
                        dispatch(changeProperty(property, productsOrdersToForm(resource.productsOrders)))
                    }
                    else
                        dispatch(changeProperty(property, resource[property]))
                }
            })
        }
        catch (e) {
            dispatch({
                type: ERROR,
                e
            });
        }
    }
}

export const submit = (id, reducer, orderUpdated, rawToken, callback = null) => dispatch => {
    const resource  = {...reducer.data, provider: reducer.provider}

    try {
        if(id === 'creer') {
            dispatch(submitCreate(id, resource, rawToken, callback))
        }
        else {
            dispatch(submitUpdate(id, resource, orderUpdated, rawToken, callback))
        }
    }
    catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
}

export const submitCreate = (id, data, rawToken, callback = null) => async dispatch => {
    const products = data.productsOrders
    delete data.productsOrders
    dispatch(createResource('orders', data, rawToken))
        .then(async res => {
            await Promise.all([
                products.forEach(article => {
                    const productsOrders = getProductToSubmit(article, res)
                    return dispatch(createResource('productsOrders', productsOrders, rawToken))
                })
            ])

            if(callback !== null)
                callback()
        })
}

export const submitUpdate = (id, data, orderUpdated, rawToken, callback = null) => async dispatch => {
    const products = data.productsOrders
    delete data.productsOrders

    dispatch(updateResource('orders', id, data, rawToken))
        .then(async () => {
           orderUpdated.productsOrders.forEach(oldProduct => {
               if(products.find(newProduct => newProduct.id === oldProduct.id) === undefined)
                   dispatch(deleteResource("productsOrders", oldProduct.id, rawToken))
           })

            products.forEach(newProduct => {
                if(orderUpdated.productsOrders.find(oldProduct => newProduct.id === oldProduct.id) === undefined)
                    delete newProduct.id
            })

            await Promise.all([
                products.map(article => {
                    const productsOrders = getProductToSubmit(article, data, true)

                    return isDefined(article.id) ?
                         dispatch(updateResource('productsOrders', article.id, productsOrders, rawToken))
                    : dispatch(createResource('productsOrders', productsOrders, rawToken))
                })
            ])

            if(callback !== null)
                callback()
        })
}

export const getProductToSubmit = (article, data, isUpdating = false) => {
    if(isUpdating === false)
        delete article.id

    delete article.totalSurface

    return {
        ...article,
        unitPrice: parseFloat(article.unitPrice),
        surface: parseFloat(article.surface),
        quantity: parseFloat(article.quantity),
        orders: data.id,
    }
}

export const reset = () => dispatch => {
    dispatch({ type: RESET });
    dispatch(resetResource())
}

export const addProduct = () => dispatch => {
    try {
        dispatch({
            type: ADD_PRODUCT_ORDERS_FORM,
        })
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        })
    }
}

export const addProductToRow = (product, products, row) => dispatch => {
    console.log(product, products, row)
    try {
        dispatch({
            type: ADD_PRODUCT_ORDERS_TO_ROW,
            product: product,
            row: row
        })
    } catch (e) {
        console.log(e)

        dispatch({
            type: ERROR,
            e
        })

        return false
    }
}

export const deleteProduct = (product) => dispatch => {
    try {
        dispatch({
            type: DELETE_PRODUCT_ORDERS_FORM,
            product
        })
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        })
    }
}

export const changeProduct = (id, name, value) => dispatch => {
    try {
        dispatch({
            type: CHANGE_PRODUCT_ORDERS_FORM,
            id: id,
            name: name,
            value: value,
        })
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        })
    }
}

export const changeUser = (user) => dispatch => {
    dispatch({
        type: CHANGE_USER,
        user
    });
}

