import React, {Component} from 'react';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import HeaderBlock from "../../../components/HeaderBlock";
import {isDefined} from "../../../utils/Data";

class Contacts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: localStorage.getItem('fiche-project-contacts-show') !== null ? JSON.parse(localStorage.getItem('fiche-project-contacts-show')) : true,
        }
    }

    handleVisibility = () => {
        this.setState(prevState => {
            return {
                show: !prevState.show
            }
        });
        localStorage.setItem('fiche-project-contacts-show', this.state.show === true ? 'false' : 'true');
    };

    render() {
        const columns = [
            {
                Header: 'Prénom',
                accessor: 'firstname',
                width: localStorage.getItem('fiche-project-contacts-firstname') !== null ? localStorage.getItem('fiche-project-contacts-firstname') : 300,
                Cell: ({row}) => isDefined(row._original.firstname) ? row._original.firstname : ''
            },
            {
                Header: 'Nom',
                accessor: 'lastname',
                width: localStorage.getItem('fiche-project-contacts-lastname') !== null ? localStorage.getItem('fiche-project-contacts-lastname') : 300,
                Cell: ({row}) => isDefined(row._original.lastname) ? row._original.lastname : ''
            },
            {
                Header: 'Fixe',
                accessor: 'homePhone',
                width: localStorage.getItem('fiche-project-contacts-homePhone') !== null ? localStorage.getItem('fiche-project-contacts-homePhone') : 300
            },
            {
                Header: 'Portable',
                accessor: 'phone',
                width: localStorage.getItem('fiche-project-contacts-phone') !== null ? localStorage.getItem('fiche-project-contacts-phone') : 150
            },
            {
                Header: 'Email',
                accessor: 'email',
                width: localStorage.getItem('fiche-project-contacts-email') !== null ? localStorage.getItem('fiche-project-contacts-email') : 400,
                Cell: ({row}) => <a href={"mailto:" + row._original.email}>{row._original.email}</a>
            },
            {
                Header: 'Fax',
                accessor: 'fax',
                width: 150,
                resizable: false,
            }
        ];
        const contacts = this.props.contacts;

        return (
            <div className={"block"}>
                <HeaderBlock title={"Contacts"} handleVisibility={this.handleVisibility} show={this.state.show}/>

                <ReactTable
                    style={this.state.show === true ? {display: 'block'} : {display: 'none'}}
                    data={contacts}
                    columns={columns}
                    keyField={"id"}
                    showPagination={false}
                    showPageJump={false}
                    sortable={false}
                    multiSort={false}
                    defaultPageSize={contacts.length}
                    loadingText={"Chargement ..."}
                    noDataText={"Aucun contacts"}
                    onResizedChange={(newResized, event) => {
                        newResized.map((newResize) =>
                            localStorage.setItem('fiche-project-contacts-' + newResize.id, newResize.value)
                        )
                    }}
                />
            </div>
        )
    }
}

export default Contacts
