import {ERROR, GET_RESOURCES, UPDATE_RESOURCE, uri} from "./action";
import {getDateOfDateJS} from "../utils/Date";

export const
    GET_WEEK_DAYS       = 'GET_WEEK_DAYS',
    FORWARD_DAY         = 'FORWARD_DAY',
    FORWARD_WEEK        = 'FORWARD_WEEK',
    PREVIOUS_DAY        = 'PREVIOUS_DAY',
    PREVIOUS_WEEK       = 'PREVIOUS_WEEK'
;

export const getStepsForWeek = (date, rawToken) => async dispatch => {
    try {
        const url = uri + '/stepsOfSpecialWeek/' + getDateOfDateJS(date);
        const res = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + rawToken
            },
        });

        if (res.status >= 200 && res.status <= 201) {
            const data = await res.json();

            dispatch({
                type: GET_RESOURCES,
                name: "steps",
                resources: data
            });

            return data;
        }
        return null;
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        })
    }
}

export const getWeekDays = () => dispatch => {
    try {
        dispatch({
            type: GET_WEEK_DAYS,
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const forwardDay = () => dispatch => {
    try {
        dispatch({
            type: FORWARD_DAY,
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const previousDay = () => dispatch => {
    try {
        dispatch({
            type: PREVIOUS_DAY,
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const forwardWeek = () => dispatch => {
    try {
        dispatch({
            type: FORWARD_WEEK,
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const previousWeek = () => dispatch => {
    try {
        dispatch({
            type: PREVIOUS_WEEK,
        });
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const changeStep = (id, name, body, rawToken) => async dispatch => {
    try {
        const url = uri + '/' + name + '/' + id;
        const res = await fetch(url, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + rawToken
            },
            body: JSON.stringify(body)
        });

        if (res.status >= 200 && res.status <= 204) {
            const data = await res.json();

            dispatch({
                type: UPDATE_RESOURCE,
                name: name,
                data: data,
                id: id
            });

            return data;
        }
    } catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const refresh = () => async dispatch => {

}
