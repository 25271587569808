import React, {Component} from 'react'
import {connect} from "react-redux"
import { withRouter } from "react-router"

import {createResource, getResource, getResources, updateResource} from "../../redux/action"
import Page from "../../components/Page/Page"

class UserForm extends Component {
    constructor(props) {
        super(props);

        const pathname = window.location.pathname;
        const split = pathname.split('/');

        this.state = {
            id: split[2],
            user: null,
            roles: [],
            form: {
                firstname: {
                    value: ''
                },
                lastname: {
                    value: ''
                },
                email: {
                    value: ''
                },
                password: {
                    value: ''
                },
                phone: {
                    value: ''
                },
                role: {
                    value: ''
                }
            }
        }
    }

    componentDidMount() {
        this.props.dispatch(getResources('roles', this.props.rawToken))

        if(this.state.id !== 'creer') {
            this.props.dispatch(getResource('users', this.state.id, this.props.rawToken))
                .then((user) => {
                    this.setState({
                        user: user,
                    });
                    Object.keys(this.state.form).map((input) => this.userToInput(input));
                });
        }
    }

    userToInput = (input) => {
        if(input === 'role')
            this.setState({
                form: {
                    ...this.state.form,
                    role: {
                        ...this.state.form.role,
                        value: this.state.user.role !== null ? this.state.user.role.id : ''
                    },
                }
            });
        else
            this.setState({
                form: {
                    ...this.state.form,
                    [input]: {
                        ...this.state.form[input],
                        value: this.state.user[input] !== null ? this.state.user[input] : ''
                    },
                }
            });
    };

    changeHandler = e => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            form: {
                ...this.state.form,
                [name]: {
                    ...this.state.form[name],
                    value
                }
            }
        })
    };

    handleSubmit = e => {
        e.preventDefault();

        let body = {};
        Object.keys(this.state.form).map((input) =>
            body[input] = this.state.form[input].value
        );

        if(body.role === '')
            delete body.role

        if(this.state.id === 'creer') {
            this.props.dispatch(createResource('users', body, this.props.rawToken)).then(() => this.props.history.goBack())
        }
        else {
            this.props.dispatch(updateResource('users', this.state.id, body, this.props.rawToken)).then(() => this.props.history.goBack())
        }
    };

    render() {
        return (
            <Page title={this.state.id !== null && this.state.id !== 'creer' ? 'Modification d\'un utilisateur' : 'Création d\'un utilisateur'}>
                <form onSubmit={this.handleSubmit} className={"form-block"}>
                    <div className={"row"}>
                        <div className={"col-12 col-lg-2"}>
                            <label>Nom</label>
                            <input type={"text"} name={"lastname"} placeholder={"Nom"} value={this.state.form.lastname.value} onChange={this.changeHandler}/>
                        </div>
                        <div className={"col-12 col-lg-2"}>
                            <label>Prénom</label>
                            <input type={"text"} name={"firstname"} placeholder={"Prénom"} value={this.state.form.firstname.value} onChange={this.changeHandler}/>
                        </div>
                        <div className={"col-12 col-lg-2"}>
                            <label>N° Téléphone</label>
                            <input type={"text"} name={"phone"} placeholder={"Téléphone"} value={this.state.form.phone.value} onChange={this.changeHandler}/>
                        </div>
                        <div className={"col-12 col-lg-3"}>
                            <label>Adresse mail</label>
                            <input required={true} type={"email"} name={"email"} placeholder={"Adresse mail"} value={this.state.form.email.value} onChange={this.changeHandler}/>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-12 col-lg-3"}>
                            <label>Mot de passe</label>
                            <input required={true} type={"password"} name={"password"} placeholder={"Mot de passe"} value={this.state.form.password.value} onChange={this.changeHandler}/>
                        </div>
                        <div className={"col-12 col-lg-3"}>
                            {/*<input required={true} type={"password"} name={"password"} placeholder={"Mot de passe"} value={this.state.form.password.value} onChange={this.changeHandler}/>*/}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-12 col-lg-3"}>
                            <label>Rôle</label>
                            <select required={true} name={"role"} value={this.state.form.role.value} onChange={this.changeHandler}>
                                {this.props.roles.map((role, i) => <option key={i} value={role.id}>{role.description}</option>)}
                            </select>
                        </div>
                    </div>

                    {this.props.id !== undefined || (this.state.id !== null && this.state.id !== 'creer') ? <button title={"Modifier"}>Modifier</button> :  <button title={"Créer"}>Créer</button>}
                </form>
            </Page>
        )
    }
}

const mapStateToProps = ({apiReducer}) => {
    return {
        rawToken: apiReducer.rawToken,
        roles: apiReducer.roles
    }
};

export default withRouter(connect(mapStateToProps)(UserForm))
