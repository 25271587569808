import React from "react"

import Logo from "../../../images/MarneEnseignes.png"
import {formatDate} from "../../../utils/Date"
import {isDefined, renderIfDefined} from "../../../utils/Data"

const Header = ({reducer, data, total, provider}) => (
    <div className={"header"}>
        <div className="left-header">
            <img className={"logo"} src={Logo} alt={"Logo de l'entreprise Marne Enseignes"}/>
            <div className={"infos"}>
                {data.delivery === false ?
                    <>Z.I.S.E. Rue Maurice Hollande BP 362 <br/> 51689 REIMS CEDEX 02</>
                    :
                    <>Rue Raoul Follereau <br/> 51520 Saint Martin sur le Pré<br/></>
                }
                Tél : 03 26 82 52 95<br/>
                {isDefined(reducer.user) ? <a href={reducer.user.email}>{reducer.user.email}</a> : <a href="mailto:contact@marne-enseignes.fr">contact@marne-enseignes.fr</a>}<br/>
                Contact : {isDefined(reducer.user) ? reducer.user.firstname : "Marne Enseignes"}
            </div>
        </div>
        <div className="right-header">
            {isDefined(provider) ?
            <>
                <h2>{provider.name}</h2>
                <div>
                    Commande N°{data.number}<br/>
                    Effectuée le {formatDate(data.ordersDate)}<br/>
                    Montant total de {parseFloat(total).toFixed(2)} €
                </div>
                <div className={"infos"}>
                    {isDefined(provider.phone) ? ("Tél : " + provider.fax) : ""}<br/>
                    {renderIfDefined(provider.email)}
                </div>
            </>
            : ""}
        </div>
    </div>
)

export default Header
