import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux"

import {getToken} from "../redux/action"
import '../css/security/login.scss'

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: ""
        }
    }

    handleSubmit = e => {
        e.preventDefault();

        const { state = {} } = this.props.location;
        const { prevLocation } = state;

        this.props.dispatch(getToken(
            e.target.elements[0].value,
            e.target.elements[1].value
        )).then(res => {
            if(res.ok)
                this.props.history.push(prevLocation || '/');
            else {
                this.setState({
                    error: res.message
                })
            }
        })
    };

    render() {
        return (
            <div className="login">
                <img className="logo" src="" alt=""/>

                <form onSubmit={this.handleSubmit}>
                    <h1>Connexion</h1>
                    {this.state.error.length > 0 ? <div className={"error"} style={{color: 'red'}}>{this.state.error}</div> : ''}

                    <input type="text" placeholder="Identifiant"/>
                    <input type="password" placeholder="Mot de passe"/>

                    <button className="btn btn-lg btn-primary">Connexion</button>
                </form>

                {/*<div className="right-text"><Link to={'/mot-de-passe-oublie'}>Mot de passe oublié ?</Link></div>*/}
            </div>
        )
    }
}

const mapStateToProps = ({apiReducer}) => {
    return {
        rawToken :  apiReducer.rawToken,
        error:      apiReducer.error,
    };
};

export default withRouter(connect(mapStateToProps)(Login))
