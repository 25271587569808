import React from "react"
import PropTypes from 'prop-types'

export const TextFilter = ({localName, onChange, filter}) => {
    const key = localName + '-filter'

    const onChangeFilter = (event, onChange) => {
        const value = event.target.value
        onChange(value)
        localStorage.setItem(key, value)
    };

    return (<input type={"text"}
                   style={{width: '100%'}}
                   onChange={event => onChangeFilter(event, onChange)}
                   value={filter ? filter.value : ''}/>)
}

TextFilter.propTypes = {
    localName:  PropTypes.string.isRequired,
    onChange:   PropTypes.func.isRequired,
    filter:     PropTypes.object,
}
