import {createResource, ERROR, resetResource, updateResource} from "../../action";

export const
    CHANGE_PROPERTY     = 'CHANGE_PROPERTY',
    RESET               = 'RESET',
    ADD_CONTACT         = 'ADD_CONTACT',
    DELETE_CONTACT      = 'DELETE_CONTACT',
    CHANGE_PROPERTY_CONTACT = 'CHANGE_PROPERTY_CONTACT'
;

export const changeProperty = (name, value) => dispatch => {
    dispatch({
        type: CHANGE_PROPERTY,
        name: name,
        value: value,
    });
};

export const customerToForm = (customer) => dispatch => Object.keys(customer).forEach(property => {
    if(property === 'contacts') {}
    else if(property === 'projects') {}
    else
        dispatch(changeProperty(property, customer[property]))
});

export const submit = (id, data, rawToken, callback = null) => dispatch => {
    try {
        if(id === 'creer')
            dispatch(submitCreate(id, data, rawToken, callback))
        else
            dispatch(submitUpdate(id, data, rawToken, callback))
    }
    catch (e) {
        dispatch({
            type: ERROR,
            e
        });
    }
};

export const submitCreate = (id, data, rawToken, callback = null) => dispatch => {
    dispatch(submitAddress(id, data, rawToken, address => {
        data.address = address !== undefined ? data.address = address.id : null
        dispatch(submitContacts(data.contacts, rawToken)).then(contacts => {
            const body = {...data}
            body.contacts = contacts.map(contact => contact.id)
            dispatch(createResource('customers', body, rawToken))
                .then(() => {
                    if(callback !== null)
                        callback()
                })
        })
    }))
};

export const submitUpdate = (id, data, rawToken, callback = null) => dispatch => {
    dispatch(submitAddress(id, data, rawToken, address => {
        data.address = address !== undefined ? data.address = address.id : null
        dispatch(submitContacts(data.contacts, rawToken)).then(contacts => {
            const body = {...data}
            body.contacts = contacts.map(contact => contact.id)
            dispatch(updateResource('customers', id, body, rawToken))
                .then(() => {
                    if(callback !== null)
                        callback()
                })
        })
    }))
}

export const submitAddress = (id, data, rawToken, callback = null) => dispatch => {
    if(data.address.id !== undefined && data.address.id !== null)
        dispatch(updateResource('adresses', data.address.id, data.address, rawToken)).then(address => callback(address))
    else {
        dispatch(createResource('adresses', data.address, rawToken)).then(address => callback(address))
    }
}

export const submitContacts = (contacts, rawToken) => dispatch => {
    return Promise.all(contacts.map(contact => {
        if(contact.id !== undefined && contact.id !== null)
            return dispatch(updateResource('contacts', contact.id, contact, rawToken))
        else {
            return dispatch(createResource('contacts', contact, rawToken))
        }
    }))
}

export const reset = () => async dispatch => {
    await dispatch(resetResource())
    return await dispatch({ type: RESET })
}

export const addContact = (form, contact) => async dispatch => {
    dispatch({
        type: ADD_CONTACT,
        form: form,
        contact: contact
    });
}

export const deleteContact = id => dispatch => {
    dispatch({
        type: DELETE_CONTACT,
        id
    });
}

export const changePropertyContact = (id, name, value) => dispatch => {
    dispatch({
        type: CHANGE_PROPERTY_CONTACT,
        id: id,
        name: name,
        value: value,
    });
};
