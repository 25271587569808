import React, {Component} from "react"

import "../../../css/pages/orders/print.scss"
import Table from "./Table"
import HeaderContainer from "./HeaderContainer"


class OrdersPrint extends Component {
    render() {
        return (
            <div className={"orders-print"}>
                <HeaderContainer/>
                <Table/>
            </div>
        )
    }
}

export default OrdersPrint
