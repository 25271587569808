import React, {Component} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

import "../../css/pages/quotations/list.scss";
import {deleteResource, specialPut} from "../../redux/action";
import Table from "../../components/Table";
import {getColumnsWidth, getDefaultFiltered} from "../../utils/List";
import {TextFilter} from "../../components/List/index";
import {formatDate} from "../../utils/Date";
import {isDefined} from "../../utils/Data";
import Page from "../../components/Page/Page";
import {onDelete} from "../../utils/Alert";
import {getAdvance, getAmountTTC, getPriceToPaid, getTotalPrice, getTva} from "../../utils/Amounts";

class Quotations extends Component {
    constructor(props) {
        super(props);

        this.frenchName = "brouillons";
        this.localName  = "quotationsDrafted";
        this.apiName    = 'quotationsDrafted';

        this.frenchName2 = "devis";
        this.localName2  = "quotations-writed";
        this.apiName2    = "quotations"

        this.frenchName3 = "modèles";
        this.localName3  = "quotations_models";
        this.apiName3    = "quotations_models"

        this.localNames     = ['number', 'project.number', 'customer.code', 'customer.name', 'name', 'created', 'sent', 'htAmount', 'tvaAmount', 'ttcAmount', 'advance', 'outstandingBalance'];
        this.localNames2    = [...this.localNames];
        this.localNames3    = [...this.localNames];

        this.localNames     = this.localNames.map(name => this.localName + '-' + name);
        this.localNames2    = this.localNames2.map(name => this.localName2 + '-' + name);
        this.localNames3    = this.localNames3.map(name => this.localName3 + '-' + name);

        this.state = {
            tabIndex: isDefined(localStorage.getItem(this.localName + "-tab")) ? parseInt(localStorage.getItem(this.localName + "-tab")) : 0,
            columns: [
                {
                    Header: 'N° Devis',
                    accessor: 'number',
                    width: getColumnsWidth(this.localNames[0], 70)
                },
                {
                    Header: 'Dossier',
                    accessor: 'project.number',
                    width: getColumnsWidth(this.localNames[1], 70),
                    Cell: ({row}) => <Link to={"/projets/" + row._original.project.id}>{row._original.project.number}</Link>
                },
                {
                    Header: 'N°Client',
                    accessor: 'customer.code',
                    width: getColumnsWidth(this.localNames[2], 60),
                    Cell: ({row}) => row._original.project !== null ? row._original.project.customer !== null ? <Link to={"/clients/" + row._original.project.customer.id + "/modifier"}>{row._original.project.customer.code}</Link> : '' : ''
                },
                {
                    Header: 'Client',
                    accessor: 'customer.name',
                    width: getColumnsWidth(this.localNames[3], 320),
                    Cell: ({row}) => row._original.project !== null ? row._original.project.customer !== null ? <Link to={"/clients/" + row._original.project.customer.id + "/modifier"}>
                        {row._original.customerName !== null ? row._original.customerName : row._original.project.customer.name}
                    </Link> : '' : ''
                },
                {
                    Header: 'Intitulé',
                    accessor: 'name',
                    width: getColumnsWidth(this.localNames[4], 450),
                },
                {
                    Header: 'Edité',
                    accessor: 'created',
                    width: getColumnsWidth(this.localNames[5], 95),
                    Cell: ({row}) => formatDate(row._original.created)
                },
                {
                    Header: 'Envoyé',
                    accessor: 'sent',
                    width: getColumnsWidth(this.localNames[6], 95),
                    Cell: ({row}) => formatDate(row._original.sent)
                },
                {
                    Header: 'Montant HT',
                    accessor: 'htAmount',
                    width: getColumnsWidth(this.localNames[7], 100),
                    className: 'text-right',
                    sortable: false,
                    Cell: ({row}) => parseFloat(getTotalPrice(row._original.totalPrice) - row._original.discountEuros).toFixed(2) + ' €'
                },
                {
                    Header: 'TVA',
                    accessor: 'tvaAmount',
                    width: getColumnsWidth(this.localNames[8], 100),
                    className: 'text-right',
                    sortable: false,
                    Cell: ({row}) => getTva(row._original.totalPrice, row._original.discountEuros).toFixed(2) + ' €'
                },
                {
                    Header: 'Montant TTC',
                    accessor: 'ttcAmount',
                    width: getColumnsWidth(this.localNames[9], 100),
                    className: 'text-right',
                    sortable: false,
                    Cell: ({row}) => getAmountTTC(row._original.totalPrice, row._original.discountEuros).toFixed(2) + ' €'
                },
                {
                    Header: 'Acompte',
                    accessor: 'advance',
                    width: getColumnsWidth(this.localNames[10], 100),
                    className: 'text-right',
                    sortable: false,
                    Cell: ({row}) => getAdvance(row._original.advance).toFixed(2) + ' €'
                },
                {
                    Header: 'Solde à régler',
                    accessor: 'outstandingBalance',
                    width: getColumnsWidth(this.localNames[11], 100),
                    className: 'text-right',
                    sortable: false,
                    Cell: ({row}) => getPriceToPaid(row._original.totalPrice, row._original.advance, row._original.discountEuros, row._original.paid).toFixed(2) + ' €'
                },
                {
                    Header: 'Actions',
                    accessor: 'actions',
                    sortable: false,
                    resizable: false,
                    width: 125,
                    Cell: ({ row }) => (
                        <div className={"btn-flex"}>
                            {row._original.drafted !== null ?
                                <Link to={"/factures/" + row._original.id + "/devis"}>
                                    <div className="btn-circle btn-primary" title={"Facturer"}>
                                        <i className="fas fa-pencil-alt"/>
                                    </div>
                                </Link>
                                : ''}
                            <Link to={"/devis/" + row._original.id + "/dupliquer"}>
                                <div className="btn-circle btn-secondary" title={"Dupliquer"}>
                                    <i className="fas fa-copy"/>
                                </div>
                            </Link>
                            <Link to={"/devis/" + row._original.id + "/modifier"}>
                                <div className="btn-circle btn-warning" title={"Modifier"}>
                                    <i className="fa fa-edit"/>
                                </div>
                            </Link>
                            <div onClick={() => this.handleDelete(row._original.id)} className="btn-circle btn-danger" title={"Supprimer"}>
                                <i className="fa fa-trash"/>
                            </div>
                        </div>
                    )
                },
            ],
            columns2: [
                {
                    Header: 'N° Devis',
                    accessor: 'number',
                    width: getColumnsWidth(this.localNames2[0], 70)
                },
                {
                    Header: 'Dossier',
                    accessor: 'project.number',
                    width: getColumnsWidth(this.localNames2[1], 70),
                    Cell: ({row}) => <Link to={"/projets/" + row._original.project.id}>{row._original.project.number}</Link>
                },
                {
                    Header: 'N°Client',
                    accessor: 'customer.code',
                    width: getColumnsWidth(this.localNames2[2], 60),
                    Cell: ({row}) => row._original.project !== null ? row._original.project.customer !== null ? <Link to={"/clients/" + row._original.project.customer.id + "/modifier"}>{row._original.project.customer.code}</Link> : '' : ''
                },
                {
                    Header: 'Client',
                    accessor: 'customer.name',
                    width: getColumnsWidth(this.localNames2[3], 320),
                    Cell: ({row}) => row._original.project !== null ? row._original.project.customer !== null ? <Link to={"/clients/" + row._original.project.customer.id + "/modifier"}>
                        {row._original.customerName !== null ? row._original.customerName : row._original.project.customer.name}
                    </Link> : '' : ''
                },
                {
                    Header: 'Intitulé',
                    accessor: 'name',
                    width: getColumnsWidth(this.localNames2[4], 380),
                },
                {
                    Header: 'Création',
                    accessor: 'created',
                    width: getColumnsWidth(this.localNames2[5], 95),
                    Cell: ({row}) => formatDate(row._original.created)
                },
                {
                    Header: 'Edition',
                    accessor: 'sent',
                    width: getColumnsWidth(this.localNames2[6], 95),
                    Cell: ({row}) => formatDate(row._original.sent)
                },
                {
                    Header: 'Montant HT',
                    accessor: 'htAmount',
                    width: getColumnsWidth(this.localNames2[7], 100),
                    className: 'text-right',
                    sortable: false,
                    Cell: ({row}) => parseFloat(getTotalPrice(row._original.totalPrice) - row._original.discountEuros).toFixed(2) + ' €'
                },
                {
                    Header: 'TVA',
                    accessor: 'tvaAmount',
                    width: getColumnsWidth(this.localNames2[8], 100),
                    className: 'text-right',
                    sortable: false,
                    Cell: ({row}) => getTva(row._original.totalPrice, row._original.discountEuros).toFixed(2) + ' €'
                },
                {
                    Header: 'Montant TTC',
                    accessor: 'ttcAmount',
                    width: getColumnsWidth(this.localNames2[9], 100),
                    className: 'text-right',
                    sortable: false,
                    Cell: ({row}) => getAmountTTC(row._original.totalPrice, row._original.discountEuros).toFixed(2) + ' €'
                },
                {
                    Header: 'Acompte',
                    accessor: 'advance',
                    width: getColumnsWidth(this.localNames2[10], 100),
                    className: 'text-right',
                    sortable: false,
                    Cell: ({row}) => getAdvance(row._original.advance).toFixed(2) + ' €'
                },
                {
                    Header: 'Solde à régler',
                    accessor: 'outstandingBalance',
                    width: getColumnsWidth(this.localNames2[11], 100),
                    className: 'text-right',
                    sortable: false,
                    Cell: ({row}) => getPriceToPaid(row._original.totalPrice, row._original.advance, row._original.discountEuros, row._original.paid).toFixed(2) + ' €'
                },
                {
                    Header: 'Actions',
                    accessor: 'actions',
                    sortable: false,
                    resizable: false,
                    width: 190,
                    Cell: ({ row }) => (
                        <div className={"btn-flex"}>
                            {row._original.drafted !== null ?
                                <Link to={"/factures/" + row._original.id + "/devis"}>
                                    <div className="btn-circle btn-primary" title={"Facturer"}>
                                        <i className="fas fa-pencil-alt"/>
                                    </div>
                                </Link>
                                : ''}
                            <Link to={"/devis/" + row._original.id + "/dupliquer"}>
                                <div className="btn-circle btn-secondary" title={"Dupliquer"}>
                                    <i className="fas fa-copy"/>
                                </div>
                            </Link>
                            <Link to={"/devis/" + row._original.id + "/modifier"}>
                                <div className="btn-circle btn-warning" title={"Modifier"}>
                                    <i className="fa fa-edit"/>
                                </div>
                            </Link>
                            <div onClick={() => this.handleDelete(row._original.id)} className="btn-circle btn-danger" title={"Supprimer"}>
                                <i className="fa fa-trash"/>
                            </div>
                        </div>
                    )
                },
            ],
            columns3: [
                {
                    Header: 'N° Devis',
                    accessor: 'number',
                    width: getColumnsWidth(this.localNames3[0], 70)
                },
                {
                    Header: 'Dossier',
                    accessor: 'project.number',
                    width: getColumnsWidth(this.localNames3[1], 70),
                    Cell: ({row}) => <Link to={"/projets/" + row._original.project.id}>{row._original.project.number}</Link>
                },
                {
                    Header: 'N°Client',
                    accessor: 'customer.code',
                    width: getColumnsWidth(this.localNames3[2], 80),
                    Cell: ({row}) => row._original.project !== null ? row._original.project.customer !== null ? <Link to={"/clients/" + row._original.project.customer.id + "/modifier"}>{row._original.project.customer.code}</Link> : '' : ''
                },
                {
                    Header: 'Client',
                    accessor: 'customer.name',
                    width: getColumnsWidth(this.localNames3[3], 420),
                    Cell: ({row}) => row._original.project !== null ? row._original.project.customer !== null ? <Link to={"/clients/" + row._original.project.customer.id + "/modifier"}>
                        {row._original.customerName !== null ? row._original.customerName : row._original.project.customer.name}
                    </Link> : '' : ''
                },
                {
                    Header: 'Intitulé',
                    accessor: 'name',
                    width: getColumnsWidth(this.localNames3[4], 450),
                },
                {
                    Header: 'Montant HT',
                    accessor: 'htAmount',
                    width: getColumnsWidth(this.localNames3[7], 100),
                    className: 'text-right',
                    sortable: false,
                    Cell: ({row}) => parseFloat(getTotalPrice(row._original.totalPrice) - row._original.discountEuros).toFixed(2) + ' €'
                },
                {
                    Header: 'TVA',
                    accessor: 'tvaAmount',
                    width: getColumnsWidth(this.localNames3[8], 100),
                    className: 'text-right',
                    sortable: false,
                    Cell: ({row}) => getTva(row._original.totalPrice, row._original.discountEuros).toFixed(2) + ' €'
                },
                {
                    Header: 'Montant TTC',
                    accessor: 'ttcAmount',
                    width: getColumnsWidth(this.localNames3[9], 100),
                    className: 'text-right',
                    sortable: false,
                    Cell: ({row}) => getAmountTTC(row._original.totalPrice, row._original.discountEuros).toFixed(2) + ' €'
                },
                {
                    Header: 'Acompte',
                    accessor: 'advance',
                    width: getColumnsWidth(this.localNames3[10], 100),
                    className: 'text-right',
                    sortable: false,
                    Cell: ({row}) => getAdvance(row._original.advance).toFixed(2) + ' €'
                },
                {
                    Header: 'Solde à régler',
                    accessor: 'outstandingBalance',
                    width: getColumnsWidth(this.localNames3[11], 100),
                    className: 'text-right',
                    sortable: false,
                    Cell: ({row}) => getPriceToPaid(row._original.totalPrice, row._original.advance, row._original.discountEuros, row._original.paid).toFixed(2) + ' €'
                },
                {
                    Header: 'Actions',
                    accessor: 'actions',
                    sortable: false,
                    resizable: false,
                    width: 190,
                    Cell: ({ row }) => (
                        <div className={"btn-flex"}>
                            {row._original.drafted !== null ?
                                <Link to={"/factures/" + row._original.id + "/devis"}>
                                    <div className="btn-circle btn-primary" title={"Facturer"}>
                                        <i className="fas fa-pencil-alt"/>
                                    </div>
                                </Link>
                                : ''}
                            <Link to={"/devis/" + row._original.id + "/dupliquer"}>
                                <div className="btn-circle btn-secondary" title={"Dupliquer"}>
                                    <i className="fas fa-copy"/>
                                </div>
                            </Link>
                            <Link to={"/devis/" + row._original.id + "/modifier"}>
                                <div className="btn-circle btn-warning" title={"Modifier"}>
                                    <i className="fa fa-edit"/>
                                </div>
                            </Link>
                            <div onClick={() => this.handleDelete(row._original.id)} className="btn-circle btn-danger" title={"Supprimer"}>
                                <i className="fa fa-trash"/>
                            </div>
                        </div>
                    )
                },
            ]
        }
    }

    componentDidMount() {
        this.state.columns.forEach((column, i) => {
            if(i < this.state.columns.length)
                column.Filter = ({filter, onChange}) => <TextFilter localName={this.localNames[i]} filter={filter} onChange={onChange}/>
        })
        this.state.columns2.forEach((column, i) => {
            if(i < this.state.columns2.length)
                column.Filter = ({filter, onChange}) => <TextFilter localName={this.localNames2[i]} filter={filter} onChange={onChange}/>
        })
        this.state.columns3.forEach((column, i) => {
            if(i < this.state.columns3.length)
                column.Filter = ({filter, onChange}) => <TextFilter localName={this.localNames3[i]} filter={filter} onChange={onChange}/>
        })
    }

    handleAccepted = id => this.props.dispatch(specialPut(id, this.props.rawToken, 'quotations', 'accept'));

    handleDelete = id => onDelete('', 'Etes-vous sûr de vouloir supprimer ce devis ?', () => this.props.dispatch(deleteResource('quotations', id, this.props.rawToken)))

    selectTab = index => {
        this.setState({ tabIndex : index });
        localStorage.setItem(this.localName + '-tab', index);
    };

    buttonsRight = () => (
        <Link to={"/devis/creer"}>
            <button className={"btn-circle add-resource"} title={"Ajouter"}>
                <i className={"fas fa-plus"} />
            </button>
        </Link>
    )

    render() {
        return (
            <Page siteTitle={"Devis"} title={"Gestion des devis"} headerButtons={this.buttonsRight()}>
                <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.selectTab(tabIndex)}>
                    <TabList>
                        <Tab>Brouillon</Tab>
                        <Tab>Devis</Tab>
                        <Tab>Modèles</Tab>
                    </TabList>

                    <TabPanel>
                        <Table
                            name={this.localName + "-list"}
                            data={this.props.quotationsDrafted}
                            columns={this.state.columns}
                            frenchName={this.frenchName}
                            localName={this.localName}
                            apiName={this.apiName}
                            defaultFiltered={getDefaultFiltered(this.localName, this.state.columns)}
                            defaultSorted={[
                                {
                                    id: "number",
                                    desc: true
                                }
                            ]}
                        />
                    </TabPanel>
                    <TabPanel>
                        <Table
                            name={this.localName2 + "-list"}
                            data={this.props.quotations}
                            columns={this.state.columns2}
                            frenchName={this.frenchName2}
                            localName={this.localName2}
                            apiName={this.apiName2}
                            defaultFiltered={getDefaultFiltered(this.localName2, this.state.columns2)}
                            defaultSorted={[
                                {
                                    id: "number",
                                    desc: true
                                }
                            ]}
                        />
                    </TabPanel>
                    <TabPanel>
                        <Table
                            name={this.localName3 + "-list"}
                            data={this.props.quotations_models}
                            columns={this.state.columns3}
                            frenchName={this.frenchName3}
                            localName={this.localName3}
                            apiName={this.apiName3}
                            defaultFiltered={getDefaultFiltered(this.localName3, this.state.columns3)}
                            defaultSorted={[
                                {
                                    id: "number",
                                    desc: true
                                }
                            ]}
                        />
                    </TabPanel>
                </Tabs>
            </Page>
        )
    }
}

const mapStateToProps = ({apiReducer}) => {
    return {
        rawToken: apiReducer.rawToken,
        quotations: apiReducer.quotations,
        quotations_models: apiReducer.quotations_models,
        quotationsDrafted: apiReducer.quotationsDrafted,
    }
};

export default connect(mapStateToProps)(Quotations)
