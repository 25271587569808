import React from 'react'
import {withRouter} from "react-router"
import {useSelector} from "react-redux"

import '../../css/components/sidenav.scss'
import Menu from "./Menu"

const Sidenav = () => {
    const {user}            = useSelector(({apiReducer}) => apiReducer)
    const {sidenavOpened}   = useSelector(({designReducer}) => designReducer)

    return (
        <nav className={sidenavOpened === false ? "sidenav sidenav-hide" : "sidenav"}>
            <div className="profile">
                <div className="name">{ user!== null ? user.firstname + ' ' + user.lastname : ''}</div>
                <div className="role">{ user!== null ? user.role.description : ''}</div>
            </div>

            <Menu mobile={true}/>
        </nav>
    )
}


export default withRouter(Sidenav)
