import React, {Component} from 'react';

class CGV extends Component {
    render() {
        return (
            <div className="cgv">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>CONDITIONS GENERALES DE VENTE</h2>

                            <h3>ARTICLE 1 OBJET</h3>

                            <p>Les présentes conditions générales ont pour objet de régir les
                                droits et obligations de la société MARNE ENSEIGNES dans le cadre de la vente de biens
                                et services relatifs à l’activité de MARNE ENSEIGNES.

                                A défaut de contrat conclu entre la société MARNE ENSEIGNES et l’acheteur, ou de
                                conditions générales ou particulières d’achat expressément acceptées par l’acheteur, les
                                ventes effectuées sont soumises aux conditions générales de vente décrites ci-après.
                                En conséquence, tous nos produits et prestations de services fournis par la société
                                MARNE ENSEIGNES implique l’adhésion sans réserve de l’acheteur aux présentes conditions
                                générales de vente, à l’exclusion de tout catalogue, prospectus ou autre document
                                publicitaire qui n’a qu’une valeur indicative.</p>


                            <h3>ARTICLE 2 PAIEMENT ET CONDITIONS DE
                                PAIEMENT</h3>

                            <h4>.1 Prix</h4>

                            <p>Le prix des produits et services proposés est exprimé en euros.
                                Les prix sont mentionnés HT. Il sera ajouté au prix proposé le taux de TVA en vigueur au
                                jour de la passation de la commande, ainsi qu’éventuellement les frais de port.
                                Tout changement du taux légal de T.V.A sera automatiquement répercuté sur le prix des
                                produits et services, à la date stipulée par le décret d’application.</p>


                            <h4>.2 Règlement</h4>

                            <p>Le client peut effectuer le règlement de ces commandes soit par
                                carte bancaire soit par chèque soit par LCR. Toutes les cartes bancaires françaises sont
                                acceptées ainsi que les cartes American Express, Eurocard/Mastercard et Visa
                                internationales.
                                Toute commande payée par chèque ne sera traitée qu’à la réception du moyen de paiement.
                                L’intégralité du prix TTC doit être versée à la commande (ou à la réception de la
                                prestation). La société MARNE ENSEIGNES se réserve le droit de suspendre toute gestion
                                de commande et toute livraison en cas de refus d’autorisation de paiement par carte
                                bancaire de la part des organismes officiellement accrédités ou en cas de
                                non-paiement.</p>


                            <h3>ARTICLE 3 RABAIS ET RISTOURNES</h3>

                            <p>Les produits et prestations de services sont facturés aux
                                conditions de tarifs en vigueur au moment de la réception.
                                Ces tarifs incluent les rabais et ristournes que la société MARNE ENSEIGNES serait
                                amenée à consentir, en fonction de ses résultats ou de la prise en charge de certaines
                                fonctions par le client.</p>


                            <h3>ARTICLE 4 CLAUSE PENALE</h3>
                            <p>Le défaut de paiement total ou partiel de la prestation à sa
                                date d’exigibilité entraîne de plein droit et sans mise en demeure préalable
                                l’allocation à la société MARNE ENSEIGNES d’une pénalité de retard.
                                Cette pénalité est calculée sur l’intégralité des sommes TTC restant dues. Elle court à
                                compter de la date d’échéance du prix de la prestation effectuée jusqu’à son paiement
                                total. Le taux d’intérêt légal de référence est celui en vigueur au jour de
                                l’utilisation des présentes conditions générales de vente.</p>


                            <h3>ARTICLE 5 CLAUSE RESOLUTOIRE</h3>

                            <p>Si dans les trente (30) jours suivant la mise en œuvre de
                                l’article « clause pénale », le client n’a toujours pas acquitté la somme due au titre
                                de la prestation effectuée par la société MARNE ENSEIGNES, la vente sera résolue de
                                plein droit, sans autre formalité, dès réception de la mise en demeure envoyée par la
                                société.
                                En outre, la société MARNE ENSEIGNES se réserve le droit de réclamer des dommages et
                                intérêts au client défaillant.
                                L’existence de la présente clause n’interdit pas au vendeur de renoncer au bénéfice de
                                celle-ci et de demander le paiement du prix.</p>


                            <h3>ARTICLE 6 CLAUSE DE RESERVE DE PROPRIETE</h3>

                            <p>Les produits et prestations de services vendus comme définis
                                dans le bon de commande ou la facture ou le bon de livraison restent la propriété de la
                                société MARNE ENSEIGNE jusqu’au paiement intégral du prix par le client.
                                Pendant la durée de la réserve de propriété, le client supportera la charge des risques
                                en cas de perte ou de destruction de la chose vendue dès sa livraison.
                                En cas de redressement ou de liquidation judiciaire du client, la propriété des services
                                livrés et resté impayés pourra être revendiquée par la société MARNE ENSEIGNES.
                                En cas de revente des services fournis par la société MARNE ENSEIGNES alors que le
                                client n’a pas réglé l’intégralité du prix, celui-ci s’engage à informer tout acquéreur
                                de la présente clause de réserve de propriété grevant lesdits services et du droit de la
                                société MARNE ENSEIGNES de revendiquer entre ses mains, soit les services concernés,
                                soit le prix de ceux-ci.</p>


                            <h3>ARTICLE 7 DROIT DE RETRACTATION</h3>

                            <p>MARNE ENSEIGNES accorde à l’acheteur un délai de rétractation
                                (délai légal) de sept (7) jours pour retourner à ses frais, les produits ne lui
                                convenant pas. Ce délai court à compter du jour de la livraison de la commande. Tout
                                retour devra être signalé au préalable à MARNE ENSEIGNES.</p>


                            <h3>ARTICLE 8 ENGAGEMENT</h3>

                            <p>La société MARNE ENSEIGNES s’engage à mener à bien la tâche
                                précisée à l’article « objet » conformément aux règles de l’art et de la meilleure
                                manière.</p>


                            <h3>ARTICLE 9 FORCE MAJEURE</h3>

                            <p>La société MARNE ENSEIGNES n’encourt aucune responsabilité en
                                cas de non-exécution ou de retard dans l’exécution de l’une de ses obligations si
                                celui-ci résulte d’un fait indépendant de sa volonté et qui échappe à son contrôle.
                                Est considéré comme tel, tout évènement extérieur, imprévisible et irrésistible au sens
                                de l’article 1148 du Code Civil.</p>


                            <h3>ARTICLE 10 DROIT APPLICABLE ET
                                JURIDICTION</h3>

                            <h4>.1 Droit applicable</h4>

                            <p>Les conditions générales, tous les actes et opérations en vertu
                                des présentes ainsi que les droits et obligations des parties aux présentes sont régis
                                et interprétés conformément au droit français.</p>


                            <h4>.2 Juridiction</h4>

                            <p>Si les parties ne parviennent pas à régler tout litige relatif
                                aux conditions générales dans les quinze (15) jours suivant la notification écrite
                                adressée par l’une des parties à l’autre concernant ledit litige, ce dernier sera réglé
                                par le tribunal compétent.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CGV
