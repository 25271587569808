import React, {Component} from 'react';
import {connect} from "react-redux";

import ProductForm from "./ProductForm";
import SubcontractingForm from "./SubcontractingForm";
import ProductHeader from "./ProductHeader";
import {
    addProductForm,
    deleteProductForm,
    addProduct,
    deleteProduct
} from "../../../redux/QuotationFormAction";
import AllowanceForm from "./AllowanceForm";
import ShapingForm from "./ShapingForm";
import AdhesiveForm from "./AdhesiveForm";
import TechnicalCostsForm from "./TechnicalCostsForm";
import MovementForm from "./MovementForm";
import SuppliesForm from "./SuppliesForm";
import PoseForm from "./PoseForm";
import {deleteResource} from "../../../redux/action";
import {onDelete} from "../../../utils/Alert";
import ProductFooter from "./ProductFooter";

class GroupInputForm extends Component {
    constructor(props) {
        super(props);

        const pathname = window.location.pathname;
        const split = pathname.split('/');
        const quotationId = split[2];

        this.state = {
            quotationId: quotationId,
            show: localStorage.getItem('groupInputFormQuotation-' + this.props.id + '-' + quotationId + '-show') !== null ? JSON.parse(localStorage.getItem('groupInputFormQuotation-' + this.props.id + '-' + quotationId + '-show')) : true,
        }
    }

    componentDidMount() {
        if(this.props.products !== null)
            this.props.products.map(product => this.addProductForm(null, product));
        else
            this.addProductForm();
    }

    onDelete = () => onDelete('', 'Etes-vous sûr de vouloir supprimer ce groupe ?', () => this.props.onDelete())

    addProductForm = (e = null, product = null) => {
        let id = this.props.productFormsId;

        if(product !== null) {
            if(product.productGroup.id === this.props.id) {
                id = product.id * 99;

                this.props.dispatch(addProduct(id, this.props.id, product.id))
                    .then(this.props.dispatch(addProductForm(this.props.id, this.renderWithType(this.props.id, id, product))));
            }
        }
        else {
            this.props.dispatch(addProduct(id, this.props.id))
                .then(this.props.dispatch(addProductForm(this.props.id, this.renderWithType(this.props.id, id, product))));
        }
    };

    deleteProductForm = (index, product) => {
        this.deleteProduct(index, product);
        this.props.dispatch(deleteProductForm(this.props.id, index));
    };

    deleteProduct = (id, product) => {
        this.props.dispatch(deleteProduct(id)).then(this.changeTotalPrice);
        if(product !== null && this.props.canDeleteResource === true)
            this.props.dispatch(deleteResource('products', product.id, this.props.rawToken))
    };

    changeTotalPrice = () => this.props.changeTotalPrice();

    renderWithType = (idGroup, id, product) => {
        switch (this.props.title) {
            case 'Produits':
                return <ProductForm
                            key={id}
                            idGroup={idGroup}
                            id={id}
                            product={product}
                            onDelete={() => this.deleteProductForm(id, product)}
                            changeTotalPrice={() => this.changeTotalPrice()}/>;
            case 'Sous traitance':
                return  <SubcontractingForm
                            key={id}
                            idGroup={idGroup}
                            id={id}
                            product={product}
                            onDelete={() => this.deleteProductForm(id, product)}
                            changeTotalPrice={() => this.changeTotalPrice()}/>;
            case 'Prestation':
                return  <AllowanceForm
                    key={id}
                    idGroup={idGroup}
                    id={id}
                    product={product}
                    onDelete={() => this.deleteProductForm(id, product)}
                    changeTotalPrice={() => this.changeTotalPrice()}/>;
            case 'Façonnage':
                return  <ShapingForm
                    key={id}
                    idGroup={idGroup}
                    id={id}
                    product={product}
                    onDelete={() => this.deleteProductForm(id, product)}
                    changeTotalPrice={() => this.changeTotalPrice()}/>;
            case 'Adhésif':
                return <AdhesiveForm
                    key={id}
                    idGroup={idGroup}
                    id={id}
                    product={product}
                    onDelete={() => this.deleteProductForm(id, product)}
                    changeTotalPrice={() => this.changeTotalPrice()}/>;
            case 'Frais techniques':
                return <TechnicalCostsForm
                    key={id}
                    idGroup={idGroup}
                    id={id}
                    product={product}
                    onDelete={() => this.deleteProductForm(id, product)}
                    changeTotalPrice={() => this.changeTotalPrice()}/>;
            case 'Déplacement':
                return <MovementForm
                    key={id}
                    idGroup={idGroup}
                    id={id}
                    product={product}
                    onDelete={() => this.deleteProductForm(id, product)}
                    changeTotalPrice={() => this.changeTotalPrice()}/>;
            case 'Fournitures':
                return <SuppliesForm
                    key={id}
                    idGroup={idGroup}
                    id={id}
                    product={product}
                    onDelete={() => this.deleteProductForm(id, product)}
                    changeTotalPrice={() => this.changeTotalPrice()}/>;
            case 'Pose':
                return <PoseForm
                    key={id}
                    idGroup={idGroup}
                    id={id}
                    product={product}
                    onDelete={() => this.deleteProductForm(id, product)}
                    changeTotalPrice={() => this.changeTotalPrice()}/>;
            default: return;
        }
    };

    handleVisibility = () => {
        this.setState({
            show: !this.state.show
        });
        localStorage.setItem('groupInputFormQuotation-' + this.props.id + '-' + this.state.quotationId + '-show', this.state.show === true ? 'false' : 'true');
    };

    render() {
        const group = this.props.groupInputForms.find(group => group.id === this.props.id);
        return (
            <div className={"group-products-container"}>
                <div className={"group-products-header"}>
                    <span className={"group-products-title"}>{this.props.title}</span>
                    <div className={"btn-right"}>
                        <div className="btn-circle btn-primary btn-hide" title={this.state.show === true ? 'Cacher' : 'Voir'}  onClick={this.handleVisibility}>
                            <i className={this.state.show === true ? 'fa fa-eye-slash' : 'fa fa-eye'}/>
                        </div>

                        <div className={'btn-success deleteProduct btn-circle'}
                             title={'Ajouter un élément'}
                             onClick={this.addProductForm}>
                            <i className="fas fa-plus"/>
                        </div>

                        <div className={"deleteProduct btn-circle btn-danger"}
                             title={"Supprimer ce bloc"}
                             onClick={this.onDelete}>
                            <i className="fas fa-trash-alt"/>
                        </div>
                    </div>
                </div>
                <table className={"group-products"}>
                    <thead>
                        <ProductHeader title={this.props.title} show={this.state.show}/>
                    </thead>
                    <tbody style={this.state.show === true ? {display: 'table-row-group'} : {display: 'none'}}>
                        {group.productsForms}
                    </tbody>
                    <tfoot>
                        <ProductFooter id={this.props.id} title={this.props.title} show={this.state.show}/>
                    </tfoot>
                </table>
            </div>
        )
    }
}

const mapStateToProps = ({apiReducer, quotationFormReducer}) => {
    return {
        rawToken: apiReducer.rawToken,
        item: apiReducer.item,
        form: quotationFormReducer.form,
        groupInputForms: quotationFormReducer.groupInputForms,
        productFormsId: quotationFormReducer.productFormsId,
    }
};

export default connect(mapStateToProps)(GroupInputForm)
