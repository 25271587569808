import React from "react"
import {getAdvance, getAmountTTC, getPriceToPaid, getTotalPrice, getTva} from "../../../utils/Amounts"

const AmountsPrint = ({resource}) => {
    const discountPerCentage    = resource.discountPerCentage || 0
    const discountEuros         = parseFloat(resource.discountEuros) || 0
    const totalPrice            = getTotalPrice(resource.totalPrice)
    const advance               = getAdvance(resource.advance)

    const tva                   = getTva(resource.totalPrice, discountEuros)
    const total                 = getAmountTTC(totalPrice, discountEuros)
    const priceToPaid           = getPriceToPaid(totalPrice, advance, discountEuros, resource.paid)

    return (
        <div className="row amounts-row">
            <div className="col-12">
                <table className="amounts">
                    <thead>
                    <tr>
                        <th>BASES HT</th>
                        <th>REMISE</th>
                        <th>MONTANT HT APRES REMISE</th>
                        <th>TAUX TVA</th>
                        <th>MONTANT TVA</th>
                        <th>TOTAL TTC</th>
                        <th>ACCOMPTE</th>
                        <th>MONTANT NET A PAYER</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{totalPrice.toFixed(2)} €</td>
                        <td>{discountPerCentage} %</td>
                        <td>{(totalPrice - discountEuros).toFixed(2)} €</td>
                        <td>20.00 %</td>
                        <td>{tva.toFixed(2)} €</td>
                        <td>{total.toFixed(2)} €</td>
                        <td>{advance.toFixed(2)} €</td>
                        <td>{resource.paid ? "0.00" : priceToPaid.toFixed(2)} €</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
};

export default AmountsPrint
